import React from "react";

function BlueLockIcon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#084BBB" />
      <path
        d="M19 20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20C17 19.4477 17.4477 19 18 19C18.5523 19 19 19.4477 19 20Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M21 14C22.8856 14 23.8284 14 24.4142 14.5858C25 15.1716 25 16.1144 25 18V20V22C25 23.8856 25 24.8284 24.4142 25.4142C23.8284 26 22.8856 26 21 26H18H15C13.1144 26 12.1716 26 11.5858 25.4142C11 24.8284 11 23.8856 11 22V20V18C11 16.1144 11 15.1716 11.5858 14.5858C12.1716 14 13.1144 14 15 14H18H21Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M15 14V10C15 8.89543 15.8954 8 17 8H19C20.1046 8 21 8.89543 21 10V14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BlueLockIcon;
