import React from "react";

function OffcerSuccessForkliftIcon() {
  return (
    <svg
      width="341"
      height="341"
      viewBox="0 0 341 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <circle cx="170.5" cy="166.5" r="110.5" fill="white" />
      </g>
      <path
        d="M129.989 180.492H97.2441V205.815H129.989V180.492Z"
        fill="#EBEBEB"
      />
      <path
        d="M121.786 205.635C121.749 205.635 121.719 199.992 121.719 193.03C121.719 186.067 121.749 180.422 121.786 180.422C121.823 180.422 121.851 186.067 121.851 193.03C121.851 199.992 121.821 205.635 121.786 205.635Z"
        fill="#E0E0E0"
      />
      <path
        d="M127.214 200.05H124.703V202.895H127.214V200.05Z"
        fill="#FAFAFA"
      />
      <path
        d="M110.955 180.557H107.235V185.96H110.955V180.557Z"
        fill="#E0E0E0"
      />
      <path
        d="M110.955 200.335H107.235V205.738H110.955V200.335Z"
        fill="#E0E0E0"
      />
      <path d="M135.11 155.227H102.365V180.55H135.11V155.227Z" fill="#EBEBEB" />
      <path
        d="M126.905 180.37C126.87 180.37 126.84 174.727 126.84 167.765C126.84 160.802 126.87 155.16 126.905 155.16C126.94 155.16 126.97 160.802 126.97 167.765C126.97 174.727 126.942 180.37 126.905 180.37Z"
        fill="#E0E0E0"
      />
      <path
        d="M132.333 174.785H129.822V177.63H132.333V174.785Z"
        fill="#FAFAFA"
      />
      <path
        d="M116.074 155.292H112.354V160.695H116.074V155.292Z"
        fill="#E0E0E0"
      />
      <path
        d="M116.074 175.073H112.354V180.475H116.074V175.073Z"
        fill="#E0E0E0"
      />
      <path
        d="M132.859 134.932H106.529V155.292H132.859V134.932Z"
        fill="#F5F5F5"
      />
      <path
        d="M126.262 155.15C126.234 155.15 126.209 150.61 126.209 145.013C126.209 139.415 126.234 134.878 126.262 134.878C126.289 134.878 126.314 139.415 126.314 145.013C126.314 150.61 126.292 155.15 126.262 155.15Z"
        fill="#E0E0E0"
      />
      <path
        d="M130.628 150.657H128.608V152.945H130.628V150.657Z"
        fill="#FAFAFA"
      />
      <path
        d="M117.552 134.985H114.563V139.33H117.552V134.985Z"
        fill="#E0E0E0"
      />
      <path
        d="M117.552 150.888H114.563V155.233H117.552V150.888Z"
        fill="#E0E0E0"
      />
      <path
        d="M250.39 149.068H225.658V168.192H250.39V149.068Z"
        fill="#F5F5F5"
      />
      <path
        d="M244.194 168.06C244.166 168.06 244.144 163.795 244.144 158.538C244.144 153.28 244.166 149.018 244.194 149.018C244.221 149.018 244.241 153.28 244.241 158.538C244.241 163.795 244.221 168.06 244.194 168.06Z"
        fill="#E0E0E0"
      />
      <path d="M248.29 163.84H246.396V165.99H248.29V163.84Z" fill="#FAFAFA" />
      <path
        d="M236.013 149.117H233.203V153.197H236.013V149.117Z"
        fill="#E0E0E0"
      />
      <path
        d="M236.013 164.057H233.203V168.137H236.013V164.057Z"
        fill="#E0E0E0"
      />
      <path
        d="M258.678 190.182H230.174V212.227H258.678V190.182Z"
        fill="#EBEBEB"
      />
      <path
        d="M251.547 212.07C251.517 212.07 251.492 207.158 251.492 201.098C251.492 195.038 251.517 190.122 251.547 190.122C251.576 190.122 251.604 195.035 251.604 201.098C251.604 207.16 251.569 212.07 251.547 212.07Z"
        fill="#E0E0E0"
      />
      <path
        d="M256.261 207.208H254.077V209.685H256.261V207.208Z"
        fill="#FAFAFA"
      />
      <path
        d="M242.109 190.237H238.871V194.94H242.109V190.237Z"
        fill="#E0E0E0"
      />
      <path
        d="M242.109 207.458H238.871V212.16H242.109V207.458Z"
        fill="#E0E0E0"
      />
      <path
        d="M254.741 168.195H226.237V190.24H254.741V168.195Z"
        fill="#EBEBEB"
      />
      <path
        d="M247.6 190.083C247.567 190.083 247.542 185.17 247.542 179.11C247.542 173.05 247.567 168.135 247.6 168.135C247.632 168.135 247.657 173.048 247.657 179.11C247.657 185.173 247.632 190.083 247.6 190.083Z"
        fill="#E0E0E0"
      />
      <path d="M252.324 185.22H250.14V187.697H252.324V185.22Z" fill="#FAFAFA" />
      <path
        d="M238.172 168.25H234.934V172.952H238.172V168.25Z"
        fill="#E0E0E0"
      />
      <path
        d="M238.172 185.47H234.934V190.172H238.172V185.47Z"
        fill="#E0E0E0"
      />
      <path d="M202.171 149.375H177.44V168.5H202.171V149.375Z" fill="#F5F5F5" />
      <path
        d="M195.975 168.368C195.948 168.368 195.928 164.103 195.928 158.845C195.928 153.588 195.948 149.325 195.975 149.325C196.003 149.325 196.025 153.575 196.025 158.845C196.025 164.115 196.003 168.368 195.975 168.368Z"
        fill="#E0E0E0"
      />
      <path
        d="M200.074 164.148H198.179V166.298H200.074V164.148Z"
        fill="#FAFAFA"
      />
      <path
        d="M187.794 149.425H184.984V153.505H187.794V149.425Z"
        fill="#E0E0E0"
      />
      <path
        d="M187.794 164.365H184.984V168.445H187.794V164.365Z"
        fill="#E0E0E0"
      />
      <path d="M204.365 130.3H179.634V149.425H204.365V130.3Z" fill="#EBEBEB" />
      <path
        d="M198.169 149.29C198.142 149.29 198.12 145.04 198.12 139.77C198.12 134.5 198.142 130.247 198.169 130.247C198.197 130.247 198.219 134.497 198.219 139.77C198.219 145.042 198.189 149.29 198.169 149.29Z"
        fill="#E0E0E0"
      />
      <path
        d="M202.268 145.073H200.374V147.223H202.268V145.073Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.989 130.348H187.179V134.428H189.989V130.348Z"
        fill="#E0E0E0"
      />
      <path
        d="M189.989 145.287H187.179V149.367H189.989V145.287Z"
        fill="#E0E0E0"
      />
      <path
        d="M203.438 117.025H185.743V130.71H203.438V117.025Z"
        fill="#EBEBEB"
      />
      <path
        d="M199.007 130.615C198.987 130.615 198.972 127.565 198.972 123.802C198.972 120.04 198.987 117 199.007 117C199.027 117 199.042 120.05 199.042 123.812C199.042 127.575 199.025 130.615 199.007 130.615Z"
        fill="#E0E0E0"
      />
      <path
        d="M201.939 127.598H200.583V129.135H201.939V127.598Z"
        fill="#FAFAFA"
      />
      <path
        d="M193.153 117.062H191.143V119.983H193.153V117.062Z"
        fill="#E0E0E0"
      />
      <path d="M193.153 127.75H191.143V130.67H193.153V127.75Z" fill="#E0E0E0" />
      <path
        d="M210.461 190.49H181.958V212.535H210.461V190.49Z"
        fill="#EBEBEB"
      />
      <path
        d="M203.321 212.377C203.288 212.377 203.263 207.465 203.263 201.405C203.263 195.345 203.288 190.43 203.321 190.43C203.353 190.43 203.375 195.342 203.375 201.405C203.375 207.467 203.35 212.377 203.321 212.377Z"
        fill="#E0E0E0"
      />
      <path
        d="M208.043 207.515H205.859V209.993H208.043V207.515Z"
        fill="#FAFAFA"
      />
      <path
        d="M193.891 190.545H190.652V195.247H193.891V190.545Z"
        fill="#E0E0E0"
      />
      <path
        d="M193.891 207.765H190.652V212.468H193.891V207.765Z"
        fill="#E0E0E0"
      />
      <path
        d="M206.522 168.503H178.018V190.548H206.522V168.503Z"
        fill="#EBEBEB"
      />
      <path
        d="M199.384 190.39C199.351 190.39 199.326 185.478 199.326 179.418C199.326 173.358 199.351 168.443 199.384 168.443C199.416 168.443 199.438 173.355 199.438 179.418C199.438 185.48 199.413 190.39 199.384 190.39Z"
        fill="#E0E0E0"
      />
      <path
        d="M204.106 185.527H201.922V188.005H204.106V185.527Z"
        fill="#FAFAFA"
      />
      <path
        d="M189.954 168.557H186.715V173.26H189.954V168.557Z"
        fill="#E0E0E0"
      />
      <path
        d="M189.954 185.777H186.715V190.48H189.954V185.777Z"
        fill="#E0E0E0"
      />
      <path
        d="M144.533 208.168V205.815H96.5361C96.3661 205.815 96.2031 205.883 96.0829 206.003C95.9628 206.124 95.8953 206.287 95.8953 206.457V207.525C95.8953 207.695 95.9628 207.859 96.0829 207.979C96.2031 208.1 96.3661 208.168 96.5361 208.168H97.8725V209.25C97.8725 209.333 97.8562 209.415 97.8245 209.492C97.7928 209.569 97.7463 209.639 97.6877 209.697C97.6291 209.756 97.5596 209.803 97.4831 209.834C97.4065 209.866 97.3245 209.882 97.2417 209.882H96.5261C96.3613 209.882 96.2031 209.947 96.0852 210.062C95.9674 210.178 95.8992 210.335 95.8953 210.5V211.588C95.8953 211.755 95.9617 211.916 96.08 212.035C96.1983 212.153 96.3588 212.22 96.5261 212.22H144.533V209.867H133.345V208.162L144.533 208.168ZM109.377 208.168H112.254V209.872H109.377V208.168ZM107.459 209.872H104.585V208.168H107.459V209.872ZM114.164 208.168H117.039V209.872H114.164V208.168ZM118.956 208.168H121.833V209.872H118.964L118.956 208.168ZM123.751 208.168H126.631V209.872H123.758L123.751 208.168ZM99.7824 208.168H102.667V209.872H99.7899L99.7824 208.168ZM131.42 209.872H128.55V208.168H131.428L131.42 209.872Z"
        fill="#E0E0E0"
      />
      <path
        d="M142.915 159.125H116.939V179.215H142.915V159.125Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M142.915 159.125H116.939V179.215H142.915V159.125Z"
        fill="black"
      />
      <path
        d="M136.407 179.073C136.385 179.073 136.365 174.595 136.365 169.073C136.365 163.55 136.385 159.073 136.407 159.073C136.43 159.073 136.45 163.55 136.45 169.073C136.45 174.595 136.43 179.073 136.407 179.073Z"
        fill="#263238"
      />
      <path
        d="M140.713 174.643H138.721V176.9H140.713V174.643Z"
        fill="#F5F5F5"
      />
      <path
        d="M127.813 159.177H124.863V163.465H127.813V159.177Z"
        fill="#263238"
      />
      <path
        d="M127.813 174.867H124.863V179.155H127.813V174.867Z"
        fill="#263238"
      />
      <path
        d="M142.69 159.125H142.623H142.428H141.68L138.92 159.137H129.817H120.712L117.954 159.122H117.206H117.011H116.944H117.011H117.206H117.954L120.712 159.11H129.817H138.92L141.68 159.122H142.428H142.623L142.69 159.125Z"
        fill="#263238"
      />
      <path d="M164.54 159.1H138.564V179.19H164.54V159.1Z" fill="#407BFF" />
      <path
        d="M158.032 179.047C158.009 179.047 157.99 174.57 157.99 169.047C157.99 163.525 158.009 159.047 158.032 159.047C158.054 159.047 158.074 163.522 158.074 169.047C158.074 174.572 158.047 179.047 158.032 179.047Z"
        fill="#263238"
      />
      <path
        d="M162.338 174.617H160.346V176.875H162.338V174.617Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.5"
        d="M149.437 159.153H146.488V163.44H149.437V159.153Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M149.437 174.843H146.488V179.13H149.437V174.843Z"
        fill="white"
      />
      <path
        d="M164.313 159.1H164.248H164.051H163.303L160.545 159.113H151.442H142.336L139.576 159.1H138.828H138.631H138.566H138.631H138.828H139.576L142.336 159.085L151.442 159.073L160.545 159.085L163.303 159.1H164.051H164.248H164.313Z"
        fill="#263238"
      />
      <path d="M165.998 139.01H140.022V159.1H165.998V139.01Z" fill="#407BFF" />
      <path
        d="M159.49 158.958C159.465 158.958 159.448 154.48 159.448 148.958C159.448 143.435 159.465 138.958 159.49 138.958C159.515 138.958 159.533 143.433 159.533 148.958C159.533 154.483 159.513 158.958 159.49 158.958Z"
        fill="#263238"
      />
      <path
        d="M163.796 154.528H161.804V156.785H163.796V154.528Z"
        fill="#F5F5F5"
      />
      <path
        d="M150.896 139.062H147.946V143.35H150.896V139.062Z"
        fill="#263238"
      />
      <path
        d="M150.896 154.753H147.946V159.04H150.896V154.753Z"
        fill="#263238"
      />
      <path
        d="M165.776 139C165.755 139.004 165.733 139.004 165.711 139H165.514H164.766H162.009L152.906 139.012L143.8 139H141.04H140.292H140.095C140.073 139.004 140.051 139.004 140.03 139H140.095H140.292H141.04L143.8 138.985H152.906H162.009L164.766 139H165.514H165.711H165.776Z"
        fill="#263238"
      />
      <path
        d="M163.22 201.582V199.23H115.226C115.056 199.23 114.893 199.298 114.772 199.418C114.652 199.538 114.584 199.702 114.583 199.872V200.94C114.584 201.111 114.652 201.274 114.772 201.394C114.893 201.515 115.056 201.582 115.226 201.582H116.563V202.652C116.563 202.821 116.496 202.982 116.377 203.101C116.258 203.221 116.097 203.287 115.929 203.287H115.216C115.049 203.287 114.888 203.354 114.769 203.473C114.651 203.591 114.584 203.752 114.583 203.92V205C114.584 205.168 114.651 205.329 114.769 205.447C114.888 205.566 115.049 205.632 115.216 205.632H163.22V203.28H152.033V201.575L163.22 201.582ZM128.064 201.582H130.939V203.287H128.067L128.064 201.582ZM126.147 203.287H123.272V201.582H126.15L126.147 203.287ZM132.864 201.582H135.741V203.287H132.864V201.582ZM137.659 201.582H140.534V203.287H137.654L137.659 201.582ZM142.451 201.582H145.331V203.287H142.446L142.451 201.582ZM118.485 201.582H121.36V203.287H118.48L118.485 201.582ZM150.12 203.287H147.238V201.582H150.116L150.12 203.287Z"
        fill="#455A64"
      />
      <path
        d="M201.907 161.052C201.986 160.972 202.047 160.876 202.089 160.772C202.13 160.667 202.15 160.555 202.148 160.442C202.146 160.33 202.121 160.218 202.075 160.116C202.03 160.013 201.964 159.92 201.882 159.842L193.786 152.17C193.624 152.018 193.411 151.933 193.189 151.933C192.967 151.933 192.754 152.018 192.592 152.17C192.507 152.249 192.438 152.345 192.391 152.451C192.343 152.557 192.317 152.672 192.314 152.788C192.311 152.905 192.331 153.02 192.373 153.129C192.415 153.237 192.479 153.336 192.559 153.42L195.788 156.825L194.108 158.447L193.233 157.75L187.126 164.762L183.863 161.615L179.928 173.217L180.709 188.967H189.759L189.126 170.25L195.197 161.58L195.245 159.517L196.99 157.725L200.753 161.095C200.916 161.241 201.129 161.318 201.347 161.31C201.565 161.302 201.772 161.21 201.924 161.052H201.907Z"
        fill="#263238"
      />
      <path
        d="M177.495 191.885C177.46 191.885 177.43 177.515 177.43 159.793C177.43 142.07 177.46 127.695 177.495 127.695C177.53 127.695 177.56 142.063 177.56 159.793C177.56 177.523 177.522 191.885 177.495 191.885Z"
        fill="#263238"
      />
      <path
        d="M197.753 187.838L203.258 172.5L201.189 171.66V168.698L216.241 168.465C216.596 168.458 216.936 168.322 217.198 168.083C217.461 167.843 217.627 167.516 217.667 167.163L219.881 147.458C219.931 147.017 220.141 146.61 220.47 146.314C220.8 146.019 221.227 145.855 221.669 145.855C221.911 145.855 222.152 145.904 222.375 145.999C222.598 146.094 222.799 146.234 222.967 146.409C223.135 146.585 223.266 146.792 223.352 147.02C223.438 147.247 223.477 147.49 223.467 147.733L222.624 168.038L230.483 168.08L230.75 171.783L207.507 172.695L201.446 187.618L197.753 187.838Z"
        fill="#455A64"
      />
      <path
        d="M201.216 187.463L181.649 187.618V188.925L189.755 188.82L202.665 188.72L233.966 184.29L230.418 170.75H209.225C208.859 170.745 208.496 170.829 208.17 170.995C207.843 171.161 207.562 171.405 207.35 171.705L201.216 187.463Z"
        fill="#263238"
      />
      <path
        d="M191.839 130.84L177.627 176.323L177.727 188.975H182.232V176.403L193.886 130.963L225.462 130.785L231.5 178.118H235.063L227.716 131.118C227.6 130.503 227.274 129.948 226.794 129.548C226.313 129.149 225.709 128.93 225.085 128.93H194.402C193.827 128.93 193.267 129.115 192.805 129.46C192.343 129.804 192.005 130.288 191.839 130.84Z"
        fill="#455A64"
      />
      <path
        d="M175.061 203.587V191.437L177.714 188.975L202.648 188.652L207.136 184.992C207.971 184.312 209 183.913 210.075 183.852L214.877 183.58C214.877 183.58 217.032 182.092 219.116 180.617C221.756 178.742 224.911 177.734 228.147 177.732H241.967C242.469 177.732 242.951 177.932 243.306 178.288C243.66 178.644 243.86 179.127 243.86 179.63V203.587H175.061Z"
        fill="#407BFF"
      />
      <path
        d="M243.855 199.672H174.817V203.667H243.855V199.672Z"
        fill="#263238"
      />
      <path
        d="M178.39 203.543C178.365 205.235 178.841 206.898 179.759 208.319C180.676 209.741 181.993 210.858 183.543 211.529C185.094 212.199 186.808 212.394 188.469 212.088C190.13 211.782 191.663 210.989 192.874 209.81C194.085 208.63 194.921 207.117 195.274 205.461C195.628 203.806 195.483 202.082 194.86 200.508C194.236 198.935 193.162 197.582 191.771 196.621C190.381 195.66 188.738 195.134 187.049 195.11C184.786 195.077 182.602 195.947 180.978 197.529C179.354 199.11 178.423 201.273 178.39 203.543Z"
        fill="#263238"
      />
      <path
        d="M178.392 203.523C178.392 203.523 178.392 203.535 178.392 203.543C178.379 205.8 179.256 207.97 180.832 209.583C182.407 211.195 184.554 212.118 186.805 212.151C189.055 212.184 191.228 211.324 192.85 209.759C194.472 208.193 195.411 206.049 195.464 203.793C195.464 203.793 195.464 203.778 195.464 203.77L178.392 203.523Z"
        fill="#263238"
      />
      <path
        d="M180.933 203.58C180.918 204.766 181.254 205.929 181.898 206.924C182.542 207.919 183.465 208.7 184.552 209.168C185.638 209.637 186.839 209.772 188.002 209.557C189.165 209.342 190.239 208.786 191.087 207.96C191.935 207.133 192.52 206.073 192.768 204.914C193.016 203.754 192.915 202.547 192.479 201.445C192.043 200.342 191.291 199.394 190.318 198.72C189.345 198.046 188.194 197.676 187.012 197.657C186.225 197.645 185.443 197.788 184.711 198.079C183.979 198.37 183.311 198.803 182.746 199.354C182.182 199.904 181.731 200.56 181.42 201.286C181.109 202.011 180.943 202.791 180.933 203.58Z"
        fill="#FAFAFA"
      />
      <path
        d="M187.012 197.657C186.225 197.645 185.443 197.788 184.711 198.079C183.979 198.37 183.311 198.803 182.746 199.354C182.182 199.904 181.731 200.56 181.42 201.286C181.109 202.011 180.943 202.791 180.933 203.58L192.919 203.752C192.931 202.963 192.788 202.179 192.498 201.445C192.208 200.711 191.776 200.042 191.227 199.476C190.678 198.909 190.023 198.457 189.3 198.145C188.577 197.833 187.799 197.668 187.012 197.657Z"
        fill="#E0E0E0"
      />
      <path
        d="M183.274 203.615C183.265 204.339 183.471 205.049 183.865 205.656C184.258 206.262 184.823 206.739 185.486 207.024C186.15 207.309 186.883 207.39 187.592 207.258C188.302 207.125 188.957 206.785 189.473 206.279C189.99 205.774 190.346 205.126 190.495 204.418C190.645 203.71 190.582 202.973 190.314 202.301C190.045 201.629 189.585 201.051 188.989 200.642C188.394 200.232 187.691 200.009 186.969 200C186.001 199.989 185.069 200.363 184.376 201.041C183.683 201.719 183.287 202.644 183.274 203.615Z"
        fill="#263238"
      />
      <path
        d="M220.163 203.543C220.138 205.235 220.614 206.898 221.532 208.319C222.449 209.741 223.766 210.858 225.317 211.529C226.867 212.199 228.581 212.394 230.242 212.088C231.903 211.782 233.436 210.989 234.647 209.81C235.859 208.63 236.694 207.117 237.047 205.461C237.401 203.806 237.257 202.082 236.633 200.508C236.01 198.935 234.935 197.582 233.544 196.621C232.154 195.66 230.511 195.134 228.822 195.11C226.559 195.077 224.375 195.947 222.751 197.529C221.128 199.11 220.197 201.273 220.163 203.543Z"
        fill="#263238"
      />
      <path
        d="M220.166 203.523C220.166 203.523 220.166 203.535 220.166 203.543C220.153 205.8 221.029 207.97 222.605 209.583C224.18 211.195 226.327 212.118 228.578 212.151C230.829 212.184 233.002 211.324 234.623 209.759C236.245 208.193 237.185 206.049 237.238 203.793C237.238 203.793 237.238 203.778 237.238 203.77L220.166 203.523Z"
        fill="#263238"
      />
      <path
        d="M222.707 203.58C222.691 204.766 223.027 205.929 223.671 206.924C224.315 207.919 225.239 208.7 226.325 209.168C227.412 209.637 228.612 209.772 229.775 209.557C230.939 209.342 232.012 208.786 232.86 207.96C233.708 207.133 234.293 206.073 234.541 204.914C234.789 203.754 234.689 202.547 234.253 201.445C233.817 200.342 233.065 199.394 232.091 198.72C231.118 198.046 229.968 197.676 228.785 197.657C227.998 197.645 227.216 197.788 226.484 198.079C225.752 198.37 225.085 198.803 224.52 199.354C223.955 199.904 223.504 200.56 223.193 201.286C222.882 202.011 222.717 202.791 222.707 203.58Z"
        fill="#FAFAFA"
      />
      <path
        d="M228.785 197.657C227.998 197.645 227.216 197.788 226.484 198.079C225.752 198.37 225.085 198.803 224.52 199.354C223.955 199.904 223.504 200.56 223.193 201.286C222.882 202.011 222.717 202.791 222.707 203.58L234.692 203.752C234.705 202.963 234.562 202.179 234.271 201.445C233.981 200.711 233.549 200.042 233 199.476C232.452 198.909 231.797 198.457 231.074 198.145C230.35 197.833 229.573 197.668 228.785 197.657Z"
        fill="#E0E0E0"
      />
      <path
        d="M225.05 203.615C225.041 204.338 225.247 205.048 225.64 205.655C226.033 206.261 226.597 206.738 227.26 207.023C227.923 207.308 228.656 207.39 229.365 207.258C230.075 207.126 230.73 206.787 231.247 206.282C231.764 205.777 232.12 205.13 232.27 204.422C232.421 203.715 232.358 202.978 232.091 202.306C231.825 201.634 231.365 201.056 230.771 200.646C230.176 200.235 229.474 200.01 228.753 200C228.273 199.993 227.796 200.081 227.35 200.259C226.904 200.437 226.497 200.702 226.154 201.037C225.81 201.373 225.535 201.774 225.346 202.216C225.156 202.658 225.056 203.134 225.05 203.615Z"
        fill="#263238"
      />
      <path
        d="M239.808 197.575C239.793 197.556 239.779 197.536 239.766 197.515L239.656 197.335L239.482 197.04C239.405 196.905 239.322 196.774 239.232 196.647C238.685 195.822 238.038 195.066 237.307 194.397C237.088 194.175 236.821 193.982 236.559 193.76C236.298 193.537 235.998 193.347 235.699 193.135C235.4 192.922 235.071 192.727 234.717 192.555C234.545 192.465 234.37 192.367 234.191 192.28L233.63 192.03C232.834 191.694 232.005 191.442 231.156 191.28C230.246 191.104 229.318 191.034 228.391 191.07C226.595 191.171 224.844 191.671 223.265 192.535L222.731 192.835C222.559 192.94 222.397 193.055 222.233 193.16C221.896 193.365 221.607 193.615 221.31 193.832C221.014 194.05 220.757 194.298 220.512 194.535C220.268 194.773 220.029 195 219.829 195.242C219.165 195.978 218.593 196.792 218.126 197.668C218.048 197.801 217.978 197.94 217.917 198.082C217.859 198.202 217.81 198.305 217.77 198.392L217.677 198.582C217.668 198.604 217.656 198.625 217.643 198.645C217.647 198.622 217.654 198.599 217.665 198.577L217.745 198.382L217.879 198.067C217.938 197.923 218.006 197.782 218.081 197.645C218.534 196.755 219.099 195.928 219.762 195.182C219.959 194.932 220.206 194.712 220.443 194.462C220.679 194.212 220.964 193.992 221.243 193.747C221.522 193.502 221.831 193.275 222.168 193.065C222.333 192.957 222.497 192.842 222.667 192.735L223.203 192.427C223.969 192.011 224.777 191.675 225.614 191.427C226.514 191.162 227.442 191.001 228.379 190.947C230.195 190.877 232.003 191.214 233.672 191.935L234.236 192.185C234.418 192.272 234.592 192.372 234.767 192.465C235.123 192.64 235.435 192.865 235.764 193.055C236.093 193.245 236.36 193.475 236.627 193.692C236.894 193.91 237.155 194.115 237.375 194.34C238.105 195.019 238.745 195.79 239.28 196.632C239.37 196.761 239.453 196.895 239.529 197.032L239.691 197.335C239.734 197.412 239.766 197.472 239.791 197.522C239.816 197.572 239.811 197.572 239.808 197.575Z"
        fill="#263238"
      />
      <path
        d="M198.072 199.15C198.058 199.129 198.046 199.106 198.037 199.082C198.017 199.03 197.99 198.963 197.955 198.878L197.82 198.545C197.761 198.415 197.693 198.265 197.618 198.098C197.184 197.153 196.643 196.261 196.005 195.44C195.075 194.209 193.889 193.197 192.529 192.473C191.715 192.074 190.852 191.785 189.961 191.615C189.008 191.418 188.035 191.329 187.062 191.35C186.088 191.364 185.12 191.489 184.174 191.723C183.295 191.943 182.447 192.275 181.651 192.71C180.291 193.435 179.075 194.403 178.063 195.568C177.39 196.356 176.805 197.217 176.318 198.133C176.143 198.458 176.011 198.713 175.929 198.883C175.889 198.965 175.854 199.03 175.829 199.08C175.82 199.103 175.807 199.125 175.792 199.145C175.796 199.12 175.805 199.097 175.817 199.075L175.906 198.873C175.981 198.693 176.106 198.435 176.273 198.105C176.745 197.173 177.322 196.299 177.993 195.5C179.004 194.318 180.225 193.333 181.594 192.598C182.398 192.158 183.254 191.822 184.142 191.598C185.097 191.361 186.076 191.234 187.059 191.22C188.043 191.203 189.026 191.297 189.989 191.5C190.889 191.674 191.762 191.969 192.584 192.375C193.955 193.112 195.147 194.143 196.075 195.395C196.71 196.226 197.243 197.13 197.663 198.088L197.853 198.54C197.9 198.673 197.943 198.79 197.975 198.878C198.007 198.965 198.03 199.033 198.047 199.088C198.058 199.107 198.066 199.128 198.072 199.15Z"
        fill="#263238"
      />
      <path d="M175.044 199.23H137.2V203.667H175.044V199.23Z" fill="#263238" />
      <path
        d="M174.019 201.113C174.019 201.331 173.954 201.545 173.833 201.726C173.712 201.908 173.54 202.05 173.339 202.133C173.137 202.217 172.916 202.239 172.702 202.196C172.488 202.154 172.292 202.048 172.138 201.894C171.984 201.739 171.879 201.542 171.836 201.328C171.794 201.114 171.815 200.892 171.899 200.69C171.982 200.488 172.123 200.315 172.305 200.194C172.486 200.072 172.699 200.008 172.917 200.008C173.209 200.008 173.49 200.124 173.696 200.331C173.903 200.538 174.019 200.819 174.019 201.113Z"
        fill="#455A64"
      />
      <path d="M174.548 128.58H171.389V191.5H174.548V128.58Z" fill="#455A64" />
      <path
        d="M219.472 195.085C219.472 195.12 214.147 195.15 207.579 195.15C201.012 195.15 195.686 195.12 195.686 195.085C195.686 195.05 201.009 195.02 207.579 195.02C214.149 195.02 219.472 195.05 219.472 195.085Z"
        fill="#263238"
      />
      <path
        d="M214.625 194.143C214.588 194.143 214.561 191.82 214.561 188.955C214.561 186.09 214.588 183.77 214.625 183.77C214.663 183.77 214.69 186.09 214.69 188.955C214.69 191.82 214.66 194.143 214.625 194.143Z"
        fill="#263238"
      />
      <path
        d="M177.497 127.138H171.05V130.023H177.497V127.138Z"
        fill="#263238"
      />
      <path
        d="M175.044 190.98H170.892V199.285H175.044V190.98Z"
        fill="#263238"
      />
      <path
        d="M165.405 179.198H139.429V199.288H165.405V179.198Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M165.405 179.198H139.429V199.288H165.405V179.198Z"
        fill="black"
      />
      <path
        d="M158.897 199.145C158.874 199.145 158.854 194.668 158.854 189.145C158.854 183.623 158.874 179.145 158.897 179.145C158.919 179.145 158.939 183.623 158.939 189.145C158.939 194.668 158.919 199.145 158.897 199.145Z"
        fill="#263238"
      />
      <path
        d="M163.203 194.715H161.211V196.973H163.203V194.715Z"
        fill="#F5F5F5"
      />
      <path
        d="M150.302 179.25H147.353V183.537H150.302V179.25Z"
        fill="#263238"
      />
      <path
        d="M150.302 194.94H147.353V199.228H150.302V194.94Z"
        fill="#263238"
      />
      <path
        d="M165.178 179.198H165.113H164.916H164.168L161.408 179.21L152.312 179.25H143.206L140.449 179.238H139.701H139.504H139.439C139.461 179.235 139.482 179.235 139.504 179.238H139.701H140.449L143.206 179.225L152.312 179.213L161.415 179.225L164.175 179.238H164.923H165.12C165.138 179.222 165.157 179.209 165.178 179.198Z"
        fill="#263238"
      />
      <path
        d="M138.157 179.198H112.182V199.288H138.157V179.198Z"
        fill="#407BFF"
      />
      <path
        d="M131.652 199.145C131.627 199.145 131.61 194.668 131.61 189.145C131.61 183.623 131.627 179.145 131.652 179.145C131.677 179.145 131.692 183.623 131.692 189.145C131.692 194.668 131.675 199.145 131.652 199.145Z"
        fill="#263238"
      />
      <path
        d="M135.958 194.715H133.966V196.973H135.958V194.715Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.5"
        d="M123.058 179.25H120.108V183.537H123.058V179.25Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M123.058 194.94H120.108V199.228H123.058V194.94Z"
        fill="white"
      />
      <path
        d="M137.933 179.197H137.866H137.671H136.923L134.166 179.21H125.062H115.957L113.197 179.197H112.449H112.254H112.187C112.209 179.195 112.232 179.195 112.254 179.197H112.449H113.197L115.957 179.185L125.062 179.172L134.166 179.185L136.923 179.197H137.671H137.866C137.888 179.195 137.911 179.195 137.933 179.197Z"
        fill="#263238"
      />
      <path
        d="M244.944 184.658C246.283 184.983 247.408 186.345 247.385 187.728C247.357 188.42 247.103 189.084 246.662 189.618C246.221 190.152 245.618 190.526 244.944 190.683"
        fill="#407BFF"
      />
      <g opacity="0.6">
        <path
          d="M244.944 184.658C246.283 184.983 247.408 186.345 247.385 187.728C247.357 188.42 247.103 189.084 246.662 189.618C246.221 190.152 245.618 190.526 244.944 190.683"
          fill="white"
        />
      </g>
      <path
        d="M244.999 183.883H243.855V191.485H244.999V183.883Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M244.999 183.883H243.855V191.485H244.999V183.883Z"
        fill="black"
      />
      <path
        d="M190.547 153.417C190.41 153.31 190.315 153.157 190.281 152.986C190.247 152.815 190.275 152.637 190.36 152.485C190.552 152.15 190.777 151.755 190.879 151.59C191.004 151.395 191.118 151.193 191.221 150.985C191.252 150.861 191.318 150.748 191.411 150.66C191.503 150.571 191.619 150.511 191.744 150.485C192.049 150.446 192.358 150.455 192.659 150.512L195.778 151.187L197.232 151.907L195.818 153.157L195.033 152.907C194.796 153.112 193.871 153.22 193.557 153.182C193.155 153.132 192.353 152.87 191.896 152.957L191.455 153.02L191.664 153.195C192.048 153.167 192.432 153.246 192.774 153.422C192.829 153.459 192.87 153.513 192.894 153.574C192.917 153.636 192.92 153.704 192.903 153.768C192.886 153.832 192.849 153.889 192.799 153.931C192.748 153.973 192.685 153.998 192.619 154.002L191.595 154.07C191.317 153.939 191.05 153.787 190.797 153.615C190.717 153.545 190.634 153.479 190.547 153.417Z"
        fill="#FFBE9D"
      />
      <path
        d="M191.457 153.03C191.407 153.064 191.35 153.088 191.29 153.1C191.127 153.148 190.954 153.148 190.792 153.1C190.675 153.062 190.571 152.989 190.495 152.893C190.451 152.834 190.42 152.766 190.404 152.695C190.388 152.624 190.386 152.55 190.4 152.478C190.419 152.395 190.464 152.321 190.53 152.268C190.595 152.22 190.667 152.184 190.744 152.16C190.893 152.11 191.046 152.071 191.2 152.043C191.495 151.986 191.792 151.947 192.091 151.928C192.228 151.928 192.357 151.913 192.482 151.913H192.577H192.652C192.704 151.905 192.755 151.895 192.806 151.883C193.018 151.814 193.234 151.757 193.452 151.71C193.258 151.825 193.048 151.909 192.829 151.96C192.776 151.978 192.722 151.991 192.667 152C192.633 152.004 192.6 152.004 192.567 152H192.482C192.365 152 192.233 152.015 192.101 152.028C191.807 152.054 191.515 152.094 191.225 152.15C191.077 152.178 190.93 152.214 190.787 152.26C190.642 152.303 190.537 152.368 190.517 152.485C190.506 152.541 190.506 152.598 190.517 152.654C190.529 152.71 190.551 152.763 190.582 152.81C190.647 152.893 190.733 152.957 190.831 152.995C190.98 153.047 191.14 153.062 191.295 153.038C191.39 153.035 191.457 153.018 191.457 153.03Z"
        fill="#EB996E"
      />
      <path
        d="M191.844 151.4C191.879 151.4 191.939 151.555 191.876 151.753C191.814 151.95 191.674 152.055 191.652 152.028C191.63 152 191.704 151.88 191.754 151.715C191.804 151.55 191.807 151.405 191.844 151.4Z"
        fill="#EB996E"
      />
      <path
        d="M192.333 151.597C192.368 151.597 192.378 151.722 192.29 151.847C192.203 151.972 192.086 151.982 192.073 151.947C192.061 151.912 192.126 151.852 192.188 151.767C192.25 151.682 192.295 151.592 192.333 151.597Z"
        fill="#EB996E"
      />
      <path
        d="M204.612 150.9C205.041 150.72 202.149 153.702 202.149 153.702L196.803 151.702L194.916 152.865L200.663 156.922C201.253 157.34 201.973 157.532 202.693 157.463C203.412 157.394 204.083 157.07 204.585 156.547L207.978 152.615L204.612 150.9Z"
        fill="#FFBE9D"
      />
      <path
        d="M207.746 138.95C206.998 138.805 206.535 138.575 206.515 138.288C206.475 137.693 208.357 137.083 210.721 136.92C213.085 136.758 215.03 137.113 215.072 137.705C215.089 137.993 214.663 138.283 213.95 138.525"
        fill="#407BFF"
      />
      <g opacity="0.4">
        <path
          d="M208.163 138.9C207.502 138.775 207.071 138.57 207.071 138.315C207.071 137.603 208.704 137.245 210.798 137.103C212.893 136.96 214.621 137.273 214.656 137.8C214.673 138.05 214.294 138.3 213.658 138.525"
          fill="black"
        />
      </g>
      <path
        d="M207.083 137.967C207.083 137.967 207.524 135.405 207.569 135.278C207.614 135.15 208.255 134 209.948 133.568L211.364 133.47C211.364 133.47 213.419 133.983 213.817 134.45C214.216 134.918 214.536 137.287 214.536 137.287L210.506 136.693L207.851 137.535L207.083 137.967Z"
        fill="#407BFF"
      />
      <path
        d="M210.392 133.25L209.784 133.29C209.642 133.479 209.571 133.712 209.584 133.948C209.584 134.39 209.691 137.425 209.691 137.425L211.462 137.225L211.155 133.485L211.766 133.58L211.733 133.368L211.561 133.19L210.392 133.25Z"
        fill="#407BFF"
      />
      <path
        d="M214.092 147.218L209.753 147.313C209.699 146.855 209.738 143.993 209.738 143.993C209.738 143.993 208.088 143.848 207.956 142.21C207.824 140.573 207.853 137.283 207.853 137.283L207.871 137.138C209.709 136.195 211.895 135.973 213.606 137.123L213.855 137.293L214.092 147.218Z"
        fill="#FFBE9D"
      />
      <path
        d="M209.738 144C210.494 143.946 211.218 143.676 211.825 143.223C211.825 143.223 211.374 144.473 209.733 144.425L209.738 144Z"
        fill="#EB996E"
      />
      <path
        d="M213.78 139C213.808 139 214.88 138.593 214.92 139.715C214.96 140.838 213.828 140.645 213.825 140.615C213.823 140.585 213.78 139 213.78 139Z"
        fill="#FFBE9D"
      />
      <path
        d="M214.147 140.178C214.147 140.178 214.167 140.193 214.199 140.205C214.223 140.215 214.248 140.22 214.273 140.22C214.298 140.22 214.323 140.215 214.346 140.205C214.417 140.156 214.474 140.09 214.51 140.012C214.546 139.934 214.561 139.848 214.553 139.763C214.553 139.666 214.531 139.571 214.489 139.485C214.477 139.45 214.457 139.419 214.43 139.394C214.404 139.369 214.372 139.35 214.336 139.34C214.314 139.335 214.291 139.338 214.27 139.349C214.25 139.359 214.234 139.376 214.224 139.398C214.209 139.43 214.224 139.453 214.212 139.455C214.199 139.458 214.187 139.438 214.194 139.39C214.201 139.362 214.215 139.337 214.237 139.318C214.267 139.293 214.305 139.282 214.344 139.285C214.39 139.292 214.434 139.312 214.471 139.341C214.507 139.37 214.536 139.409 214.553 139.453C214.607 139.543 214.638 139.645 214.646 139.75C214.657 139.851 214.637 139.953 214.589 140.042C214.541 140.131 214.467 140.204 214.376 140.25C214.347 140.26 214.315 140.264 214.284 140.26C214.253 140.256 214.223 140.245 214.197 140.228C214.147 140.205 214.147 140.18 214.147 140.178Z"
        fill="#EB996E"
      />
      <path
        d="M208.395 139.305C208.401 139.366 208.431 139.423 208.477 139.464C208.523 139.505 208.582 139.528 208.644 139.528C208.704 139.528 208.762 139.504 208.804 139.461C208.847 139.418 208.871 139.36 208.871 139.3C208.864 139.239 208.835 139.182 208.789 139.141C208.743 139.1 208.683 139.077 208.621 139.078C208.561 139.078 208.504 139.101 208.461 139.144C208.418 139.187 208.395 139.245 208.395 139.305Z"
        fill="#263238"
      />
      <path
        d="M208.32 139.075C208.35 139.102 208.519 138.96 208.771 138.942C209.023 138.925 209.215 139.04 209.24 139.007C209.265 138.975 209.217 138.94 209.135 138.887C209.021 138.82 208.889 138.789 208.756 138.797C208.625 138.806 208.5 138.856 208.4 138.94C208.327 139 208.305 139.062 208.32 139.075Z"
        fill="#263238"
      />
      <path
        d="M210.871 139.212C210.872 139.244 210.88 139.274 210.893 139.302C210.907 139.33 210.926 139.355 210.949 139.376C210.972 139.397 210.999 139.413 211.029 139.423C211.058 139.433 211.089 139.437 211.12 139.435C211.15 139.435 211.179 139.429 211.206 139.418C211.234 139.406 211.259 139.389 211.28 139.368C211.3 139.347 211.317 139.322 211.328 139.294C211.339 139.267 211.345 139.237 211.344 139.207C211.343 139.176 211.335 139.146 211.322 139.118C211.308 139.09 211.289 139.065 211.266 139.044C211.243 139.023 211.216 139.007 211.186 138.997C211.157 138.987 211.126 138.983 211.095 138.985C211.065 138.985 211.036 138.991 211.009 139.002C210.981 139.014 210.956 139.031 210.935 139.052C210.915 139.073 210.898 139.098 210.887 139.126C210.876 139.153 210.87 139.183 210.871 139.212Z"
        fill="#263238"
      />
      <path
        d="M210.793 138.953C210.823 138.983 210.99 138.84 211.242 138.823C211.494 138.805 211.686 138.92 211.711 138.888C211.736 138.855 211.691 138.82 211.606 138.765C211.493 138.697 211.361 138.667 211.23 138.678C211.099 138.685 210.973 138.735 210.873 138.82C210.801 138.885 210.778 138.94 210.793 138.953Z"
        fill="#263238"
      />
      <path
        d="M210.008 140.883C209.872 140.852 209.733 140.837 209.594 140.838C209.529 140.838 209.467 140.838 209.454 140.783C209.444 140.717 209.455 140.65 209.484 140.59L209.641 140.09C209.787 139.663 209.901 139.225 209.98 138.78C209.798 139.194 209.65 139.622 209.537 140.06C209.484 140.235 209.434 140.403 209.387 140.56C209.354 140.639 209.349 140.727 209.372 140.81C209.383 140.832 209.399 140.851 209.418 140.866C209.438 140.88 209.46 140.89 209.484 140.895C209.521 140.9 209.558 140.9 209.594 140.895C209.732 140.909 209.871 140.905 210.008 140.883Z"
        fill="#263238"
      />
      <path
        d="M210.758 141C210.716 141 210.736 141.272 210.509 141.482C210.282 141.692 210.01 141.682 210.01 141.732C210.01 141.782 210.078 141.78 210.202 141.775C210.361 141.766 210.512 141.701 210.629 141.592C210.74 141.486 210.807 141.342 210.816 141.188C210.811 141.07 210.776 141 210.758 141Z"
        fill="#263238"
      />
      <path
        d="M210.656 137.97C210.686 138.038 210.933 137.988 211.23 138.005C211.526 138.023 211.771 138.085 211.806 138.02C211.841 137.955 211.776 137.928 211.676 137.868C211.543 137.793 211.394 137.752 211.242 137.748C211.09 137.74 210.938 137.768 210.798 137.83C210.699 137.883 210.656 137.94 210.656 137.97Z"
        fill="#263238"
      />
      <path
        d="M208.362 138.34C208.412 138.393 208.579 138.323 208.789 138.305C208.998 138.288 209.175 138.318 209.213 138.255C209.25 138.193 209.195 138.17 209.115 138.12C209.008 138.062 208.885 138.038 208.764 138.05C208.642 138.062 208.527 138.11 208.432 138.188C208.362 138.25 208.342 138.313 208.362 138.34Z"
        fill="#263238"
      />
      <path
        d="M213.897 137.937C213.885 138.395 213.93 138.852 213.917 139.31C213.902 139.483 213.859 139.652 213.788 139.81C213.764 139.902 213.712 139.983 213.64 140.044C213.567 140.105 213.478 140.141 213.384 140.148C213.289 140.155 213.196 140.132 213.115 140.083C213.035 140.033 212.972 139.96 212.935 139.872C212.846 139.692 212.798 139.494 212.795 139.292C212.794 139.091 212.76 138.891 212.695 138.7C212.589 138.502 212.462 138.316 212.316 138.145C212.245 138.058 212.194 137.956 212.169 137.846C212.143 137.736 212.144 137.622 212.169 137.512C212.773 137.494 213.37 137.64 213.897 137.935"
        fill="#263238"
      />
      <path
        d="M207.854 138.14L207.826 137.222L210.444 136.242L213.848 136.992L213.912 137.977C211.946 137.257 209.779 137.315 207.854 138.14Z"
        fill="#455A64"
      />
      <path
        d="M207.981 137.018L207.871 137.138L206.951 137.81C208.156 137.405 209.407 137.153 210.674 137.06C212.065 137.028 213.456 137.159 214.818 137.45L213.501 136.772C213.287 136.572 213.036 136.417 212.761 136.316C212.487 136.215 212.195 136.171 211.903 136.185L209.499 136.3C209.212 136.314 208.929 136.384 208.669 136.507C208.408 136.63 208.175 136.804 207.981 137.018Z"
        fill="#407BFF"
      />
      <path
        d="M209.659 136.217C209.703 135.792 209.715 135.363 209.694 134.935C209.704 134.506 209.683 134.078 209.629 133.652C209.582 134.078 209.57 134.507 209.594 134.935C209.582 135.364 209.604 135.792 209.659 136.217Z"
        fill="#263238"
      />
      <path
        d="M211.763 133.58C211.616 133.526 211.461 133.492 211.305 133.48C211.256 133.484 211.211 133.506 211.177 133.541C211.144 133.577 211.125 133.624 211.125 133.673L211.14 133.943C211.157 134.135 211.172 134.34 211.19 134.553C211.225 134.988 211.265 135.38 211.297 135.663C211.304 135.817 211.329 135.971 211.372 136.12C211.39 135.966 211.39 135.811 211.372 135.658C211.357 135.373 211.329 134.98 211.292 134.548C211.272 134.333 211.255 134.128 211.237 133.935C211.237 133.845 211.217 133.758 211.21 133.673C211.209 133.643 211.22 133.614 211.239 133.592C211.258 133.569 211.285 133.554 211.314 133.55C211.463 133.572 211.613 133.582 211.763 133.58Z"
        fill="#263238"
      />
      <path
        d="M206.889 137.803C206.889 137.803 206.998 137.748 207.2 137.66C207.3 137.615 207.422 137.565 207.562 137.488C207.724 137.404 207.862 137.28 207.963 137.128C208.08 136.953 208.088 136.72 208.213 136.545C208.337 136.37 208.597 136.343 208.851 136.318C209.365 136.26 209.931 136.218 210.526 136.193C211.122 136.168 211.693 136.163 212.209 136.168C212.446 136.148 212.683 136.209 212.88 136.343C212.96 136.419 213.032 136.503 213.097 136.593L213.199 136.715L213.252 136.773C213.273 136.792 213.298 136.808 213.324 136.82C213.693 136.968 213.995 137.07 214.202 137.138C214.306 137.181 214.414 137.218 214.523 137.248C214.499 137.23 214.473 137.215 214.446 137.203C214.394 137.178 214.316 137.143 214.214 137.1C214.015 137.013 213.715 136.908 213.356 136.755C213.338 136.746 213.32 136.735 213.304 136.723L213.257 136.665L213.159 136.543C213.091 136.453 213.016 136.37 212.935 136.293C212.725 136.141 212.469 136.069 212.212 136.088C211.693 136.088 211.122 136.088 210.524 136.105C209.926 136.123 209.355 136.175 208.838 136.238C208.711 136.248 208.584 136.268 208.459 136.298C208.34 136.328 208.235 136.4 208.163 136.5C208.018 136.705 208.018 136.938 207.913 137.093C207.822 137.238 207.695 137.357 207.544 137.44C207.412 137.52 207.295 137.578 207.193 137.628C207.091 137.678 207.021 137.72 206.971 137.75C206.941 137.763 206.913 137.781 206.889 137.803Z"
        fill="#263238"
      />
      <path
        d="M207.258 137.92C207.282 137.91 207.304 137.896 207.325 137.88C207.367 137.85 207.432 137.81 207.517 137.763C207.763 137.64 208.021 137.542 208.287 137.47C209.169 137.238 210.079 137.132 210.99 137.155C211.526 137.155 212.035 137.155 212.486 137.175C212.937 137.195 213.361 137.268 213.705 137.325C213.86 137.351 214.013 137.386 214.164 137.428C214.278 137.448 214.387 137.494 214.481 137.563C214.518 137.592 214.548 137.63 214.568 137.674C214.589 137.717 214.599 137.765 214.598 137.813C214.612 137.788 214.617 137.76 214.613 137.733C214.604 137.653 214.567 137.579 214.508 137.525C214.413 137.446 214.302 137.39 214.182 137.363C214.029 137.315 213.874 137.276 213.718 137.245C213.313 137.166 212.904 137.108 212.494 137.073C212.027 137.038 211.514 137.048 210.98 137.045C210.06 137.02 209.142 137.137 208.257 137.393C207.988 137.469 207.729 137.579 207.487 137.72C207.422 137.758 207.36 137.803 207.302 137.853C207.273 137.898 207.255 137.918 207.258 137.92Z"
        fill="#263238"
      />
      <path
        d="M210.045 133.35C210.128 133.356 210.212 133.356 210.295 133.35L210.898 133.327C211.132 133.327 211.349 133.29 211.499 133.305C211.54 133.307 211.579 133.317 211.616 133.336C211.652 133.354 211.683 133.38 211.708 133.412C211.708 133.412 211.708 133.387 211.674 133.355C211.626 133.311 211.566 133.284 211.502 133.277C211.3 133.262 211.097 133.262 210.896 133.277L210.292 133.312C210.209 133.316 210.126 133.328 210.045 133.35Z"
        fill="#FAFAFA"
      />
      <path
        d="M209.94 136.317C209.983 136.322 210.025 136.322 210.068 136.317H210.412C210.703 136.317 211.105 136.317 211.549 136.317L212.174 136.332C212.342 136.332 212.508 136.345 212.673 136.372C212.785 136.396 212.885 136.457 212.957 136.545C212.982 136.578 213.002 136.614 213.017 136.652C213.012 136.61 212.996 136.569 212.97 136.535C212.899 136.439 212.797 136.372 212.681 136.345C212.516 136.313 212.349 136.296 212.182 136.295C211.99 136.295 211.778 136.28 211.556 136.277C211.11 136.277 210.708 136.277 210.417 136.29L210.073 136.307C210.028 136.307 209.984 136.31 209.94 136.317Z"
        fill="#FAFAFA"
      />
      <path
        d="M211.255 135.873C211.258 135.843 211.258 135.812 211.255 135.783C211.255 135.7 211.232 135.62 211.22 135.533C211.192 135.325 211.157 135.033 211.125 134.72C211.093 134.408 211.075 134.115 211.063 133.908C211.063 133.823 211.058 133.739 211.048 133.658C211.052 133.628 211.052 133.597 211.048 133.568C211.044 133.597 211.044 133.628 211.048 133.658C211.048 133.715 211.048 133.8 211.048 133.908C211.048 134.118 211.068 134.408 211.098 134.725C211.127 135.043 211.17 135.33 211.205 135.535C211.222 135.64 211.24 135.723 211.252 135.785C211.249 135.814 211.25 135.844 211.255 135.873Z"
        fill="#FAFAFA"
      />
      <path
        d="M213.329 136.867C213.354 136.882 213.381 136.894 213.409 136.902L213.628 136.985L214.356 137.235C214.491 137.278 214.622 137.329 214.75 137.387C214.853 137.435 214.935 137.517 214.982 137.62C215.013 137.69 215.021 137.768 215.005 137.842C214.987 137.892 214.967 137.917 214.972 137.92C214.993 137.9 215.009 137.875 215.02 137.847C215.048 137.771 215.048 137.687 215.02 137.61C214.97 137.499 214.881 137.41 214.77 137.36C214.642 137.297 214.508 137.244 214.371 137.202L213.638 136.952L213.416 136.882C213.388 136.874 213.359 136.869 213.329 136.867Z"
        fill="#FAFAFA"
      />
      <path
        d="M194.901 181.545L195.128 185.605C195.128 185.605 191.089 187.198 191.059 187.95L198.923 187.795L198.828 181.458L194.901 181.545Z"
        fill="#407BFF"
      />
      <g opacity="0.4">
        <path
          d="M194.901 181.545L195.128 185.605C195.128 185.605 191.089 187.198 191.059 187.95L198.923 187.795L198.828 181.458L194.901 181.545Z"
          fill="black"
        />
      </g>
      <path
        d="M197.551 185.215C197.628 185.236 197.693 185.285 197.736 185.352C197.778 185.419 197.794 185.499 197.781 185.578C197.762 185.654 197.715 185.721 197.649 185.764C197.582 185.807 197.502 185.823 197.424 185.808C197.342 185.787 197.27 185.735 197.224 185.664C197.178 185.593 197.16 185.506 197.175 185.423C197.203 185.344 197.259 185.28 197.333 185.243C197.407 185.206 197.492 185.198 197.571 185.223"
        fill="#E0E0E0"
      />
      <path
        d="M198.923 187.795L198.888 187.158L191.36 187.545C191.36 187.545 191.014 187.705 191.059 187.95L198.923 187.795Z"
        fill="#E0E0E0"
      />
      <path
        d="M195 185.568C195 185.605 195.192 185.618 195.384 185.738C195.576 185.858 195.681 186.018 195.716 186.003C195.751 185.988 195.688 185.765 195.454 185.625C195.22 185.485 194.99 185.533 195 185.568Z"
        fill="#263238"
      />
      <path
        d="M194.18 185.925C194.18 185.962 194.34 186.025 194.474 186.175C194.609 186.325 194.649 186.507 194.686 186.505C194.724 186.502 194.746 186.282 194.577 186.087C194.407 185.892 194.18 185.887 194.18 185.925Z"
        fill="#263238"
      />
      <path
        d="M193.732 186.925C193.766 186.925 193.816 186.737 193.714 186.53C193.612 186.322 193.43 186.24 193.415 186.28C193.4 186.32 193.512 186.422 193.594 186.595C193.677 186.767 193.702 186.92 193.732 186.925Z"
        fill="#263238"
      />
      <path
        d="M195.05 184.635C195.068 184.67 195.242 184.615 195.459 184.635C195.676 184.655 195.848 184.71 195.866 184.678C195.883 184.645 195.721 184.51 195.464 184.5C195.207 184.49 195.03 184.605 195.05 184.635Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M194.948 182.35L194.986 183.072L198.85 182.96L198.843 182.307L194.948 182.35Z"
          fill="black"
        />
      </g>
      <path
        d="M195.021 183.818C195.15 183.852 195.285 183.852 195.414 183.818C195.555 183.788 195.692 183.743 195.823 183.683C195.9 183.645 195.975 183.603 196.048 183.558C196.091 183.531 196.13 183.499 196.165 183.463C196.189 183.434 196.203 183.399 196.205 183.362C196.207 183.325 196.198 183.289 196.177 183.258C196.158 183.231 196.132 183.209 196.103 183.195C196.073 183.18 196.041 183.172 196.008 183.173C195.958 183.171 195.908 183.179 195.861 183.195C195.775 183.216 195.691 183.246 195.611 183.285C195.48 183.349 195.358 183.433 195.252 183.535C195.075 183.708 195.016 183.853 195.028 183.86C195.04 183.868 195.133 183.75 195.315 183.61C195.422 183.527 195.539 183.458 195.664 183.405C195.736 183.374 195.812 183.349 195.888 183.33C195.97 183.305 196.04 183.305 196.063 183.33C196.085 183.355 196.063 183.35 196.063 183.375C196.038 183.402 196.009 183.425 195.978 183.445C195.911 183.49 195.842 183.531 195.771 183.568C195.651 183.631 195.525 183.683 195.395 183.723C195.267 183.744 195.142 183.776 195.021 183.818Z"
        fill="#263238"
      />
      <path
        d="M195.071 183.87C195.085 183.87 195.158 183.737 195.168 183.487C195.171 183.341 195.15 183.195 195.105 183.055C195.079 182.968 195.044 182.884 195.001 182.805C194.979 182.754 194.945 182.71 194.901 182.676C194.857 182.642 194.806 182.62 194.751 182.612C194.716 182.614 194.683 182.625 194.654 182.645C194.625 182.665 194.603 182.693 194.589 182.725C194.571 182.772 194.558 182.82 194.552 182.87C194.539 182.958 194.539 183.047 194.552 183.135C194.577 183.282 194.638 183.421 194.729 183.54C194.886 183.742 195.048 183.79 195.051 183.772C195.053 183.755 194.928 183.68 194.801 183.487C194.731 183.375 194.686 183.249 194.669 183.117C194.658 183.042 194.658 182.965 194.669 182.89C194.669 182.805 194.709 182.737 194.741 182.745C194.774 182.752 194.849 182.805 194.881 182.872C194.923 182.939 194.957 183.011 194.983 183.085C195.03 183.211 195.059 183.343 195.068 183.477C195.088 183.72 195.051 183.865 195.071 183.87Z"
        fill="#263238"
      />
      <path
        d="M216.142 164.303C216.142 164.303 215.281 168.723 213.855 168.785C212.11 168.86 199.431 169.035 199.431 169.035L200.184 183.188L194.654 183.055L192.455 169.108C192.209 167.856 192.418 166.558 193.043 165.448L193.088 165.37C193.561 164.591 194.225 163.946 195.018 163.498C195.81 163.05 196.704 162.814 197.614 162.813L208.143 162.653L216.142 164.303Z"
        fill="#263238"
      />
      <path
        d="M197.052 181.545L197.277 185.605C197.277 185.605 193.237 187.198 193.208 187.95L201.071 187.795L200.977 181.458L197.052 181.545Z"
        fill="#407BFF"
      />
      <path
        d="M199.7 185.215C199.777 185.236 199.842 185.285 199.885 185.352C199.927 185.419 199.943 185.499 199.93 185.578C199.912 185.655 199.865 185.722 199.798 185.765C199.731 185.808 199.651 185.823 199.573 185.808C199.491 185.787 199.419 185.735 199.373 185.664C199.327 185.593 199.309 185.506 199.324 185.423C199.352 185.344 199.408 185.28 199.482 185.243C199.556 185.206 199.641 185.198 199.72 185.223"
        fill="#E0E0E0"
      />
      <path
        d="M201.072 187.795L201.037 187.158L193.509 187.545C193.509 187.545 193.163 187.705 193.208 187.95L201.072 187.795Z"
        fill="#E0E0E0"
      />
      <path
        d="M197.15 185.568C197.15 185.605 197.344 185.618 197.534 185.738C197.723 185.858 197.83 186.018 197.865 186.003C197.9 185.988 197.838 185.765 197.604 185.625C197.369 185.485 197.14 185.533 197.15 185.568Z"
        fill="#263238"
      />
      <path
        d="M196.332 185.925C196.332 185.962 196.492 186.025 196.624 186.175C196.756 186.325 196.798 186.507 196.836 186.505C196.873 186.502 196.896 186.282 196.726 186.087C196.556 185.892 196.332 185.887 196.332 185.925Z"
        fill="#263238"
      />
      <path
        d="M195.881 186.925C195.918 186.925 195.965 186.737 195.863 186.53C195.761 186.322 195.579 186.24 195.564 186.28C195.549 186.32 195.661 186.422 195.743 186.595C195.826 186.767 195.843 186.92 195.881 186.925Z"
        fill="#263238"
      />
      <path
        d="M197.192 184.635C197.209 184.67 197.384 184.615 197.601 184.635C197.818 184.655 197.99 184.71 198.007 184.678C198.025 184.645 197.863 184.51 197.606 184.5C197.349 184.49 197.192 184.605 197.192 184.635Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M197.097 182.35L197.137 183.072L200.999 182.96L200.992 182.307L197.097 182.35Z"
          fill="black"
        />
      </g>
      <path
        d="M197.169 183.818C197.299 183.852 197.434 183.852 197.563 183.818C197.704 183.788 197.841 183.743 197.972 183.683C198.05 183.646 198.125 183.604 198.197 183.558C198.24 183.531 198.279 183.499 198.314 183.463C198.338 183.434 198.352 183.399 198.354 183.362C198.356 183.325 198.346 183.289 198.326 183.258C198.307 183.231 198.281 183.209 198.252 183.195C198.222 183.18 198.19 183.172 198.157 183.173C198.107 183.171 198.057 183.179 198.01 183.195C197.924 183.216 197.84 183.246 197.76 183.285C197.629 183.349 197.508 183.433 197.404 183.535C197.224 183.708 197.154 183.853 197.177 183.86C197.199 183.868 197.282 183.75 197.464 183.61C197.571 183.527 197.688 183.458 197.813 183.405C197.885 183.374 197.96 183.349 198.037 183.33C198.119 183.305 198.189 183.305 198.212 183.33C198.234 183.355 198.212 183.35 198.212 183.375C198.186 183.402 198.158 183.425 198.127 183.445C198.06 183.49 197.991 183.531 197.92 183.568C197.799 183.631 197.673 183.683 197.543 183.723C197.416 183.744 197.291 183.776 197.169 183.818Z"
        fill="#263238"
      />
      <path
        d="M197.219 183.87C197.234 183.87 197.307 183.737 197.317 183.487C197.321 183.341 197.3 183.195 197.254 183.055C197.228 182.968 197.193 182.884 197.15 182.805C197.128 182.754 197.094 182.71 197.05 182.676C197.006 182.642 196.955 182.62 196.9 182.612C196.865 182.614 196.832 182.625 196.803 182.645C196.774 182.665 196.752 182.693 196.738 182.725C196.72 182.772 196.707 182.82 196.701 182.87C196.688 182.958 196.688 183.047 196.701 183.135C196.726 183.282 196.787 183.421 196.878 183.54C197.035 183.742 197.197 183.79 197.199 183.772C197.202 183.755 197.077 183.68 196.95 183.487C196.88 183.375 196.835 183.249 196.818 183.117C196.807 183.042 196.807 182.965 196.818 182.89C196.83 182.805 196.86 182.737 196.89 182.745C196.92 182.752 196.998 182.805 197.03 182.872C197.071 182.939 197.106 183.011 197.132 183.085C197.179 183.211 197.208 183.343 197.217 183.477C197.237 183.72 197.192 183.865 197.219 183.87Z"
        fill="#263238"
      />
      <path
        d="M217.528 164.303C217.528 164.303 216.67 168.723 215.241 168.785C213.496 168.86 200.817 169.035 200.817 169.035L201.588 183.188L196.058 183.055L193.846 169.108C193.6 167.856 193.809 166.558 194.434 165.448L194.479 165.37C194.952 164.59 195.617 163.946 196.41 163.498C197.203 163.05 198.097 162.814 199.007 162.813L209.534 162.653L217.528 164.303Z"
        fill="#263238"
      />
      <path
        d="M206.742 146.557L208.412 157.678L207.283 162.678L217.54 165.705C218.468 164.375 218.503 160.153 218.47 157.165C218.413 151.9 218.645 151.645 218.418 149.798C218.191 147.95 217.805 146.765 216.832 146.182L214.035 144.75C214.035 144.75 212.459 145.75 211.499 145.702C211.499 145.702 210.36 145.75 209.729 145.143C209.729 145.135 207.415 145.835 206.742 146.557Z"
        fill="#407BFF"
      />
      <path
        d="M206.742 146.557L203.323 151.282L207.978 154.022L207.622 152.305L206.742 146.557Z"
        fill="#407BFF"
      />
      <path
        d="M198.596 162.88C198.576 162.884 198.556 162.884 198.536 162.88L198.364 162.893C198.27 162.899 198.175 162.911 198.082 162.928C197.955 162.943 197.83 162.966 197.706 162.998C196.912 163.193 196.178 163.583 195.571 164.133C195.154 164.51 194.795 164.947 194.507 165.43C194.176 165.984 193.971 166.603 193.906 167.245C193.846 167.958 193.873 168.676 193.986 169.383C194.088 170.133 194.235 170.883 194.312 171.68C194.489 173.255 194.726 174.75 194.948 176.108C195.17 177.465 195.384 178.683 195.557 179.713C195.729 180.743 195.863 181.57 195.945 182.148C195.983 182.428 196.013 182.648 196.035 182.813C196.035 182.883 196.05 182.938 196.055 182.985C196.057 183.005 196.057 183.025 196.055 183.045C196.047 183.027 196.042 183.007 196.04 182.988C196.04 182.94 196.02 182.885 196.005 182.815L195.893 182.155C195.788 181.57 195.644 180.745 195.464 179.728C195.285 178.71 195.068 177.478 194.828 176.128C194.589 174.778 194.357 173.275 194.18 171.692C194.1 170.905 193.958 170.145 193.859 169.4C193.744 168.683 193.72 167.955 193.786 167.233C193.855 166.574 194.069 165.94 194.412 165.375C194.709 164.884 195.079 164.441 195.509 164.063C196.131 163.511 196.883 163.127 197.693 162.948C197.99 162.884 198.293 162.861 198.596 162.88Z"
        fill="#455A64"
      />
      <path
        d="M202.525 159.447C202.525 159.447 206.482 160.727 209.559 161.845C210.081 162.035 210.643 162.093 211.192 162.012C211.742 161.932 212.264 161.717 212.711 161.385C213.282 160.962 213.706 160.369 213.92 159.69L216.027 153.035L212.509 151.8L210.157 157.782L201.406 156.63"
        fill="#FFBE9D"
      />
      <path
        d="M197.292 158.075L197.259 157.76C197.281 157.527 197.377 157.308 197.534 157.135C197.618 157.041 197.72 156.963 197.833 156.905L198.867 156.362C199.021 156.284 199.196 156.256 199.366 156.282L201.488 156.645L203.924 156.965L203.425 159.75L202.298 159.375C202.298 159.375 201.483 159.827 200.979 159.72C200.979 159.72 200.513 160.032 200.321 159.802C200.321 159.802 199.905 160.162 199.748 160.14C199.591 160.117 199.281 159.935 199.294 159.827L199.306 159.682C199.226 159.726 199.136 159.747 199.045 159.743C198.954 159.74 198.866 159.711 198.79 159.66C198.682 159.576 198.6 159.463 198.553 159.335C198.359 159.315 198.175 159.235 198.027 159.107C197.929 158.963 197.864 158.8 197.835 158.627C197.835 158.627 197.369 158.4 197.292 158.075Z"
        fill="#FFBE9D"
      />
      <path
        d="M198.858 157.068C198.872 157.068 198.897 157.183 198.845 157.358C198.768 157.579 198.64 157.78 198.471 157.943C198.284 158.135 198.11 158.293 198.002 158.423C197.895 158.553 197.838 158.643 197.82 158.635C197.803 158.628 197.82 158.518 197.93 158.368C198.062 158.182 198.212 158.01 198.376 157.853C198.532 157.699 198.662 157.521 198.76 157.325C198.828 157.175 198.835 157.068 198.858 157.068Z"
        fill="#EB996E"
      />
      <path
        d="M199.261 158.18C199.143 158.627 198.885 159.025 198.526 159.315C198.618 159.108 198.735 158.913 198.875 158.735C199.077 158.425 199.227 158.163 199.261 158.18Z"
        fill="#EB996E"
      />
      <path
        d="M199.803 158.915C199.833 158.935 199.745 159.115 199.606 159.32C199.466 159.525 199.332 159.675 199.302 159.652C199.272 159.63 199.362 159.45 199.499 159.247C199.636 159.045 199.773 158.895 199.803 158.915Z"
        fill="#EB996E"
      />
      <path
        d="M199.703 158.623C199.703 158.638 199.648 158.64 199.583 158.6C199.541 158.572 199.505 158.535 199.478 158.492C199.451 158.449 199.433 158.4 199.426 158.35C199.417 158.301 199.42 158.25 199.433 158.202C199.445 158.153 199.469 158.108 199.501 158.07C199.548 158.013 199.596 157.998 199.608 158.01C199.621 158.023 199.526 158.163 199.553 158.33C199.581 158.498 199.718 158.59 199.703 158.623Z"
        fill="#EB996E"
      />
      <path
        d="M200.294 159.37C200.294 159.385 200.249 159.388 200.194 159.37C200.159 159.349 200.129 159.321 200.105 159.288C200.081 159.255 200.064 159.217 200.055 159.178C200.039 159.134 200.037 159.087 200.049 159.042C200.061 158.997 200.087 158.957 200.122 158.928C200.187 158.883 200.237 158.913 200.232 158.928C200.204 158.953 200.185 158.987 200.176 159.024C200.167 159.061 200.169 159.099 200.182 159.135C200.209 159.263 200.309 159.338 200.294 159.37Z"
        fill="#EB996E"
      />
      <path
        d="M199.359 157.827C199.359 157.842 199.304 157.855 199.229 157.827C199.179 157.803 199.136 157.766 199.105 157.721C199.073 157.675 199.054 157.622 199.049 157.567C199.044 157.511 199.053 157.456 199.076 157.405C199.099 157.354 199.134 157.31 199.179 157.277C199.246 157.23 199.301 157.235 199.304 157.25C199.306 157.265 199.164 157.377 199.179 157.555C199.194 157.732 199.369 157.797 199.359 157.827Z"
        fill="#EB996E"
      />
      <path
        d="M216.044 147.085C215.241 146.735 214.251 147.085 213.67 147.695C213.089 148.305 212.823 149.103 212.573 149.878C212.324 150.653 211.731 152.145 211.489 152.893L216.049 154.85C216.438 153.795 216.8 152.731 217.136 151.658C217.42 150.758 217.685 149.788 217.356 148.893"
        fill="#407BFF"
      />
      <path
        d="M217.356 148.893C217.376 148.927 217.392 148.964 217.403 149.003C217.443 149.11 217.472 149.222 217.49 149.335C217.562 149.76 217.551 150.194 217.458 150.615C217.319 151.256 217.134 151.885 216.907 152.5C216.658 153.215 216.408 154.013 216.112 154.87L216.089 154.933L216.024 154.905L212.813 153.53L211.462 152.95L211.407 152.925L211.424 152.875C211.793 151.785 212.205 150.788 212.514 149.875C212.649 149.433 212.816 149.001 213.012 148.583C213.189 148.209 213.43 147.87 213.726 147.583C214.177 147.156 214.775 146.919 215.396 146.923C215.566 146.928 215.734 146.958 215.895 147.013C215.949 147.031 216.001 147.055 216.052 147.083C215.842 147.013 215.622 146.974 215.401 146.968C214.8 146.981 214.225 147.221 213.793 147.64C213.511 147.921 213.282 148.251 213.115 148.613C212.925 149.029 212.766 149.459 212.636 149.898C212.334 150.825 211.923 151.828 211.561 152.91L211.524 152.835L212.878 153.415L216.084 154.795L215.997 154.83C216.291 153.973 216.565 153.18 216.812 152.468C217.044 151.858 217.237 151.234 217.388 150.6C217.485 150.191 217.508 149.768 217.458 149.35C217.406 149.048 217.346 148.895 217.356 148.893Z"
        fill="#263238"
      />
      <path
        d="M211.511 160.065C211.497 160.065 211.462 159.815 211.307 159.358C211.212 159.088 211.086 158.83 210.931 158.59C210.733 158.274 210.46 158.012 210.138 157.828H210.15L205.211 157.183L202.219 156.78L200.92 156.6L199.79 156.418L199.292 156.34C199.152 156.315 199.008 156.341 198.885 156.413L198.244 156.723L197.838 156.915C197.792 156.941 197.742 156.96 197.691 156.973C197.734 156.942 197.779 156.914 197.826 156.89L198.222 156.678L198.853 156.348C198.988 156.266 199.148 156.234 199.304 156.258L199.803 156.328L200.932 156.493L202.234 156.663L205.226 157.055L210.158 157.733H210.173C210.51 157.928 210.793 158.207 210.993 158.543C211.146 158.79 211.268 159.057 211.354 159.335C211.409 159.509 211.453 159.687 211.484 159.868C211.5 159.932 211.51 159.998 211.511 160.065Z"
        fill="#EB996E"
      />
      <path
        d="M208.412 157.5C208.378 157.383 208.355 157.262 208.345 157.14C208.303 156.89 208.25 156.555 208.188 156.157C208.046 155.305 207.859 154.17 207.652 152.907C207.445 151.645 207.255 150.517 207.116 149.657C207.053 149.26 207.004 148.927 206.964 148.672C206.938 148.553 206.924 148.43 206.924 148.307C206.965 148.423 206.995 148.542 207.014 148.662L207.206 149.642C207.363 150.47 207.57 151.612 207.782 152.892C207.993 154.172 208.168 155.31 208.28 156.142C208.338 156.56 208.375 156.892 208.397 157.142C208.414 157.261 208.419 157.381 208.412 157.5Z"
        fill="#263238"
      />
      <path
        d="M255.661 212.225C255.661 212.263 219.572 212.29 175.066 212.29C130.56 212.29 94.4666 212.263 94.4666 212.225C94.4666 212.188 130.548 212.16 175.071 212.16C219.594 212.16 255.661 212.19 255.661 212.225Z"
        fill="#263238"
      />
      <path
        d="M99.2989 203.89C98.8975 204.432 98.152 204.227 97.8154 203.89C97.4788 203.552 97.3416 203.05 97.2194 202.58C96.9427 201.507 96.6659 200.382 96.9227 199.305C96.985 198.977 97.1369 198.673 97.3616 198.427C97.4748 198.306 97.6178 198.217 97.7762 198.169C97.9346 198.121 98.1029 198.115 98.2642 198.152C98.6406 198.262 98.865 198.64 99.0271 198.997C99.4965 200.052 99.7273 201.198 99.7028 202.352C99.7225 202.894 99.5811 203.429 99.2964 203.89"
        fill="#407BFF"
      />
      <g opacity="0.2">
        <path
          d="M99.2989 203.89C98.8975 204.432 98.152 204.227 97.8154 203.89C97.4788 203.552 97.3416 203.05 97.2194 202.58C96.9427 201.507 96.6659 200.382 96.9227 199.305C96.985 198.977 97.1369 198.673 97.3616 198.427C97.4748 198.306 97.6178 198.217 97.7762 198.169C97.9346 198.121 98.1029 198.115 98.2642 198.152C98.6406 198.262 98.865 198.64 99.0271 198.997C99.4965 200.052 99.7273 201.198 99.7028 202.352C99.7225 202.894 99.5811 203.429 99.2964 203.89"
          fill="black"
        />
      </g>
      <path
        d="M97.7779 206.648C97.14 206.327 96.4166 206.219 95.7134 206.34C95.3298 206.365 94.9652 206.516 94.6762 206.77C94.4119 207.048 94.352 207.545 94.6388 207.798C94.8255 207.929 95.0517 207.993 95.2795 207.978C95.8281 207.978 96.4389 207.905 96.8678 208.248C97.1171 208.445 97.2717 208.763 97.5684 208.863C97.7168 208.902 97.8738 208.891 98.0156 208.832C98.1574 208.774 98.2762 208.67 98.3538 208.538C98.504 208.265 98.5441 207.946 98.466 207.645C98.4354 207.437 98.3574 207.239 98.238 207.066C98.1187 206.893 97.9612 206.75 97.7779 206.648Z"
        fill="#407BFF"
      />
      <path
        d="M100.336 204.71C100.144 204.445 100.02 204.138 99.9745 203.814C99.9288 203.49 99.9631 203.16 100.074 202.853C100.299 202.239 100.688 201.699 101.199 201.293C101.786 200.798 102.463 200.423 103.193 200.187C103.476 200.082 103.778 200.039 104.079 200.062C104.228 200.075 104.373 200.124 104.499 200.206C104.626 200.287 104.73 200.399 104.804 200.53C105.001 200.927 104.789 201.4 104.555 201.78C104.16 202.457 103.712 203.101 103.216 203.707C102.817 204.195 102.363 204.675 101.77 204.885C101.176 205.095 100.568 205.05 100.274 204.635"
        fill="#407BFF"
      />
      <path
        d="M99.1019 212.225C99.0833 212.143 99.0741 212.059 99.0745 211.975C99.0595 211.79 99.0446 211.56 99.0246 211.287C98.9999 210.952 98.9574 210.618 98.8974 210.287C98.819 209.881 98.7081 209.481 98.5658 209.092C98.427 208.703 98.2331 208.335 97.9899 208C97.792 207.729 97.5365 207.505 97.2419 207.345C97.0422 207.238 96.8267 207.164 96.6036 207.125C96.5191 207.121 96.4354 207.107 96.3542 207.082C96.4371 207.074 96.5207 207.074 96.6036 207.082C96.8352 207.106 97.0608 207.171 97.2693 207.275C97.58 207.433 97.8504 207.661 98.0597 207.94C98.3125 208.279 98.5133 208.654 98.6556 209.052C98.8026 209.441 98.9152 209.842 98.9922 210.25C99.048 210.588 99.0838 210.928 99.0994 211.27C99.0994 211.562 99.1144 211.797 99.1144 211.96C99.1191 212.049 99.1149 212.137 99.1019 212.225Z"
        fill="#263238"
      />
      <path
        d="M99.0694 211.695C99.064 211.656 99.064 211.616 99.0694 211.577C99.0694 211.49 99.0694 211.38 99.0694 211.245C99.0694 210.957 99.0894 210.537 99.0869 210.022C99.0869 208.987 99.0395 207.56 98.9099 205.987C98.7802 204.415 98.5882 202.987 98.4112 201.987C98.3688 201.737 98.3264 201.502 98.284 201.302C98.2416 201.102 98.2117 200.927 98.1793 200.785C98.1469 200.642 98.1269 200.535 98.1095 200.46C98.0998 200.422 98.0939 200.384 98.092 200.345C98.092 200.345 98.1095 200.382 98.1294 200.455C98.1494 200.527 98.1818 200.645 98.2167 200.777C98.2516 200.91 98.2965 201.09 98.3414 201.29C98.3863 201.49 98.4361 201.72 98.4835 201.975C98.7325 203.298 98.9106 204.633 99.0171 205.975C99.1467 207.55 99.1866 208.975 99.1667 210.02C99.1667 210.537 99.1367 210.957 99.1143 211.245C99.1143 211.38 99.0944 211.495 99.0869 211.577C99.0837 211.617 99.0778 211.656 99.0694 211.695Z"
        fill="#263238"
      />
      <path
        d="M99.1367 208.938C99.1263 208.821 99.1263 208.704 99.1367 208.588C99.1567 208.365 99.1941 208.043 99.2689 207.65C99.4667 206.623 99.7882 205.624 100.226 204.675C100.648 203.716 101.239 202.841 101.972 202.092C102.1 201.965 102.235 201.846 102.378 201.735C102.44 201.685 102.495 201.635 102.55 201.595L102.707 201.493C102.801 201.426 102.901 201.367 103.004 201.315C102.66 201.571 102.333 201.848 102.024 202.145C101.313 202.899 100.734 203.769 100.314 204.717C100.076 205.235 99.8719 205.768 99.7027 206.312C99.559 206.756 99.4383 207.207 99.3412 207.662C99.2589 208.052 99.209 208.372 99.1766 208.592C99.1711 208.708 99.1577 208.824 99.1367 208.938Z"
        fill="#263238"
      />
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M135 146C121.225 146 110 157.225 110 171C110 184.775 121.225 196 135 196C148.775 196 160 184.775 160 171C160 157.225 148.775 146 135 146ZM146.95 165.25L132.775 179.425C132.425 179.775 131.95 179.975 131.45 179.975C130.95 179.975 130.475 179.775 130.125 179.425L123.05 172.35C122.325 171.625 122.325 170.425 123.05 169.7C123.775 168.975 124.975 168.975 125.7 169.7L131.45 175.45L144.3 162.6C145.025 161.875 146.225 161.875 146.95 162.6C147.675 163.325 147.675 164.5 146.95 165.25Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="341"
          height="341"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.694118 0 0 0 0 0.721569 0 0 0 0 0.784314 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_0_1">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(105 141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OffcerSuccessForkliftIcon;
