import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultCheckbox from "../components/UI/DefaultCheckbox";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import DefaultPhoneField from "../components/UI/DefaultPhoneField";
import DefaultSelect from "../components/UI/DefaultSelect";
import * as validators from "../utils/validators";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import getAgreementByTypeId from "../api/getAgreementByTypeId";
import getUserListRoleRequest from "../api/getUserListRoleRequest";
import registerRequest from "../api/registerRequest";
import Agreement from "../components/Agreement";
import { AgreementTypeEnum } from "../enums/agreement-type.enum";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 80px 0;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px;
  padding: 20px;
`;

const StyledCheckboxWrapper = styled.div`
  align-self: left;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const StyledLoginNowLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;

  color: #3a3a3a;
`;

const StyledAgreementLabel = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  :hover,
  :focus {
    text-decoration: underline;
    color: #084bbb;
  }
`;

const SignUp = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState([
    {
      key: "name",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "surname",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "companyName",
      value: null,
      type: "text",
      required: false,
      hasError: false,
    },
    {
      key: "address",
      value: null,
      type: "textLength",
      min: 10,
      max: 100,
      required: true,
      hasError: false,
    },
    {
      key: "email",
      value: null,
      type: "email",
      required: true,
      hasError: false,
    },
    {
      key: "phone",
      value: null,
      type: "number",
      required: true,
      hasError: false,
    },
    {
      key: "password",
      value: null,
      type: "simplePassword",
      required: true,
      hasError: false,
    },
    {
      key: "passwordAgain",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "registeryNo",
      value: null,
      type: "tcknvkn",
      required: true,
      hasError: false,
    },
    {
      key: "condition1",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
    {
      key: "condition2",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
    {
      key: "condition3",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
    {
      key: "condition4",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
    {
      key: "roleId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    const result = await getUserListRoleRequest();

    if (result?.code === 200) {
      const converted = result.data.map((item, index) => ({
        id: item.id,
        label: item.name,
      }));
      setRoles(converted ?? []);
    }
  };

  const handleRegister = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      name: formData.find((q) => q.key === "name").value,
      surname: formData.find((q) => q.key === "surname").value,
      email: formData.find((q) => q.key === "email").value,
      phone: formData.find((q) => q.key === "phone").value,
      password: formData.find((q) => q.key === "password").value,
      registeryNo: formData.find((q) => q.key === "registeryNo").value,
      roleId: formData.find((q) => q.key === "roleId").value,
      companyName: formData.find((q) => q.key === "companyName").value,
      address: formData.find((q) => q.key === "address").value,
    };

    const result = await registerRequest(request);

    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");
      setTimeout(() => {
        navigate("/upload-file-message");
        setIsLoading(false);
      }, 1000);
    } else if (result?.code === 409) {
      setError("Kullanıcı halihazırda sistemde kayıtlıdır.");
      setIsLoading(false);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const handleAgreement = async (agreementTypeId) => {
    const result = await getAgreementByTypeId(agreementTypeId);

    if (result?.code === 200) {
      setContent(result.data?.content);
      setShowAgreement(true);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <Wrapper>
      <NavBar />
      <ContentWrapper>
        {showAgreement ? (
          <Agreement
            showAgreement={showAgreement}
            setShowAgreement={setShowAgreement}
            // header={"Şartlar ve Koşullar"}
            content={content}
          />
        ) : null}
        <StyledGridContainer container>
          <Grid item xs={12}>
            <DefaultSelect
              id={"role"}
              label={"Niteliğiniz"}
              options={roles}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "roleId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "roleId");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "roleId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="identity"
              type={"text"}
              label="TCKN/VKN"
              variant="filled"
              backgroundColor="#E5E5E587"
              maxLength={11}
              startIcon={<AccountCircleOutlinedIcon />}
              value={formData.find((q) => q.key === "registeryNo").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "registeryNo");
                val.value = e.target.value;
                val.hasError =
                  !validators.checkIsValidTurkishIdentity(e.target.value) &&
                  !validators.checkIsValidTurkishCompanyIdentity(
                    e.target.value
                  );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTurkishIdentity(
                  formData.find((q) => q.key === "registeryNo").value
                ) &&
                !validators.checkIsValidTurkishCompanyIdentity(
                  formData.find((q) => q.key === "registeryNo").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="name"
              type={"text"}
              label="İsim"
              maxLength={50}
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<AccountCircleOutlinedIcon />}
              value={formData.find((q) => q.key === "name").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "name");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextField(
                  e.target.value
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextField(
                  formData.find((q) => q.key === "name").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="surname"
              type={"text"}
              label="Soyisim"
              maxLength={50}
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<AccountCircleOutlinedIcon />}
              value={formData.find((q) => q.key === "surname").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "surname");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextField(
                  e.target.value
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextField(
                  formData.find((q) => q.key === "surname").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="companyName"
              type={"text"}
              maxLength={200}
              label="Şirket Adı"
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<AccountCircleOutlinedIcon />}
              value={formData.find((q) => q.key === "companyName").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "companyName");
                val.value = e.target.value;
                setFormData([...formData]);
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" gap="20px">
            <DefaultInputFeild
              id="address"
              type={"text"}
              label="Adres"
              placeholderText={"Açık Adres Giriniz"}
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<AccountCircleOutlinedIcon />}
              value={formData.find((q) => q.key === "address").value ?? ""}
              multiline={true}
              rowCount={2}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "address");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextLength(
                  e.target.value,
                  10,
                  100
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextLength(
                  formData.find((q) => q.key === "address").value,
                  10,
                  100
                )
              }
            />
            {isSubmit &&
            !validators.checkIsValidTextLength(
              formData.find((q) => q.key === "address").value,
              10,
              100
            ) ? (
              <AlertBox
                severity="error"
                message={`Adres ${
                  formData.find((q) => q.key === "address").min
                } karakterden fazla, ${
                  formData.find((q) => q.key === "address").max
                } karakterden az olmalıdır.`}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="email"
              type={"text"}
              label="E-Posta"
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<MailOutlineOutlinedIcon />}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "email");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidMail(e.target.value);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidMail(
                  formData.find((q) => q.key === "email").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultPhoneField
              id="phone"
              label=""
              backgroundColor="#E5E5E587"
              placeholderText="+90 (5XX) XXX XX XX"
              value={formData.find((q) => q.key === "phone").value}
              error={
                isSubmit &&
                !validators.checkIsValidPhone(
                  formData.find((q) => q.key === "phone").value
                )
              }
              onChange={(e) => {
                let val = formData.find((q) => q.key === "phone");
                val.value = e;
                val.hasError = !validators.checkIsValidPhone(e);
                setFormData([...formData]);
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" gap="20px">
            <DefaultInputFeild
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Şifre"
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={
                <>
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <LockOutlinedIcon />
                </>
              }
              onChange={(e) => {
                let val = formData.find((q) => q.key === "password");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidSimplePassword(
                  e.target.value,
                  6
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSimplePassword(
                  formData.find((q) => q.key === "password").value,
                  6
                )
              }
            />
            {isSubmit &&
            !validators.checkIsValidSimplePassword(
              formData.find((q) => q.key === "password").value,
              6
            ) ? (
              <AlertBox
                severity="error"
                // message="Şifreniz en az 1 harf, 1 rakam ve 1 özel karakter içermeli, en az 8 karakterden oluşmalıdır."
                message="Şifreniz en az 1 harf ve 1 rakam içeren toplam 6 karakterden oluşmalıdır."
              />
            ) : null}
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="column" gap="20px">
            <DefaultInputFeild
              id="passwordAgain"
              autoComplete="current-password-again"
              type={showPasswordAgain ? "text" : "password"}
              label="Şifre Tekrar"
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={
                <>
                  <IconButton
                    onClick={() => {
                      setShowPasswordAgain(!showPasswordAgain);
                    }}
                  >
                    {showPasswordAgain ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  <LockOutlinedIcon />
                </>
              }
              onChange={(e) => {
                let val = formData.find((q) => q.key === "passwordAgain");
                val.value = e.target.value;
                val.hasError =
                  formData.find((q) => q.key === "password").value !==
                  e.target.value;
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                formData.find((q) => q.key === "password").value !==
                  formData.find((q) => q.key === "passwordAgain").value
              }
            />
            {isSubmit &&
            formData.find((q) => q.key === "password").value !==
              formData.find((q) => q.key === "passwordAgain").value ? (
              <AlertBox
                severity="error"
                message="Aynı şifrenizi tekrar giriniz."
              />
            ) : null}
          </Grid>
        </StyledGridContainer>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            value={Boolean(formData.find((q) => q.key === "condition1")?.value)}
            label={
              <span>
                <StyledAgreementLabel
                  onClick={() => {
                    if (!showAgreement) {
                      handleAgreement(AgreementTypeEnum.UserAgreement);
                    }
                  }}
                >
                  Kullanıcı Sözleşmesi
                </StyledAgreementLabel>
                'ni okudum ve kabul ediyorum.
              </span>
            }
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition1");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition1").value
            }
          />
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            value={Boolean(formData.find((q) => q.key === "condition2")?.value)}
            label={
              <span>
                <StyledAgreementLabel
                  onClick={() => {
                    if (!showAgreement) {
                      handleAgreement(AgreementTypeEnum.TermsOfUse);
                    }
                  }}
                >
                  Site Kullanım Koşulları
                </StyledAgreementLabel>
                'nı okudum ve kabul ediyorum.
              </span>
            }
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition2");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition2").value
            }
          />
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            value={Boolean(formData.find((q) => q.key === "condition3")?.value)}
            label={
              <span>
                <StyledAgreementLabel
                  onClick={() => {
                    if (!showAgreement) {
                      handleAgreement(AgreementTypeEnum.ClearenceAgreement);
                    }
                  }}
                >
                  Aydınlatma Metni
                </StyledAgreementLabel>
                'ni okudum ve kabul ediyorum.
              </span>
            }
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition3");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition3").value
            }
          />
        </StyledCheckboxWrapper>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            value={Boolean(formData.find((q) => q.key === "condition4")?.value)}
            label={
              <span>
                <StyledAgreementLabel
                  onClick={() => {
                    if (!showAgreement) {
                      handleAgreement(AgreementTypeEnum.ApplicationAgreement);
                    }
                  }}
                >
                  İlgili Kişi Başvuru Formu
                </StyledAgreementLabel>
                'nu okudum ve kabul ediyorum.
              </span>
            }
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition4");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition4").value
            }
          />
        </StyledCheckboxWrapper>
        {showAlert ? (
          <Grid item xs={12}>
            <AlertBox
              severity={showAlertDetail?.severity}
              title={showAlertDetail?.title}
              message={showAlertDetail?.message}
            />
          </Grid>
        ) : null}
        <div style={{ padding: "8px 18px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Kayıt Ol</StyledButtontext>}
            isProgress={isLoading}
            onClick={() => handleRegister()}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <StyledLoginNowLabel>
            Hesabınız var mı?
            <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ color: "rgba(8, 75, 187, 1)", fontWeight: "600" }}
            >
              &nbsp; Giriş Yap
            </span>
          </StyledLoginNowLabel>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SignUp;
