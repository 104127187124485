import WestIcon from "@mui/icons-material/West";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

import getUserAddressRequest from "../api/getUserAddress";
import updateUserAddress from "../api/updateUserAddress";
import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import * as validators from "../utils/validators";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: #d4d6dc7d; */
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 80px 0 80px 0;
  height: 100%;
  min-height: 100vh;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  color: #ffffff;
`;

const EditAddress = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([
    {
      key: "address",
      value: null,
      type: "textLength",
      min: 10,
      max: 100,
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    const result = await getUserAddressRequest(user.id);

    if (result?.code === 200) {
      let val = formData.find((q) => q.key === "address");
      val.value = result?.data?.address;
      setFormData([...formData]);
    }
    setIsLoading(false);
  };

  const handleUpdateAddress = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      address: formData.find((q) => q.key === "address").value,
      userId: user.id,
    };

    const result = await updateUserAddress(request);

    if (result?.code === 200) {
      setSuccess("Güncelleme işleminiz gerçekleştirildi.");
      setTimeout(() => {
        setIsLoading(false);
        setShowAlert(false);
      }, 1000);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <StyledWrapper>
      <NavBar header={"Adres Bilgilerim"} showLogo={false} />
      <ContentWrapper>
        <StyledGridContainer
          container
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Profilim"}
              icon={<WestIcon />}
              onClick={() => {
                navigate("/profile");
              }}
            />
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="address"
                  type={"text"}
                  label="Adres"
                  placeholderText={"Açık Adres Giriniz"}
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={formData.find((q) => q.key === "address").value ?? ""}
                  multiline={true}
                  rowCount={2}
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "address");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidTextLength(
                      e.target.value,
                      10,
                      100
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidTextLength(
                      formData.find((q) => q.key === "address").value,
                      10,
                      100
                    )
                  }
                />
                {isSubmit &&
                !validators.checkIsValidTextLength(
                  formData.find((q) => q.key === "address").value,
                  10,
                  100
                ) ? (
                  <AlertBox
                    severity="error"
                    message={`Adres ${
                      formData.find((q) => q.key === "address").min
                    } karakterden fazla, ${
                      formData.find((q) => q.key === "address").max
                    } karakterden az olmalıdır.`}
                  />
                ) : null}
              </Grid>
              <Grid>
                <DefaultButton
                  size={"small"}
                  variant={"contained"}
                  buttonColor={"#084BBB"}
                  buttonTextColor={"#FFFFFF"}
                  buttonTextTransform={"none"}
                  buttonMinHeight={48}
                  buttonRadius={"10px"}
                  fullWidth={true}
                  buttonText={<StyledButtontext>Kaydet</StyledButtontext>}
                  onClick={() => handleUpdateAddress()}
                />
              </Grid>
            </>
          )}
          {showAlert ? (
            <Grid item xs={12}>
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
        </StyledGridContainer>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default EditAddress;
