import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import VerticalRoadIcon from "../../assets/icons/VerticalRoadIcon";
import { convertStatus } from "../OfferStatus";

const TabsWrapper = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

const StyledCard = styled.div`
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  min-height: 136px;
`;

const StyledCardContainer = styled.div``;

const StyledCardDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
`;

const StyledCardHeader = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #000000 !important;
  margin: 8px 8px 8px 0px !important;
  min-height: 32px !important;
  /* width: -webkit-fill-available; */

  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
`;

const StyledCardDetail = styled.div`
  display: flex;
  justify-content: start;
  gap: 4px;
  padding-top: 4px;
`;

const StyledCardDetailPrimary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.58);
`;

const StyledCardDetailSecondary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #0a0909;
`;

const StyledSourceDestinationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSourcePrimary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #c4c4c4;
`;

const StyledSourceSecondary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.77);
  word-break: break-all;
`;

const StyledRoadWrapper = styled.div`
  padding: 0 4px;
  max-width: -webkit-fill-available !important;
`;

const OfferCard = ({ advertisements, offer, preventLink }) => {
  // ! offer note manipulation added for empty note navigate bug fix
  useEffect(() => {
    if (!(offer?.note?.trim()?.length > 0)) {
      offer.note = " ";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleClickEvent = () => {
    if (preventLink) return;

    offer?.advertisementTypeId === 1
      ? navigate(
          `/offer-load/${offer?.advertisementId}/true/${offer?.note}/${offer?.offerAmount}/${offer?.id}`
        )
      : navigate(
          `/offer-vehicle/${offer?.advertisementId}/true/${offer?.note}/${offer?.offerAmount}/${offer?.id}`
        );
  };

  const numberWithCommas = (number) => {
    let nf = Intl.NumberFormat("tr-TR");
    return nf.format(number);
  };

  return (
    <TabsWrapper>
      <StyledCard onClick={() => handleClickEvent()}>
        <StyledCardContainer>
          <Grid container>
            <Grid item xs={4}>
              <img
                src={
                  offer?.advertisementTypeId === 1
                    ? "/images/cargo.svg"
                    : "/images/vehicle.svg"
                }
                style={{ height: "100%", cursor: "pointer" }}
                alt="cargo"
              />
            </Grid>
            <Grid item xs={8}>
              <StyledCardDetailHeader>
                <StyledCardHeader>
                  {offer?.header ??
                    offer?.advertisementHeader ??
                    "Teklifi Görüntüle"}
                </StyledCardHeader>
                {/* <IconButton>
                  <FavoriteCirceIcon />
                </IconButton> */}
              </StyledCardDetailHeader>
              <Grid container>
                <Grid item xs={7}>
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Durum:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {convertStatus(offer)}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>
                      Teklif Veren:
                    </StyledCardDetailPrimary>
                  </StyledCardDetail>
                  <StyledCardDetail>
                    <StyledCardDetailSecondary>
                      {offer?.ownerUser}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Fiyat:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {`${numberWithCommas(offer?.offerAmount)} TL`}
                    </StyledCardDetailSecondary>{" "}
                  </StyledCardDetail>
                  {/* <StyledCardDetail>
                    <StyledCardDetailPrimary>
                      Özellikli Not:
                    </StyledCardDetailPrimary>
                  </StyledCardDetail>
                  <StyledCardDetail>
                    <StyledCardDetailSecondary>
                      {offer?.note}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail> */}
                </Grid>
                <Grid item xs={1}>
                  <VerticalRoadIcon />
                </Grid>
                <Grid item xs={4}>
                  <StyledSourceDestinationWrapper>
                    <StyledRoadWrapper>
                      <StyledSourcePrimary>Nereden:</StyledSourcePrimary>
                      <StyledSourceSecondary>
                        {offer?.originCity}
                      </StyledSourceSecondary>
                      <StyledSourcePrimary>&nbsp;</StyledSourcePrimary>
                      <StyledSourcePrimary>Nereye:</StyledSourcePrimary>
                      <StyledSourceSecondary>
                        {offer?.destinationCity}
                      </StyledSourceSecondary>
                    </StyledRoadWrapper>
                  </StyledSourceDestinationWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledCardContainer>
      </StyledCard>
    </TabsWrapper>
  );
};

export default OfferCard;
