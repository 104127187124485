import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";

import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import ProfileListMenu from "../components/ProfileListMenu";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";

import * as validators from "../utils/validators";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled(Grid)`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const StyledInputLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  color: rgba(10, 9, 9, 0.83);
`;

const MenuOptions = [
  {
    label: "bilgi@yukline.com",
    id: 1,
    icon: <ForwardToInboxIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
  {
    label: "0212 922 22 22",
    id: 2,
    icon: <PhoneForwardedIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
];

const ContactUs = () => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");

  const handleListClick = (selected) => {
    if (selected?.url && selected?.url?.length > 0) {
      navigate(selected.url);
    }
  };

  const handleSendMessage = () => {
    setIsSubmit(true);
    if (!message) {
      return;
    }

    let params = {
      type: "vehicle",
      title: "Mesajınız İletildi",
      message: "Mesajınız başarıyla iletilmiştir.",
    };

    navigate(
      `/success-message/${params.type}/${params.title}/${
        params.message ?? " "
      }/${params.button}`
    );
  };

  return (
    <Wrapper>
      <NavBar />
      <StyledContainer container>
        <Grid
          item
          xs={12}
          style={{
            padding: "16px 0px 0px 16px",
          }}
        >
          <DefaultBackButton
            label={"Profilim"}
            icon={<ArrowBackIosIcon />}
            onClick={() => {
              navigate("/profile");
            }}
          />
          {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
        </Grid>
        <ProfileListMenu
          options={MenuOptions}
          handleListClick={handleListClick}
        />
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="10px"
          padding={"0 20px"}
        >
          <StyledInputLabel>Mesajın:</StyledInputLabel>
          <DefaultInputFeild
            id="password_again"
            type={"password"}
            placeholderText={"Örnek yazı..."}
            variant="filled"
            backgroundColor="#E5E5E587"
            multiline={true}
            rowCount={4}
            onChange={(e) => setMessage(e.target.value)}
            error={isSubmit && !validators.checkIsValidText(message)}
          />
          {isSubmit && !validators.checkIsValidText(message) && (
            <AlertBox
              severity="error"
              message="Mesajınız 2 karakterden fazla olmalıdır"
            />
          )}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent={"center"}>
          <DefaultButton
            buttonTextSize={"18px"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonTextWeight={"600"}
            buttonTextLineHeight={"21px"}
            buttonMinHeight={48}
            buttonRadius={"11px"}
            buttonText={"Gönder"}
            buttonMinWidth={"154px"}
            onClick={() => handleSendMessage()}
          />
        </Grid>
      </StyledContainer>
      <BottomMenu />
    </Wrapper>
  );
};

export default ContactUs;
