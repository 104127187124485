import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { deviceMax } from "../../utils/device";

const StyledNavBar = styled.div`
  max-width: 425px;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0px;
  z-index: 2;
  background: #084bbb !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${deviceMax.mobileL} {
    left: 0px;
  }
`;

const StyledNavBarText = styled.div`
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23.46px !important;
  padding-left: 8px;

  color: #ffffff;
`;

const NavBar = ({ header, showLogo = true }) => {
  const navigate = useNavigate();
  return (
    <StyledNavBar
      onClick={() => {
        navigate("/");
      }}
    >
      {showLogo ? (
        <img
          src={"/images/logo_white.png"}
          alt="logo"
          width={"200"}
          height={"65"}
        />
      ) : null}

      <StyledNavBarText
        onClick={() => {
          navigate("/");
        }}
      >
        {header && header?.length > 0 ? header : ""}
      </StyledNavBarText>
    </StyledNavBar>
  );
};

export default NavBar;
