import api from "../config/api";

const getAgreementAdvertisement = async (typeId, advertisementId, offerId) => {
  const res = await api.get(
    `offer/agreement/${typeId}/${advertisementId}/${offerId}`
  );
  return res?.data;
};

export default getAgreementAdvertisement;
