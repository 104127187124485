import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ContactsIcon from '@mui/icons-material/Contacts';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BottomMenu from "../components/Layout/BottomMenu";
import ProfileNavBar from "../components/Layout/ProfileNavBar";
import ProfileListMenu from "../components/ProfileListMenu";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  padding: 250px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const MenuOptions = [
  // {
  //   label: "Favorilerim",
  //   id: 1,
  //   icon: <BookmarksOutlinedIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  // },
  {
    label: "Kişisel Bilgilerim",
    id: 1,
    icon: <PersonIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
    url: "/edit-profile",
  },  {
    label: "Adres Bilgilerim",
    id: 9,
    icon: <ContactsIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
    url: "/edit-address",
  },
  {
    label: "Ödeme Bilgilerim",
    id: 2,
    icon: <AccountBalanceWalletIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
    url: "/edit-payment-profile",
  },
  {
    label: "Belgelerim",
    id: 4,
    icon: (
      <QrCodeScannerOutlinedIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />
    ),
    url: "/my-documents",
  },
  {
    label: "Araçlarım",
    id: 3,
    icon: (
      <LocalShippingOutlinedIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />
    ),
    url: "/my-vehicles",
  },
  {
    label: "Ayarlar",
    id: 6,
    icon: <SettingsOutlinedIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
    url: "/settings",
  },
  {
    label: "Bize Ulaşın",
    id: 7,
    icon: <HelpOutlineIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
    url: "/contact-us",
  },
  {
    label: "Çıkış",
    id: 8,
    icon: (
      <LogoutIcon
        htmlColor="#BE1010"
        sx={{ fontSize: 24, transform: "rotate(180deg)" }}
      />
    ),
    url: "/logout",
  },
];

const Profile = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedtab] = useState(0);
  const navigate = useNavigate();

  const handleListClick = (selected) => {
    if (selected?.url && selected?.url?.length > 0) {
      navigate(selected.url);
    }
  };

  return (
    <StyledWrapper>
      <ProfileNavBar />
      <StyledContainer>
        <ProfileListMenu
          options={MenuOptions}
          handleListClick={handleListClick}
        />
      </StyledContainer>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default Profile;
