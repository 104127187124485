import { LoadingButton } from "@mui/lab";

const DefaultButton = ({
  size,
  variant,
  disabled,
  buttonColor,
  buttonColorDisabled,
  onClick,
  iconBeforeText,
  iconAfterText,
  buttonText,
  buttonTextSize,
  buttonTextColor,
  buttonTextWeight,
  buttonTextTransform,
  buttonTextLineHeight,
  buttonMinWidth,
  buttonMinHeight,
  buttonMaxWidth,
  buttonBorder,
  buttonRadius,
  buttonJustifyContent,
  fullWidth,
  isProgress,
  type,
}) => {
  return (
    <LoadingButton
      type={type}
      loading={isProgress}
      loadingPosition="start"
      startIcon={<></>}
      fullWidth={fullWidth}
      style={{
        backgroundColor: disabled ? buttonColorDisabled : buttonColor,
        textTransform: buttonTextTransform,
        color: buttonTextColor,
        minWidth: buttonMinWidth,
        minHeight: buttonMinHeight,
        maxWidth: buttonMaxWidth,
        borderRadius: buttonRadius ?? "8px",
        fontSize: buttonTextSize,
        justifyContent: buttonJustifyContent,
        fontWeight: buttonTextWeight,
        lineHeight: buttonTextLineHeight,
        border: buttonBorder,
      }}
      size={size}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {iconBeforeText}
      {buttonText}
      {iconAfterText}
    </LoadingButton>
  );
};

export default DefaultButton;
