import api from "../config/api";

const loadAdvertisementListRequest = async (pagination) => {
  const res = await api.get(
    `advertisement/list/load-advertisements?page=${pagination.page}&limit=${pagination.limit}`
  );
  return res?.data;
};

export default loadAdvertisementListRequest;
