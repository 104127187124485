import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import BottomMenuCargoIcon from "../../assets/icons/BottomMenuCargoIcon";
import BottomMenuHomeIcon from "../../assets/icons/BottomMenuHomeIcon";
import BottomMenuListingIcon from "../../assets/icons/BottomMenuListingIcon";
import BottomMenuProposalIcon from "../../assets/icons/BottomMenuProposalIcon";

const StyledNavBar = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  margin: 0px auto;
  max-width: 425px;
  bottom: 0px;
  max-height: 100px;
  padding-bottom: 14px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 0px;

  & .MuiTab-fullWidth {
    max-width: 40px;
    padding-bottom: 14px;
    /* padding: 0  !important; */
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: none !important;
    color: rgba(0, 0, 0, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  & .MuiTabs-indicator {
    background-color: transparent !important;
  }

  & .MuiTabs-root {
    width: 100%;
  }
`;

const StyledTabs = styled(Tabs)`
  display: flex !important;
  flex-direction: row !important;
  & .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-around !important;
  }
`;

const StyledTab = styled(Tab)``;

const getSelectedValue = (selected) => {
  switch (selected) {
    case "Home":
      return 0;
    case "MyOffers":
      return 1;
    case "MyCargos":
      return 2;
    case "MyListings":
      return 3;

    default:
      return false;
  }
};

const BottomMenu = ({ selected }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(getSelectedValue(selected) ?? false);

  const handleOnClick = (url) => {
    navigate(`/${url}`, { replace: true });
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    switch (newValue) {
      case 0:
        handleOnClick("home");
        break;
      case 1:
        handleOnClick("offers");
        break;
      case 2:
        handleOnClick("my-transports");
        break;
      case 3:
        handleOnClick("my-advertisements");
        break;
      default:
        console.log("boş");
        break;
    }
    setValue(newValue ?? 0);
  };

  const calculateColor = (path) => {
    if (
      location?.pathname === path ||
      (path === "/home" && location?.pathname === "/")
    )
      return "#084BBB";
    else return "rgba(0,0,0,0.6)";
  };

  return (
    <>
      <StyledNavBar>
        <StyledTabs
          value={value}
          onChange={(event, newValue) => handleChange(event, newValue)}
          variant="fullWidth"
        >
          <StyledTab
            style={{
              marginRight: "-12px",
            }}
            icon={<BottomMenuHomeIcon htmlColor={calculateColor("/home")} />}
            onClick={() => navigate("/home")}
            label={
              <span
                style={{
                  color: calculateColor("/home"),
                }}
              >
                Anasayfa
              </span>
            }
          />
          <StyledTab
            icon={
              <BottomMenuProposalIcon htmlColor={calculateColor("/offers")} />
            }
            label={
              <span
                style={{
                  color: calculateColor("/offers"),
                }}
              >
                Tekliflerim
              </span>
            }
          />
          <StyledTab
            icon={
              <BottomMenuCargoIcon
                htmlColor={calculateColor("/my-transports")}
              />
            }
            label={
              <span
                style={{
                  color: calculateColor("/my-transports"),
                }}
              >
                Taşımalarım
              </span>
            }
          />
          <StyledTab
            icon={
              <BottomMenuListingIcon
                htmlColor={calculateColor("/my-advertisements")}
              />
            }
            label={
              <span
                style={{
                  color: calculateColor("/my-advertisements"),
                }}
              >
                İlanlarım
              </span>
            }
          />
        </StyledTabs>
      </StyledNavBar>
    </>
  );
};

export default BottomMenu;
