import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import * as validators from "../utils/validators";

import resetPasswordRequest from "../api/resetPasswordRequest";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  background-color: #d4d6dc7d;
  height: 100vh;
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
`;

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [isSubmit, setIsSubmit] = useState(false);
  const [password, setPassword] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});

  useEffect(() => {}, []);

  const savePassword = async () => {
    setIsLoading(true);
    setIsSubmit(true);

    if (!validators.checkIsValidPassword(password)) {
      setIsLoading(false);
      return;
    }

    let request = {
      token: token,
      password: password,
    };

    const result = await resetPasswordRequest(request);

    if (result?.code === 200) {
      setSuccess(
        "Şifre değiştirme işleminiz gerçekleştirildi, yeni şifreniz ile giriş yapabilirsiniz."
      );
      setPasswordChanged(true);
    } else {
      console.log("error:", result);
      setError();
    }
    if (result) setIsLoading(false);
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <StyledWrapper>
      <NavBar header={``} showLogo={true} />
      <ContentWrapper>
        {/* <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} /> */}
        <StyledCardsWrapper>
          {/* {isLoading ? <Loader /> : null} */}
          <Grid
            display="flex"
            padding="0 18px"
            flexDirection="column"
            gap="20px"
          >
            <DefaultInputFeild
              id="password"
              type={"password"}
              label="Yeni Şifrenizi Girin"
              variant="filled"
              backgroundColor="#fff"
              onChange={(e) => {
                setPassword(e?.target?.value);
              }}
              error={isSubmit && !validators.checkIsValidPassword(password)}
            />
            {isSubmit && !validators.checkIsValidPassword(password) ? (
              <AlertBox
                severity="error"
                message="Şifreniz en az 1 harf, 1 rakam ve 1 özel karakter içermeli, en az 8 karakterden oluşmalıdır."
              />
            ) : null}
            {showAlert && (
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            )}
          </Grid>
          <div style={{ padding: "8px 18px" }}>
            <DefaultButton
              size={"small"}
              variant={"contained"}
              buttonColor={"#084BBB"}
              buttonTextColor={"#FFFFFF"}
              buttonTextTransform={"none"}
              buttonMinHeight={48}
              buttonRadius={"10px"}
              fullWidth={true}
              buttonText={
                <StyledButtontext>
                  {passwordChanged ? "Giriş Yap" : "Şifreyi Kaydet"}{" "}
                </StyledButtontext>
              }
              isProgress={isLoading}
              onClick={(e) => {
                e.preventDefault();
                if (passwordChanged) {
                  navigate("/login");
                } else {
                  savePassword();
                }
              }}
            />
          </div>
        </StyledCardsWrapper>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default ResetPassword;
