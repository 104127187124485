import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import * as validators from "../utils/validators";

import resetPasswordMailRequest from "../api/resetPasswordMailRequest";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: -webkit-fill-available;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px;
  padding: 20px;
  width: 100%;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const StyledLoginNowLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;

  color: #3a3a3a;
`;

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([
    {
      key: "email",
      value: null,
      type: "email",
      required: true,
      hasError: false,
    },
  ]);

  const handleRegister = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    console.log(formData);
    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      email: formData.find((q) => q.key === "email").value,
    };

    const result = await resetPasswordMailRequest(request);

    if (result?.ok) {
      setSuccess(
        "Şifreniz değiştirme linki eposta adresinize başarıyla gönderildi. Linke tıklayıp yeni şifre oluşturabilirsiniz."
      );
      setIsLoading(false);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <Wrapper>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="email"
              type={"text"}
              label="E-Posta"
              variant="filled"
              backgroundColor="#E5E5E587"
              startIcon={<MailOutlineOutlinedIcon />}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "email");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidMail(e.target.value);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidMail(
                  formData.find((q) => q.key === "email").value
                )
              }
            />
          </Grid>
          {showAlert ? (
            <Grid item xs={12}>
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
        </StyledGridContainer>

        <div style={{ padding: "8px 18px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Şifre Gönder</StyledButtontext>}
            isProgress={isLoading}
            onClick={() => handleRegister()}
          />
        </div>
        <div style={{ marginTop: "24px" }}>
          <StyledLoginNowLabel>
            Hesabınız var mı?
            <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ color: "rgba(8, 75, 187, 1)", fontWeight: "600" }}
            >
              &nbsp; Giriş Yap
            </span>
          </StyledLoginNowLabel>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ForgetPassword;
