import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Grid, IconButton } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const StyledUploadCard = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

const StyledTitle = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #0a0909;
`;

const UploadCard = ({
  document,
  selectDocumentHandler,
  handleViewDocument,
}) => {
  return (
    <Wrapper>
      <StyledUploadCard container padding="18px">
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          gap="5px"
          padding="6px 16px"
          position="relative"
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledTitle>{document?.value}</StyledTitle>
          <Grid display="flex" flexDirection="row" alignItems="center">
            {document?.statusText}
            {document?.url?.length > 0 ? (
              <IconButton
                onClick={() => {
                  handleViewDocument(document?.documentId);
                }}
                // onClick={() => {
                //   window.open(document.url);
                // }}
              >
                <FolderOpenIcon htmlColor="#084bbb" />
              </IconButton>
            ) : (
              <IconButton onClick={() => selectDocumentHandler(document)}>
                {document?.icon}
              </IconButton>
            )}
          </Grid>
        </Grid>
      </StyledUploadCard>
    </Wrapper>
  );
};

export default UploadCard;
