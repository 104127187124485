import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import styled from "styled-components";

const StyledPhoneInput = styled(PhoneInput)`
  > input {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  > input:focus {
    background-color: white !important;
    border-color: transparent !important;
  }
`;

const DefaultPhoneField = ({
  id,
  label,
  backgroundColor,
  placeholderText,
  value,
  onChange,
  error,
}) => {
  return (
    <StyledPhoneInput
      id={id}
      specialLabel={label}
      country={"tr"}
      // preferredCountries={["tr"]}
      onlyCountries={["tr"]}
      masks={{ tr: "(...) ... .. .." }}
      placeholder={placeholderText}
      inputStyle={{
        backgroundColor: value?.length > 0 ? "white" : backgroundColor,
        width: "100%",
        borderRadius: "8px",
        borderColor: error
          ? "red"
          : value?.length > 0
          ? "rgba(8, 75, 187, 1)"
          : "",
      }}
      containerStyle={{
        color: error && "red",
      }}
      value={value}
      onChange={(e) => onChange(e)}
      disableDropdown={true}
    />
  );
};

export default DefaultPhoneField;
