import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import OfferSuccessForkliftIcon from "../assets/icons/OfferSuccessForkliftIcon";
import OffcerSuccessVehicleIcon from "../assets/icons/OffcerSuccessVehicleIcon";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";
import OfferFailVehicleIcon from "../assets/icons/OfferFailVehicleIcon";
import * as utils from "../utils/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: #191d31;
`;

const Description = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  color: #a7a9b7;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const SuccessMessage = () => {
  const navigate = useNavigate();
  // * id: vehicle and forklift changes icon
  let { id, title, message, button, url, urlParams } = useParams();

  return (
    <Wrapper>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {id === "forklift" ? (
                <OfferSuccessForkliftIcon />
              ) : id === "vehicle" ? (
                <OffcerSuccessVehicleIcon />
              ) : id === "vehicle-error" ? (
                <OfferFailVehicleIcon />
              ) : null}
              <Title>{title}</Title>
              <Description style={{ paddingTop: "10px" }}>
                {message}
              </Description>
            </div>
          </Grid>
        </StyledGridContainer>

        <div style={{ display: "flex", padding: "8px 18px", gap: "10px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Ana Sayfa</StyledButtontext>}
            onClick={() => {
              navigate("/");
            }}
          />
          {button !== "undefined" && button?.length > 0 ? (
            <DefaultButton
              size={"small"}
              variant={"contained"}
              buttonColor={"#084BBB"}
              buttonTextColor={"#FFFFFF"}
              buttonTextTransform={"none"}
              buttonMinHeight={48}
              buttonRadius={"10px"}
              fullWidth={true}
              buttonText={<StyledButtontext>{button}</StyledButtontext>}
              onClick={() => {
                if (url && url?.length > 0)
                  navigate(
                    `/${url}${
                      urlParams && urlParams?.length > 0
                        ? `/${utils.decodeJson(urlParams)}`
                        : ""
                    }`
                  );
                else navigate("/");
              }}
            />
          ) : null}
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SuccessMessage;
