import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProfileAvatarIcon from "../../assets/images/ProfileAvatarIcon";
import * as utils from "../../utils/utils";

const StyledNavBar = styled.header`
  max-width: 425px;
  position: fixed;
  width: 100%;
  height: 242px;
  top: 0px;
  background: #084bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
`;

const StyledNavBarHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledNavBarText = styled.div`
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23.46px !important;
  padding-left: 8px;
  color: #ffffff;
`;

const StyledNavBarLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledNavBarAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledNavBarAvatarUsername = styled.div`
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 23px !important;

  color: #ffffff;
  padding-top: 10px;
`;

const StyledNavBarAvatarPhone = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  color: rgba(229, 229, 229, 0.76);
  padding-top: 10px;
`;

const StyledButton = styled(Button)`
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21px !important;

  color: #ffffff !important;
  text-transform: none !important;
`;

const ProfileNavBar = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  return (
    <>
      <StyledNavBar>
        <StyledNavBarHeader>
          <div style={{ paddingLeft: "10px" }}>
            <StyledButton></StyledButton>
          </div>
          <StyledNavBarLogoWrapper>
            {/* <LogoNavBarIcon /> */}
            <img
              src={"/images/logo_white.png"}
              alt="logo"
              width={"200"}
              height={"65"}
            />
            <StyledNavBarText
              onClick={() => {
                navigate(`/home`, { replace: true });
              }}
            ></StyledNavBarText>
          </StyledNavBarLogoWrapper>
          <div style={{ paddingRight: "10px" }}>
            <StyledButton
              onClick={() => {
                navigate(`/home`, { replace: true });
              }}
            >
              Kapat
            </StyledButton>
          </div>
        </StyledNavBarHeader>
        <StyledNavBarAvatar>
          <ProfileAvatarIcon />
          <StyledNavBarAvatarUsername>
            {user?.user?.name} {user?.user?.surname}
          </StyledNavBarAvatarUsername>
          <StyledNavBarAvatarPhone>
            {utils.formatPhoneNumber(user?.user?.phone ?? "")}
          </StyledNavBarAvatarPhone>
        </StyledNavBarAvatar>
      </StyledNavBar>
    </>
  );
};

export default ProfileNavBar;
