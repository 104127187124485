import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CargoCard from "../components/CargoCard";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import VehicleCard from "../components/VehicleCard";

import getUserAdvertisements from "../api/getUserAdvertisements";
import EmptyState from "../components/EmptyState";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #084bbb;
  border: 1px solid #084bbb;
  border-radius: 10px;
  width: 100%;
  height: 44px;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
`;

const MyTransports = () => {
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedtab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadLoadAdvertisements();
  }, [selectedTab]);

  const loadLoadAdvertisements = async () => {
    let filter = {
      advertisementStatus: 4,
    };

    const result = await getUserAdvertisements(filter);

    if (result?.code === 200) {
      setAdvertisements(result.data?.items ?? []);
    } else {
      console.log("error:", result);
    }

    setIsLoading(false);
  };

  const generateAdvertisement = (advertisement) => {
    if (advertisement?.advertisementTypeId === 1) {
      return (
        <CargoCard
          load={advertisement}
          preventLink={true}
          hideFavorite={true}
        />
      );
    } else {
      return (
        <VehicleCard
          vehicle={advertisement}
          preventLink={true}
          hideFavorite={true}
        />
      );
    }
  };

  return (
    <StyledWrapper>
      <NavBar />
      <StyledContainer>
        <div style={{ padding: "0 16px", width: "100%" }}>
          <Header>TAŞIMALARIM</Header>
        </div>
        {isLoading ? <Loader /> : null}
        {selectedTab === 0 &&
          (advertisements && advertisements?.length > 0 ? (
            advertisements?.map((advertisement, index) => (
              <React.Fragment key={index}>
                {generateAdvertisement(advertisement)}
              </React.Fragment>
            ))
          ) : (
            <EmptyState />
          ))}
      </StyledContainer>
      <BottomMenu selected={"Home"} />
    </StyledWrapper>
  );
};

export default MyTransports;
