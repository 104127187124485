import { Grid, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as adStatus from "../AdStatus";
import FavoriteCirceIcon from "../../assets/icons/FavoriteCirceIcon";
import VerticalRoadIcon from "../../assets/icons/VerticalRoadIcon";

const TabsWrapper = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

const StyledCard = styled.div`
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  min-height: 136px;
  cursor: pointer;
`;

const StyledCardContainer = styled.div``;

const StyledCardDetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledCardHeader = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #000000 !important;
  margin: 8px 8px 8px 0px !important;
  min-height: 32px !important;
  /* width: -webkit-fill-available; */

  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
`;

const StyledCardDetail = styled.div`
  display: flex;
  justify-content: start;
  gap: 4px;
  padding-top: 4px;
`;

const StyledCardDetailPrimary = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: rgba(0, 0, 0, 0.58);
`;

const StyledCardDetailSecondary = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #0a0909;
`;

const StyledSourceDestinationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSourcePrimary = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #c4c4c4;
`;

const StyledSourceSecondary = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: rgba(0, 0, 0, 0.77);
  word-break: break-all;
`;

const StyledRoadWrapper = styled.div`
  padding: 0 4px;
  max-width: -webkit-fill-available !important;
`;

const CargoCard = ({
  load,
  preventLink,
  hideFavorite,
  clicked,
  backUrl = "",
  showStatus = true,
}) => {
  const navigate = useNavigate();

  const handleClickEvent = () => {
    if (clicked) clicked(load);
    if (preventLink) return;
    navigate(`/offer-load/${load?.id}${backUrl?.length > 0 ? backUrl : ""}`);
  };

  return (
    <TabsWrapper>
      <StyledCard onClick={() => handleClickEvent()}>
        <StyledCardContainer>
          <Grid container>
            <Grid item xs={4}>
              {/* <HomeCargoImage /> */}
              <img
                src="/images/cargo.svg"
                style={{
                  height: "100%",
                  minHeight: "150px",
                  minWidth: "106px",
                }}
                alt="cargo"
              />
            </Grid>
            <Grid item xs={8}>
              <StyledCardDetailHeader>
                <StyledCardHeader>{load?.header}</StyledCardHeader>
                {hideFavorite ? null : (
                  <IconButton>
                    <FavoriteCirceIcon />
                  </IconButton>
                )}
              </StyledCardDetailHeader>
              <Grid container>
                <Grid item xs={7}>
                  {}
                  {showStatus ? (
                    <StyledCardDetail>
                      <StyledCardDetailPrimary>Durum:</StyledCardDetailPrimary>
                      <StyledCardDetailSecondary>
                        {adStatus.convertStatusWithId(load?.advertisementStatusId)}
                      </StyledCardDetailSecondary>
                    </StyledCardDetail>
                  ) : null}
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Tarih:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {load?.closeDate}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Parsiyel:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {load?.isPartialLoad ? "Evet" : "Hayır"}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                  {/* <StyledCardDetail>
                    <StyledCardDetailPrimary>Adet:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {load?.partialLoadNumber}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail> */}
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Miktar:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {`${Math.trunc(load?.loadAmount)} Ton`}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                  {load?.isPartialLoad ? (
                    <StyledCardDetail>
                      <StyledCardDetailPrimary>Boyut:</StyledCardDetailPrimary>
                      <StyledCardDetailSecondary>
                        {`G:${Math.trunc(load?.partialLoadWidth)}cm 
                      U:${Math.trunc(load?.partialLoadLength)}cm 
                      Y:${Math.trunc(load?.partialLoadHeight)}cm`}
                      </StyledCardDetailSecondary>
                    </StyledCardDetail>
                  ) : null}
                </Grid>
                <Grid item xs={1}>
                  <VerticalRoadIcon />
                </Grid>
                <Grid item xs={4}>
                  <StyledSourceDestinationWrapper>
                    <StyledRoadWrapper>
                      <StyledSourcePrimary>Nereden:</StyledSourcePrimary>
                      <StyledSourceSecondary>
                        {load?.originCity}
                      </StyledSourceSecondary>
                      <StyledSourcePrimary>&nbsp;</StyledSourcePrimary>
                      <StyledSourcePrimary>Nereye:</StyledSourcePrimary>
                      <StyledSourceSecondary>
                        {load?.destinationCity}
                      </StyledSourceSecondary>
                    </StyledRoadWrapper>
                  </StyledSourceDestinationWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledCardContainer>
      </StyledCard>
    </TabsWrapper>
  );
};

export default CargoCard;
