/* Validators */
export const checkIsValidTextField = (data) => {
  if (data && data?.trim()?.length > 0) {
    return true;
  }
  return false;
};

export const checkIsValidSelect = (data) => {
  if (data) {
    return true;
  }
  return false;
};

export const checkIsValidDateTimePicker = (data) => {
  if (data) {
    return true;
  }
  return false;
};

export const checkIsValidDatePicker = (data) => {
  if (data) {
    return true;
  }
  return false;
};

export const checkIsValidNumber = (data) => {
  if (/^[+-]?\d+(,\d+)?(\.\d+)?$/g.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidPositiveNumber = (data) => {
  if (/^[+]?\d+(,\d+)?(\.\d+)?$/g.test(data)) {
    return true;
  }
  return false;
};

export const checkIsValidNegativeNumber = (data) => {
  if (/^[-]?\d+(,\d+)?(\.\d+)?$/g.test(data)) {
    return true;
  }
  return false;
};

export const checkIsValidBoolean = (data) => {
  if (data === true || data === false || data === "true" || data === "false") {
    return true;
  }
  return false;
};

export const checkIsAllValidChars = (data) => {
  if (/^[a-zA-ZğüşöçİĞÜŞÖÇı 0123456789]+$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidUrl = (data) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(data);
};

export const removeLtGtSymbols = (data) => {
  data = data.replace(/</g, "").replace(/>/g, "");
  return data;
};

export const checkIsValidMail = (data) => {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidPassword = (data) => {
  if (
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/.test(
      data
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidSimplePassword = (data) => {
  if (/(?=.*[a-zA-Z])(?=.*\d)(?=.*[0-9]).{6,}$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkMinLength = (data, length) => {
  if (data && data?.length >= length) {
    return true;
  } else {
    return false;
  }
};

export const checkMaxLength = (data, length) => {
  if (data && data?.length <= length) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidPhone = (data) => {
  //! Valid for turkey
  // check if data is starts with 905
  if (data && data?.startsWith("905") && data?.length === 12) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidTurkishIdentity = (data) => {
  if (/^[1-9]{1}[0-9]{9}[02468]{1}$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidTurkishCompanyIdentity = (data) => {
  if (/^[0-9]{10}$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidTurkishMobilePhone = (data) => {
  if (/^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidTurkishPlateNo = (data) => {
  if (/^[0-9]{2}[A-Z]{1,3}[0-9]{1,5}$/.test(data)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidText = (data) => {
  if (
    checkIsValidTextField(data) &&
    checkMinLength(data, 3) &&
    checkMaxLength(data, 50)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValidTextLength = (data, min = 3, max = 100) => {
  if (
    checkIsValidTextField(data) &&
    checkMinLength(data, min) &&
    checkMaxLength(data, max)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isFormValid = (form) => {
  let isValid = true;
  form.forEach(function (element, index) {
    if (element.required && isValid) {
      switch (element.type) {
        case "text":
          isValid = checkIsValidTextField(element.value);
          break;

        case "textLength":
          isValid = checkIsValidTextLength(
            element.value,
            element.min,
            element.max
          );
          break;

        case "select":
          isValid = checkIsValidSelect(element.value);
          break;

        case "datetime":
          isValid = checkIsValidDateTimePicker(element.value);
          break;

        case "date":
          isValid = checkIsValidDatePicker(element.value);
          break;

        case "img":
          isValid = checkIsValidTextField(element.value);
          break;

        case "number":
          isValid = checkIsValidNumber(element.value);
          break;

        case "boolean":
          isValid = checkIsValidBoolean(element.value);
          break;

        case "url":
          isValid = checkIsValidUrl(element.value);
          break;

        case "email":
          isValid = checkIsValidMail(element.value);
          break;

        case "phone":
          isValid = checkIsValidPhone(element.value);
          break;

        case "confirm":
          isValid = element.value;
          break;

        case "password":
          isValid = checkIsValidPassword(element.value);
          break;

        // * Custom Validators
        // * This validators depends on the project
        case "tcknvkn":
          isValid =
            checkIsValidTurkishIdentity(element.value) ||
            checkIsValidTurkishCompanyIdentity(element.value);
          break;

        case "plate":
          isValid = checkIsValidTurkishPlateNo(element.value);
          break;

        case "simplePassword":
          isValid = checkMinLength(element.value, 6);
          break;

        case "textArea":
          isValid = checkIsValidText(element.value);
          break;

        case "iban":
          isValid = checkIsValidIban(element.value);
          break;

        case "tckn":
          isValid = checkIsValidTurkishIdentity(element.value);
          break;

        case "vkn":
          isValid = checkIsValidTurkishCompanyIdentity(element.value);
          break;

        default:
          isValid = checkIsValidTextField(element.value);
          break;
      }
      if (!isValid) {
        console.log(element);
      }
      element.hasError = !isValid;
    }
  });
  return isValid;
};

export const checkIsValidIban = (iban) => {
  //assume it already starts with TR
  const ibanRegex = /^[0-9A-Z]{24}$/;
  if (!ibanRegex.test(iban)) {
    return false;
  }
  return true;
};
/* End of Validators */
