import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import OfferSuccessForkliftIcon from "../assets/icons/OfferSuccessForkliftIcon";
import OffcerSuccessVehicleIcon from "../assets/icons/OffcerSuccessVehicleIcon";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 130% !important;
  text-align: center !important;
  color: #191d31;
`;

const Description = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  color: #a7a9b7;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const OfferSuccessMessage = () => {
  const navigate = useNavigate();
  // * id: vehicle and forklift changes icon
  let { id } = useParams();

  return (
    <Wrapper>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {id === "forklift" ? (
                <OfferSuccessForkliftIcon />
              ) : (
                <OffcerSuccessVehicleIcon />
              )}
              <Title>İlanınız için verilen teklifi kabul ettiniz</Title>
              <Description style={{ paddingTop: "10px" }}>
                Taşımanın başlayabilmesi için yükline ekibi en kısa sürede
                sizlerle iletişime geçeceklerdir.
              </Description>
            </div>
          </Grid>
        </StyledGridContainer>

        <div style={{ padding: "8px 18px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Tamam</StyledButtontext>}
            onClick={() => navigate("/")}
          />
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default OfferSuccessMessage;
