import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";

import activateUserEmailRequest from "../api/activateUserEmailRequest";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  background-color: #d4d6dc7d;
  height: 100vh;
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
`;

const StyledLoginNowLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;

  color: #3a3a3a;
`;

const ConfirmUser = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});

  useEffect(() => {
    activate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activate = async () => {
    setIsLoading(true);
    const result = await activateUserEmailRequest(token);

    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");
      setTimeout(() => {
        navigate("/upload-file-message");
        setIsLoading(false);
      }, 2000);
    } else {
      console.log("error:", result);
      setError("Onay linki geçerliliğini yitirdi veya hatalı.");
    }
    if (result) setIsLoading(false);
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <StyledWrapper>
      <NavBar header={``} showLogo={true} />
      <ContentWrapper>
        {/* <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} /> */}
        <StyledCardsWrapper>
          {showAlert && (
            <AlertBox
              severity={showAlertDetail?.severity}
              title={showAlertDetail?.title}
              message={showAlertDetail?.message}
            />
          )}
          {isLoading ? <Loader /> : null}
          <div style={{ marginTop: "24px" }}>
            <StyledLoginNowLabel>
              Hesabınız var mı?
              <span
                onClick={() => {
                  navigate("/login");
                }}
                style={{ color: "rgba(8, 75, 187, 1)", fontWeight: "600" }}
              >
                &nbsp; Giriş Yap
              </span>
            </StyledLoginNowLabel>
          </div>
        </StyledCardsWrapper>
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default ConfirmUser;
