import React from "react";

function UploadFileMessageIcon() {
  return (
    <svg
      width="371"
      height="424"
      viewBox="0 0 371 424"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="66"
        y="86"
        width="235"
        height="311"
        rx="25"
        fill="white"
        stroke="#191D31"
        strokeWidth="6"
      />
      <g filter="url(#filter0_d_225_1913)">
        <rect x="80" y="296" width="207" height="44" rx="12" fill="white" />
      </g>
      <rect x="86" y="302" width="32" height="32" rx="10" fill="#EEF1F8" />
      <rect x="128" y="313" width="112" height="10" rx="5" fill="#E7EAF1" />
      <g filter="url(#filter1_d_225_1913)">
        <rect x="80" y="242" width="207" height="44" rx="12" fill="white" />
      </g>
      <rect x="86" y="248" width="32" height="32" rx="10" fill="#EEF1F8" />
      <rect
        opacity="0.7"
        x="128"
        y="206"
        width="112"
        height="16"
        rx="8"
        fill="#B1B8C8"
      />
      <rect x="128" y="259" width="112" height="10" rx="5" fill="#E7EAF1" />
      <circle cx="184" cy="156" r="35" fill="#EEF1F8" />
      <path
        d="M184 150.8C181.182 150.8 178.8 153.182 178.8 156C178.8 158.818 181.182 161.2 184 161.2C186.818 161.2 189.2 158.818 189.2 156C189.2 153.182 186.818 150.8 184 150.8ZM184 158.6C182.615 158.6 181.4 157.384 181.4 156C181.4 154.615 182.615 153.4 184 153.4C185.384 153.4 186.6 154.615 186.6 156C186.6 157.384 185.384 158.6 184 158.6Z"
        fill="#B7BECD"
      />
      <path
        d="M194.4 146.9H191.038L187.519 143.381C187.399 143.26 187.255 143.164 187.098 143.099C186.94 143.033 186.771 143 186.6 143H181.4C181.229 143 181.06 143.033 180.902 143.099C180.745 143.164 180.601 143.26 180.481 143.381L176.962 146.9H173.6C172.166 146.9 171 148.066 171 149.5V163.8C171 165.234 172.166 166.4 173.6 166.4H194.4C195.834 166.4 197 165.234 197 163.8V149.5C197 148.066 195.834 146.9 194.4 146.9ZM173.6 163.8V149.5H177.5C177.846 149.5 178.176 149.364 178.419 149.119L181.938 145.6H186.062L189.581 149.119C189.701 149.24 189.845 149.336 190.002 149.401C190.16 149.467 190.329 149.5 190.5 149.5H194.4L194.403 163.8H173.6Z"
        fill="#B7BECD"
      />
      <g filter="url(#filter2_d_225_1913)">
        <circle cx="301" cy="86" r="30" fill="white" />
      </g>
      <g clipPath="url(#clip0_225_1913)">
        <path
          d="M301 72.6667C293.653 72.6667 287.667 78.6533 287.667 86C287.667 93.3467 293.653 99.3333 301 99.3333C308.347 99.3333 314.333 93.3467 314.333 86C314.333 78.6533 308.347 72.6667 301 72.6667ZM307.373 82.9333L299.813 90.4933C299.627 90.68 299.373 90.7867 299.107 90.7867C298.84 90.7867 298.587 90.68 298.4 90.4933L294.627 86.72C294.24 86.3333 294.24 85.6933 294.627 85.3067C295.013 84.92 295.653 84.92 296.04 85.3067L299.107 88.3733L305.96 81.52C306.347 81.1333 306.987 81.1333 307.373 81.52C307.76 81.9067 307.76 82.5333 307.373 82.9333Z"
          fill="#084BBB"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_225_1913"
          x="0"
          y="220"
          width="367"
          height="204"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="40" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.654902 0 0 0 0 0.662745 0 0 0 0 0.717647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_225_1913"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_225_1913"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_225_1913"
          x="0"
          y="166"
          width="367"
          height="204"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="40" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.654902 0 0 0 0 0.662745 0 0 0 0 0.717647 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_225_1913"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_225_1913"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_225_1913"
          x="211"
          y="0"
          width="180"
          height="180"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.694118 0 0 0 0 0.721569 0 0 0 0 0.784314 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_225_1913"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_225_1913"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_225_1913">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(285 70)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadFileMessageIcon;
