import { Grid } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import UploadFileMessageIcon from "../assets/icons/UploadFileMessageIcon";
import NavBar from "../components/Layout/NavBar";
import DefaultButton from "../components/UI/DefaultButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #191d31;
`;

const HalfColor = styled.div`
  background: rgb(245, 248, 252);
  background: linear-gradient(
    0deg,
    rgba(245, 248, 252, 1) 0%,
    rgba(245, 248, 252, 1) 28%,
    rgba(245, 248, 252, 0.8534007352941176) 46%,
    rgba(245, 248, 252, 0.6097032563025211) 65%,
    rgba(245, 248, 252, 0.28757440476190477) 83%,
    rgba(245, 248, 252, 0) 100%
  );
  position: absolute;
  margin-top: -316px;
  width: 100%;
  height: 290px;
`;

const Description = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  text-align: center;
  color: #a7a9b7;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const UploadFileMessage = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12} paddingTop="24px">
            <Title>Kayıt İşlemini Başarıyla Tamamladın</Title>
          </Grid>
          <Grid item xs={12} style={{ position: "relative" }}>
            <UploadFileMessageIcon />
            <HalfColor />
            <Title style={{ position: "relative", marginTop: "-80px" }}>
              {/* Belgelerini Yükle */}
            </Title>
            <Description
              style={{
                position: "relative",
                marginInline: "20px",
                marginBlock: "20px",
              }}
            >
              {/* İlanlara teklif verebilmek için ve platformun güvenilirliği için
              sürücü belgeni, vergi levhanı ve diğer gerekli olan belgeleri
              yükle.  */}
              Yük ve Araç ilanlarını görmek, ilan ve teklif vermek için platformu kullanmaya başlayabilirsin.
            </Description>
          </Grid>
        </StyledGridContainer>

        <div style={{ marginInline: "48px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Giriş Yap</StyledButtontext>}
            onClick={() => navigate("/login")}
          />
        </div>
        {/* <div style={{ marginBlock: "24px" }}>
          <StyledLoginNowLabel
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Daha sonra yükle
          </StyledLoginNowLabel>
        </div> */}
      </ContentWrapper>
    </Wrapper>
  );
};

export default UploadFileMessage;
