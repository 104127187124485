import React from "react";

function LogoSplashIcon() {
  return (
    <svg
      width="52"
      height="73"
      viewBox="0 0 52 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_26_1065)">
        <path
          d="M0.09021 15.0538V21.5625L10.2101 27.3926C10.2101 25.6159 10.2101 23.843 10.2043 22.0663C10.2043 21.4279 10.4096 21.0626 10.9718 20.7415C15.8264 17.9861 20.658 15.1865 25.5146 12.4368C25.789 12.2811 26.307 12.308 26.591 12.4676C28.8744 13.7597 31.367 15.2576 33.6369 16.5767L44.0235 10.2852C38.2267 6.91448 32.4166 3.56492 26.6217 0.188438C26.1593 -0.0807572 25.8388 -0.0461464 25.4225 0.198052C24.0812 0.984487 22.7208 1.74208 21.3757 2.52467C16.191 5.53197 11.0006 8.52773 5.84091 11.5754"
          fill="white"
        />
        <path
          d="M26.6275 23.9564C26.1266 24.2545 25.7467 24.2679 25.2555 23.968C22.9491 22.5605 19.6487 20.655 17.3385 19.2513L16.7954 19.5666L11.7624 22.4066V28.1847C13.8596 29.4326 16.4942 30.9536 18.5914 32.1938C19.0462 32.4611 19.501 32.7322 19.9519 33.0033C20.2742 33.1975 20.6465 33.7378 20.6484 34.1166C20.6887 41.633 20.6657 49.1435 20.6657 56.6579V57.7097C18.9215 56.706 17.1044 55.708 15.4849 54.7716C13.9594 53.8871 12.4531 52.9738 10.9104 52.122C10.3578 51.8162 10.1083 51.472 10.1083 50.8125C10.1218 44.0999 10.1256 37.3854 10.1218 30.6729L0 24.8467V58.0135C0.095941 58.1019 0.21107 58.1865 0.349225 58.2654C6.86745 62.0168 13.3665 65.8028 19.8828 69.562C20.0114 69.637 25.8542 72.9173 25.9482 73L31.2345 69.7966C31.2345 68.0276 31.2384 66.2586 31.2403 64.4896C31.2461 54.4063 31.2518 44.3249 31.248 34.2435C31.248 33.7071 31.3401 33.334 31.8505 32.9956C33.1725 32.1169 34.1243 31.5785 34.1243 31.5785C34.1243 31.5785 41.8379 27.1099 46.8998 24.191V11.8657"
          fill="white"
        />
        <path
          d="M52 58.0001V33.6514C51.7109 33.8052 51.5114 33.9142 51.4014 33.9782C48.4541 35.6876 48.3658 35.7453 42.5575 39.0968C41.9857 39.4275 41.8092 39.7967 41.813 40.4274C41.8361 43.9058 41.8034 47.3842 41.8341 50.8626C41.8399 51.5548 41.5809 51.924 41.0014 52.2489C38.5434 53.6295 36.1141 55.0639 33.66 56.4522C33.0575 56.7926 32.8234 57.2079 32.8234 57.9309C32.8253 61.2612 32.8253 64.5934 32.8253 67.9238V69.0486"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_26_1065">
          <rect width="52" height="73" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoSplashIcon;
