import React from "react";

function FavoriteCirceIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#E5E5E5" fillOpacity="0.53" />
      <path
        d="M15.4736 8.8421H9.57891C8.76617 8.8421 8.10522 9.50305 8.10522 10.3158V22.1053L12.5263 19.4519L16.9473 22.1053V10.3158C16.9473 9.50305 16.2864 8.8421 15.4736 8.8421ZM15.4736 19.502L12.5263 17.7343L9.57891 19.502V10.3158H15.4736V19.502Z"
        fill="white"
      />
      <path
        d="M18.421 5.89471H12.5263C11.7136 5.89471 11.0526 6.55566 11.0526 7.3684H16.9473C17.7601 7.3684 18.421 8.02935 18.421 8.84208V16.5369L19.8947 18.3709V7.3684C19.8947 6.55566 19.2338 5.89471 18.421 5.89471Z"
        fill="white"
      />
    </svg>
  );
}

export default FavoriteCirceIcon;
