import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import ProfileListMenu from "../components/ProfileListMenu";
import DefaultBackButton from "../components/UI/DefaultBackButton";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ReceiptIcon from "@mui/icons-material/Receipt";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled(Grid)`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const MenuOptions = [
  {
    label: "Bildirim",
    id: 1,
    icon: <NotificationsIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
  {
    label: "İzin",
    id: 2,
    icon: <LockIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
  {
    label: "Sözleşme",
    id: 3,
    icon: <ReceiptIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
  {
    label: "E-Posta Değişikliği",
    id: 4,
    icon: <EmailIcon htmlColor="#084BBB" sx={{ fontSize: 24 }} />,
  },
];

const Settings = () => {
  const navigate = useNavigate();

  const handleListClick = (selected) => {
    if (selected?.url && selected?.url?.length > 0) {
      navigate(selected.url);
    }
  };

  return (
    <Wrapper>
      <NavBar />
      <StyledContainer container>
        <Grid
          item
          xs={12}
          style={{
            padding: "16px 0px 0px 16px",
          }}
        >
          <DefaultBackButton
            label={"Profilim"}
            icon={<ArrowBackIosIcon />}
            onClick={() => {
              navigate("/profile");
            }}
          />
          {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
        </Grid>
        <ProfileListMenu
          options={MenuOptions}
          handleListClick={handleListClick}
        />
      </StyledContainer>
      <BottomMenu />
    </Wrapper>
  );
};

export default Settings;
