import styled from "styled-components";

import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import * as React from "react";

import BlueLockIcon from "../assets/icons/BlueLockIcon";
import OnBoardingImageFirst from "../assets/images/OnBoardingImageFirst";
import OnBoardingImageSecond from "../assets/images/OnBoardingImageSecond";
import DefaultButton from "../components/UI/DefaultButton";

const StyledBox = styled(Box)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const StyledLockChip = styled.div`
  background: rgba(8, 75, 187, 0.09);
  border-radius: 76px;
  max-width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 10px;
  margin-top: 68px;
`;

const StyledBoxContent = styled(Box)`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

const StyledMessageHeader = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;

  color: rgba(0, 0, 0, 0.83);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledMessageDescription = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: rgba(58, 58, 58, 0.73);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const steps = [
  {
    label: "label1",
    description: `description1`,
  },
  {
    label: "label2",
    description: `description2`,
  },
];

const OnboardingScreen = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <StyledBox>
      {/* <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{steps[activeStep].label}</Typography>
      </Paper> */}
      <StyledBoxContent>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <StyledLockChip>
            <BlueLockIcon />
            Lorem ipsum dolor sit.
          </StyledLockChip>
        </div>
        <div style={{ padding: "5px 24px" }}>
          <OnBoardingImageFirst />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "2px 40px",
          }}
        >
          <OnBoardingImageSecond />
        </div>
        <div style={{ marginTop: "54px" }}>
          <StyledMessageHeader>Lorem ipsum dolor sit</StyledMessageHeader>
          <StyledMessageDescription>
            Lorem ipsum dolor sit amet contectur.
          </StyledMessageDescription>
        </div>
        {/* {steps[activeStep].description} */}
      </StyledBoxContent>
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinWidth={121}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            buttonText={<StyledButtontext>İleri</StyledButtontext>}
          />
        }
        backButton={
          <DefaultButton
            size={"small"}
            variant={"text"}
            // onClick={handleBack}
            disabled={activeStep === 0}
            buttonText={"Geç"}
            buttonTextTransform={"none"}
            buttonTextSize={"18px"}
            buttonTextColor={"#084bbb"}
          />
        }
      />
    </StyledBox>
  );
};

export default OnboardingScreen;
