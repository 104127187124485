import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import styled from "styled-components";

const StyledList = styled(List)`
  width: 100%;

  &.MuiList-root li:last-child {
    > div {
      border-bottom: 1px solid transparent !important;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  padding: 0 20px !important;
`;

const StyledListItemButton = styled(ListItemButton)`
  &.MuiListItemButton-gutters {
    border-bottom: 1px solid #c4c4c4;
  }
  & .MuiListItemText-primary {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    /* identical to box height */

    color: #3a3a3a !important;
  }
`;

const StyledListItemText = styled(ListItemText)``;

const ProfileListMenu = ({ options, handleListClick }) => {
  return (
    <StyledList>
      {options &&
        options?.length > 0 &&
        options.map((option, index) => (
          <StyledListItem key={index}>
            <StyledListItemButton
              onClick={(e) => {
                handleListClick(option);
              }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <StyledListItemText primary={option.label} />
            </StyledListItemButton>
          </StyledListItem>
        ))}
    </StyledList>
  );
};

export default ProfileListMenu;
