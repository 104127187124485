import { IconButton } from "@mui/material";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import styled from "styled-components";

const StyledDefaultCurrencyInput = styled(CurrencyTextField)`
  & .MuiInputBase-root {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 16px !important;
    border-radius: 8px;
    background-color: #ffffff;
    color: #000 !important;
  }

  & .MuiIconButton-root {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 16px !important;

    color: #000;
  }
`;

const DefaultCurrencyField = ({
  value,
  onChange,
  disabled,
  maximumValue,
  error,
}) => {
  return (
    <StyledDefaultCurrencyInput
      // label="Amount"
      variant="outlined"
      value={value}
      currencySymbol=""
      backgroundColor="#FFFFFF"
      //minimumValue="0"
      maximumValue={maximumValue}
      outputFormat="number"
      decimalCharacter=","
      digitGroupSeparator="."
      //autoFocus
      //className=classes.textField
      //readonly
      //disabled
      //placeholder="Currency"
      //preDefined={predefinedOptions.percentageEU2dec}
      onChange={(event, value) => onChange(event, value)}
      disabled={disabled}
      InputProps={{
        endAdornment: <IconButton>TL</IconButton>,
      }}
      error={error}
    />
  );
};

export default DefaultCurrencyField;
