import api from "../config/api";

const getUserAdvertisements = async (data) => {
  const res = await api.post(
    `advertisement/user/advertisements?page=${1}&limit=${500}`,
    data
  );
  return res?.data;
};

export default getUserAdvertisements;
