import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import MyVehicleListCard from "../components/MyVehicleListCard";
import DefaultButton from "../components/UI/DefaultButton";
import Loader from "../components/Loader";

import getUserVehicles from "../api/getUserVehicles";
import EmptyState from "../components/EmptyState";
import { ArrowBackIos } from "@mui/icons-material";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import * as utils from "../utils/utils";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #d4d6dc7d;
`;

const ContentWrapper = styled.div`
  padding: 80px 0 80px 0;
  height: 100%;
  min-height: 100vh;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  color: #ffffff;
`;

const MyVehicles = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVehicles = async () => {
    const result = await getUserVehicles();

    if (result?.code === 200) {
      if (id && id > 0) {
        const vehicle = result?.data?.find((item) => item.id === +id);
        navigate(`/vehicle-documents/${utils.encodeJson(vehicle)}`);
      } else {
        setVehicles(result?.data ?? []);
      }
    } else {
      console.log("error:", result);
    }

    setIsLoading(false);
  };

  return (
    <StyledWrapper>
      <NavBar header={"Araçlarım"} showLogo={false} />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Profilim"}
              icon={<ArrowBackIos />}
              onClick={() => {
                navigate("/profile");
              }}
            />
            {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
          </Grid>
          <Grid item xs={12}>
            <DefaultButton
              size={"small"}
              variant={"contained"}
              buttonColor={"#084BBB"}
              buttonTextColor={"#FFFFFF"}
              buttonTextTransform={"none"}
              buttonMinHeight={48}
              buttonRadius={"10px"}
              fullWidth={true}
              buttonText={<StyledButtontext>YENİ ARAÇ EKLE +</StyledButtontext>}
              onClick={() => navigate("/add-vehicle")}
            />
          </Grid>
          {isLoading ? <Loader /> : null}
          <Grid item xs={12} display="flex" flexDirection="column" gap="20px">
            {vehicles?.length > 0 ? (
              vehicles.map((vehicle, index) => (
                <MyVehicleListCard key={index} vehicle={vehicle} />
              ))
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <EmptyState />
              </div>
            )}
          </Grid>
        </StyledGridContainer>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default MyVehicles;
