import React from "react";

function NavbarHamburgerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.25C2.58579 6.25 2.25 6.58579 2.25 7C2.25 7.41421 2.58579 7.75 3 7.75V6.25ZM21 7.75C21.4142 7.75 21.75 7.41421 21.75 7C21.75 6.58579 21.4142 6.25 21 6.25V7.75ZM3 11.25C2.58579 11.25 2.25 11.5858 2.25 12C2.25 12.4142 2.58579 12.75 3 12.75V11.25ZM18.4286 12.75C18.8428 12.75 19.1786 12.4142 19.1786 12C19.1786 11.5858 18.8428 11.25 18.4286 11.25V12.75ZM3 16.25C2.58579 16.25 2.25 16.5858 2.25 17C2.25 17.4142 2.58579 17.75 3 17.75V16.25ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17C12.75 16.5858 12.4142 16.25 12 16.25V17.75ZM3 7.75H21V6.25H3V7.75ZM3 12.75H18.4286V11.25H3V12.75ZM3 17.75H12V16.25H3V17.75Z"
        fill="white"
      />
    </svg>
  );
}

export default NavbarHamburgerIcon;
