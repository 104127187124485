import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import NavbarHamburgerIcon from "../../assets/icons/NavbarHamburgerIcon";
import NavbarLogisticIcon from "../../assets/icons/NavbarLogisticIcon";
import NavbarVehicleIcon from "../../assets/icons/NavbarVehicleIcon";

const StyledNavBar = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  top: 0px;
  background: #084bbb;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledNavBarLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHandbookLeft = styled.div`
  position: absolute;
  height: 178px;
  width: 160px;
  left: 16px;
  top: 112px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(42, 60, 124, 0.15);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledHandbookRight = styled.div`
  position: absolute;
  height: 178px;
  width: 160px;
  right: 16px;
  top: 112px;
  background: #ffffff;
  box-shadow: 0px 30px 30px rgba(42, 60, 124, 0.15);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledHandbookHeaderText = styled.div`
  font-family: "DM Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* line-height: 42px; */
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #06144e;
`;

const StyledHandbookDescriptionText = styled.div`
  margin: 0 20px;
  font-family: "DM Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(6, 20, 78, 0.6);
`;

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <StyledNavBar>
        <div style={{ paddingLeft: "20px" }}>
          <IconButton
            onClick={() => {
              navigate("/profile");
            }}
          >
            <NavbarHamburgerIcon />
          </IconButton>
        </div>
        <StyledNavBarLogoWrapper>
          {/* <LogoNavBarIcon /> */}
          <img
            src={"/images/logo_white.png"}
            alt="logo"
            width={"200"}
            height={"65"}
          />
        </StyledNavBarLogoWrapper>
        <div style={{ paddingRight: "44px" }}>
          {/* <IconButton>
            <NavbarNotificationIcon />
          </IconButton> */}
        </div>
        <StyledHandbookLeft
          onClick={() => {
            navigate("post-load");
          }}
        >
          <NavbarLogisticIcon />
          <StyledHandbookHeaderText>Yük İlanı Ver</StyledHandbookHeaderText>
          <StyledHandbookDescriptionText>
            {/* Lorem ipsum dolor sit amet */}
          </StyledHandbookDescriptionText>
        </StyledHandbookLeft>

        <StyledHandbookRight
          onClick={() => {
            navigate("post-vehicle");
          }}
        >
          <NavbarVehicleIcon />
          <StyledHandbookHeaderText>Araç İlanı Ver</StyledHandbookHeaderText>
          <StyledHandbookDescriptionText>
            {/* Lorem ipsum dolor sit amet */}
          </StyledHandbookDescriptionText>
        </StyledHandbookRight>
      </StyledNavBar>
    </>
  );
};

export default Header;
