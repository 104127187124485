import styled from "styled-components";
import LogoSplashIcon from "../assets/icons/LogoSplashIcon";

const StyledWrapper = styled.div`
  max-width: 425px;
  width: 100%;
  background-color: #084bbb;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const StyledBrandName = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  /* identical to box height */

  color: #ffffff;
`;

const SplashScreen = () => {
  return (
    <StyledWrapper>
      <LogoSplashIcon />
      <StyledBrandName>yükline</StyledBrandName>
    </StyledWrapper>
  );
};

export default SplashScreen;
