import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import CustomTabs from "../components/CustomTabs";
import EmptyState from "../components/EmptyState";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import CargoCard from "../components/CargoCard";
import VehicleCard from "../components/VehicleCard";
import OfferCard from "../components/OfferCard";
import ThinBackButton from "../components/UI/ThinBackButton";

import getOffersByAdvertisementId from "../api/getOffersByAdvertisementId";
import getUserOffersRequest from "../api/getUserOffersRequest";
import getUserAdvertisements from "../api/getUserAdvertisements";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
`;

const TabOptions = [
  { label: "Aldığım Teklifler", id: 1 },
  { label: "Verdiğim Teklifler", id: 2 },
];

const Offers = () => {
  const [selectedTab, setSelectedtab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [myOffers, setMyOffers] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);
  const [myAdOffers, setMyAdOffers] = useState([]);
  const [showAdOffers, setShowAdOffers] = useState(false);
  const [selectedAd, setSelectedAd] = useState();

  useEffect(() => {
    loadData();
  }, [selectedTab]); // eslint-disable-line react-hooks/exhaustive-deps

  // const loadOfferList = async () => {
  //   const result = await getUserOffersRequest();
  //   console.log(result.data);
  //   setIsLoading(false);
  // };

  const loadData = async () => {
    setIsLoading(true);

    if (selectedTab === 0) {
      let req = {
        advertisementStatus: null,
        advertisementType: null,
        hasOffer: true,
      };
      const result = await getUserAdvertisements(req);

      if (result?.code === 200) {
        setAdvertisements(result.data?.items ?? []);
      } else {
        console.log("error:", result);
      }
      setIsLoading(false);
    }
    if (selectedTab === 1) {
      const result = await getUserOffersRequest();

      if (result?.code === 200) {
        setMyOffers(result.data ?? []);
      } else {
        console.log("error:", result);
      }
      setIsLoading(false);
    }
  };

  const loadOffers = async (advertisementId) => {
    setIsLoading(true);
    setMyAdOffers([]);

    let result = await getOffersByAdvertisementId(advertisementId);
    if (result?.code === 200 && result?.data?.length > 0) {
      setMyAdOffers(result?.data ?? []);
    }

    setIsLoading(false);
  };

  const generateAdvertisement = (advertisement, index) => {
    if (advertisement?.advertisementTypeId === 1) {
      return (
        <CargoCard
          load={advertisement}
          hideFavorite={true}
          preventLink={true}
          clicked={(e) => {
            setShowAdOffers(true);
            loadOffers(e.id);
            setSelectedAd(e);
          }}
        />
      );
    } else {
      return (
        <VehicleCard
          vehicle={advertisement}
          hideFavorite={true}
          preventLink={true}
          clicked={(e) => {
            setShowAdOffers(true);
            loadOffers(e.id);
            setSelectedAd(e);
          }}
        />
      );
    }
  };

  return (
    <StyledWrapper>
      <NavBar />
      <StyledContainer>
        <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} />
        {isLoading ? <Loader /> : null}

        {selectedTab === 0 &&
          (!showAdOffers && advertisements && advertisements?.length > 0 ? (
            advertisements?.map((advertisement, index) => (
              <React.Fragment key={index}>
                {generateAdvertisement(advertisement, index)}
              </React.Fragment>
            ))
          ) : !showAdOffers ? (
            <EmptyState />
          ) : null)}

        {selectedTab === 0 &&
          (showAdOffers && myAdOffers && myAdOffers?.length > 0 ? (
            <>
              <Grid container padding="0 20px">
                <Grid item xs={12} display="flex" justifyContent="flex-start">
                  <ThinBackButton
                    label={selectedAd?.header ?? "İlanlar"}
                    icon={<ArrowBackIosIcon />}
                    onClick={() => {
                      setShowAdOffers(false);
                      setSelectedAd();
                    }}
                  />
                </Grid>
              </Grid>
              {myAdOffers?.map((offer, index) => (
                <OfferCard key={index} offer={offer} />
              ))}
            </>
          ) : showAdOffers ? (
            <>
              <Grid container padding="0 20px">
                <Grid xs={12} display="flex" justifyContent="flex-start">
                  <ThinBackButton
                    label={selectedAd?.header ?? "İlanlar"}
                    icon={<ArrowBackIosIcon />}
                    onClick={() => {
                      setShowAdOffers(false);
                      setSelectedAd();
                    }}
                  />
                </Grid>
              </Grid>
              <EmptyState />
            </>
          ) : null)}

        {selectedTab === 1 &&
          (myOffers && myOffers?.length > 0 ? (
            myOffers?.map((offer, index) => (
              <OfferCard key={index} offer={offer} />
            ))
          ) : (
            <EmptyState />
          ))}
      </StyledContainer>
      <BottomMenu selected={"Home"} />
    </StyledWrapper>
  );
};

export default Offers;
