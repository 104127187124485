import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import UploadCard from "../components/UploadCard";

import createVehicleDocumentRequest from "../api/createVehicleDocumentRequest";
import getVehicleDocumentsRequest from "../api/getVehicleDocumentsRequest";
import * as utils from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  background-color: #d4d6dc7d;
  height: 100vh;
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
`;

const documentTypes = [
  {
    id: 5,
    value: "Vergi Levhası",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
  {
    id: 6,
    value: "Araç Ruhsat",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
  {
    id: 7,
    value: "Dorse Ruhsat",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
  {
    id: 8,
    value: "Sürücü Belgesi",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
  {
    id: 9,
    value: "Diğer",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
];

const VehicleDocuments = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const { v } = useParams();
  // eslint-disable-next-line
  const [vehicle, setVehicles] = useState(utils.decodeJson(v));
  const [document, setDocument] = useState();
  const hiddenFileInput = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [documents, setDocuments] = useState(documentTypes);

  useEffect(() => {
    loadVehicleDocuments();
    // eslint-disable-next-line
  }, []);

  const loadVehicleDocuments = async () => {
    const result = await getVehicleDocumentsRequest(vehicle.id);

    if (result?.data?.length > 0) {
      result?.data.forEach((document, index) => {
        let list = [...documents];
        let doc = list.find((q) => q.id === document.documentTypeId);
        if (doc) {
          doc.url = document?.documentPath;
          doc.statusText = document?.documentStatus;
          setDocuments(list);
        }
      });
    } else {
      // * This part cleans previous documents
      const converted = documentTypes.map((item, value) => ({
        id: item.id,
        value: item.value,
        type: item.type,
        icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
      }));
      setDocuments(converted);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  const isFileValid = (fileUploaded) => {
    if (fileUploaded && fileUploaded["type"].split("/")[0] !== "image") {
      alert("Dosya görsel yüklenmesi gerekmektedir!");
      return false;
    }

    if (fileUploaded?.size > 1024 * 1024 * 15) {
      alert("Dosya boyutu en fazla 15 MB olması gerekmektedir!");
      return false;
    }

    return true;
  };

  const selectDocumentHandler = (document) => {
    setDocument(document);
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    const fileUploaded = event.target.files[0];
    if (!isFileValid(fileUploaded)) {
      return;
    }

    var formdata = new FormData();
    formdata.append("file", event.target.files[0], event.target.files[0]?.name);
    formdata.append("vehicleId", vehicle?.id);
    formdata.append("documentTypeId", document?.id);
    formdata.append("userId", user.id);

    const result = await createVehicleDocumentRequest(formdata);

    if (result?.code === 200) {
      setSuccess();
      loadVehicleDocuments();
      setTimeout(() => setShowAlert(false), 3000);
    } else {
      setError();
      setTimeout(() => setShowAlert(false), 3000);
    }
  };

  return (
    <StyledWrapper>
      <NavBar header={`${vehicle?.plateNo} Belgeleri`} showLogo={false} />
      <ContentWrapper>
        {/* <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} /> */}
        <StyledCardsWrapper>
          <>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </>
          {showAlert && (
            <AlertBox
              severity={showAlertDetail?.severity}
              title={showAlertDetail?.title}
              message={showAlertDetail?.message}
            />
          )}
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Araçlarım"}
              icon={<ArrowBackIos />}
              onClick={() => {
                navigate("/my-vehicles");
              }}
            />
            {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
          </Grid>
          {documents.map((document, index) => (
            <UploadCard
              key={index}
              document={document}
              selectDocumentHandler={() => selectDocumentHandler(document)}
            />
          ))}
        </StyledCardsWrapper>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default VehicleDocuments;
