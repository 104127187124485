export const convertStatus = (offer) => {
  switch (offer?.offerStatusId) {
    case 1:
      return <span style={{ color: "orange" }}>{offer?.offerStatus}</span>;
    case 2:
      return <span style={{ color: "red" }}>{offer?.offerStatus}</span>;
    case 3:
      return <span style={{ color: "gray" }}>{offer?.offerStatus}</span>;
    case 4:
      return <span style={{ color: "purple" }}>{offer?.offerStatus}</span>;
    case 5:
      return <span style={{ color: "green" }}>{offer?.offerStatus}</span>;
    case 6:
      return <span style={{ color: "purple" }}>{offer?.offerStatus}</span>;
    case 7:
      return <span style={{ color: "green" }}>{offer?.offerStatus}</span>;

    default:
      return <span>-</span>;
  }
};
