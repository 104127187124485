import { create } from "apisauce";
import {} from "react-router-dom";

const API = create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
});

API.addRequestTransform((request) => {
  const store = JSON.parse(localStorage.getItem("_ygu"));
  request.headers["X-API-KEY"] = process.env.REACT_APP_API_KEY;
  request.headers["Content-Type"] = "application/json;charset=UTF-8";
  if (
    store?.token?.accessToken?.length > 0 &&
    store?.token?.accessToken !== undefined
  ) {
    request.headers["Authorization"] = `Bearer ${store?.token?.accessToken}`;
  }
  request.headers["Access-Control-Allow-Origin"] = "*";
  request.headers["Access-Control-Allow-Headers"] =
    "Origin, X-Requested-With, Content-Type, Accept";
});

API.addMonitor((response) => {
  if (response.status === 401 || response.status === 403) {
    console.log("api unauthorized");
    if (window.location?.pathname !== "/login") {
      window.location.replace("/logout");
    }
  }
});

export default API;
