import WestIcon from "@mui/icons-material/West";
import { Grid, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import NavBar from "../components/Layout/NavBar";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultCheckbox from "../components/UI/DefaultCheckbox";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import DefaultSelect from "../components/UI/DefaultSelect";
import * as validators from "../utils/validators";
import * as utils from "../utils/utils";

import createVehicleRequest from "../api/createVehicleRequest";
import loadCapacityTypeListRequest from "../api/loadCapacityTypeListRequest";
import trailerListRequest from "../api/trailerListRequest";
import vehicleListRequest from "../api/vehicleListRequest";

const ContentWrapper = styled.div`
  padding-top: 80px;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledCheckboxWrapper = styled.div`
  align-self: left;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const AddVehicle = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [vehicles, setVehicles] = useState([]);
  const [trailers, setTrailers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [capacities, setCapacities] = useState([]);
  const [formData, setFormData] = useState([
    {
      key: "plate",
      value: null,
      type: "plate",
      required: true,
      hasError: false,
    },
    {
      key: "vehicleTypeId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "trailerId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "loadCapacityAmount",
      value: null,
      type: "number",
      required: true,
      hasError: false,
    },
    {
      key: "loadUnitId",
      value: 1,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "condition1",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    loadVehicles();
    loadCapacities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadTrailers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.find((q) => q.key === "vehicleTypeId")?.value]);

  const loadVehicles = async () => {
    const result = await vehicleListRequest();

    if (result?.code === 200) {
      const converted = result.data.map((item, value) => ({
        id: item.id,
        label: item.value,
      }));

      setVehicles(converted ?? []);
    } else {
      console.log("error:", result);
    }
  };

  const loadTrailers = async () => {
    const vehicleId = formData.find((q) => q.key === "vehicleTypeId")?.value;

    if (vehicleId && vehicleId > 0) {
      const result = await trailerListRequest(vehicleId);

      if (result?.code === 200) {
        const converted = result.data.map((item, value) => ({
          id: item.id,
          label: item.value,
        }));

        setTrailers(converted ?? []);
      } else {
        console.log("error:", result);
      }
    } else {
      setTrailers([]);
    }
  };

  const loadCapacities = async () => {
    const result = await loadCapacityTypeListRequest();

    if (result?.code === 200) {
      const converted = result.data.map((item, value) => ({
        id: item.id,
        label: item.value,
      }));

      setCapacities(converted ?? []);
    } else {
      console.log("error:", result);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  const handleCreateVehicle = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      vehicleTypeId: formData.find((q) => q.key === "vehicleTypeId").value,
      trailerId: formData.find((q) => q.key === "trailerId").value,
      userId: user.id,
      plateNo: formData.find((q) => q.key === "plate").value,
      // loadCapacityId: formData.find((q) => q.key === "loadCapacityId").value,
      loadCapacityAmount: +formData.find((q) => q.key === "loadCapacityAmount")
        .value,
      // * Load Unit Default value "ton"
      loadUnitId: 1,
    };

    const result = await createVehicleRequest(request);

    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");

      let params = {
        type: "vehicle",
        title: "Araç kaydınız gerçekleştirildi.",
        message:
          "Platformun güvenirliliği için belgeleriniz ,yükline ekibince onaylandıktan sonra teklif verebileceksiniz.",
        button: "Belge Yükle",
        url: `my-vehicles`,
        urlParams: utils.encodeJson(result.data),
      };

      setTimeout(() => {
        navigate(
          `/success-message/${params.type}/${params.title}/${params.message}/${params.button}/${params.url}/${params.urlParams}`
        );
        setIsLoading(false);
      }, 1000);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Araçlarım"}
              icon={<WestIcon />}
              onClick={() => {
                navigate("/my-vehicles");
              }}
            />
            {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="plate"
              type={"text"}
              label="Plaka"
              variant="filled"
              backgroundColor="#E5E5E587"
              value={formData.find((q) => q.key === "plate").value ?? ""}
              onChange={(e) => {
                let text = e.target.value?.toUpperCase();
                let val = formData.find((q) => q.key === "plate");
                val.value = text;
                val.hasError = !validators.checkIsValidTurkishPlateNo(text);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTurkishPlateNo(
                  formData.find((q) => q.key === "plate").value
                )
              }
              textTransform="uppercase"
            />
            {isSubmit &&
            !validators.checkIsValidTurkishPlateNo(
              formData.find((q) => q.key === "plate").value
            ) ? (
              <AlertBox
                severity="error"
                message="Örn: 34ABC12, 34ABC123, 34ABC1234, 34ABC12345"
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <DefaultSelect
              id={"vehicleTypeId"}
              label={"Araç Tipi"}
              options={vehicles}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "vehicleTypeId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "vehicleTypeId");
                let trailer = formData.find((q) => q.key === "trailerId");
                trailer.value = null;
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "vehicleTypeId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultSelect
              id={"trailerId"}
              label={"Araç Kasa Tipi"}
              options={trailers}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "trailerId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "trailerId");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "trailerId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="loadCapacityAmount"
              type={"number"}
              label="Yük Kapasitesi"
              variant="filled"
              backgroundColor="#E5E5E587"
              value={
                formData.find((q) => q.key === "loadCapacityAmount").value ?? ""
              }
              onChange={(e) => {
                let val = formData.find((q) => q.key === "loadCapacityAmount");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidNumber(e.target.value);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidNumber(
                  formData.find((q) => q.key === "loadCapacityAmount").value
                )
              }
              min={1}
              max={50}
              endAdornment={<InputAdornment position="end">Ton</InputAdornment>}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <DefaultSelect
              id={"loadUnitId"}
              label={"Birimi"}
              options={optionsUnit}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "loadUnitId").value}
              onChange={(option) => {
                // let val = formData.find((q) => q.key === "loadUnitId");
                // val.value = option?.id;
                // val.hasError = !validators.checkIsValidSelect(option?.id);
                // setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "loadUnitId").value
                )
              }
              disabled={true}
            />
          </Grid> */}
        </StyledGridContainer>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            label={"Taşıma şartlarını kabul ediyorum."}
            value={Boolean(formData.find((q) => q.key === "condition1")?.value)}
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition1");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition1").value
            }
          />
        </StyledCheckboxWrapper>

        <div style={{ padding: "0 18px" }}>
          {showAlert ? (
            <Grid item xs={12}>
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Araç Ekle</StyledButtontext>}
            isProgress={isLoading}
            onClick={() => handleCreateVehicle()}
          />
        </div>
      </ContentWrapper>
    </>
  );
};

export default AddVehicle;
