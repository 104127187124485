import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../features/user/userSlice";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  dispatch(clearUser());
  localStorage.removeItem("_ygu");
  setTimeout(() => {
    navigate("/login");
  }, 100);

  return <></>;
};

export default Logout;
