import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as utils from "../../utils/utils";

const StyledCard = styled(Grid)`
  background: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 150px;
`;

const DetailButton = styled.div`
  position: absolute;
  right: 0px;
  margin-right: -10px;
`;

const Plate = styled(Typography)`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: #0a0909;
`;

const VehicleDescription = styled(Typography)`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  color: rgba(58, 58, 58, 0.6);
  margin-top: 5px;
`;

const VehicleDescriptionExtended = styled(Typography)`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: rgba(0, 0, 0, 0.93);
`;

const MyVehicleListCard = ({ vehicle }) => {
  const navigate = useNavigate();

  return (
    <StyledCard container padding="20px">
      <Grid
        item
        xs={12}
        position="relative"
        display="flex"
        flexDirection="column"
        gap="5px"
      >
        <Plate>{vehicle?.plateNo}</Plate>
        <DetailButton>
          <IconButton
            onClick={() =>
              navigate(`/vehicle-documents/${utils.encodeJson(vehicle)}`)
            }
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </DetailButton>
      </Grid>
      <Grid item xs={12} marginTop="0px">
        <VehicleDescription>{`${vehicle?.trailer} ${vehicle?.vehicleType}`}</VehicleDescription>
      </Grid>
      <Grid item xs={12}>
        <VehicleDescriptionExtended>{`${utils.convertSeperator(
          vehicle?.loadCapacityAmount
        )} ${vehicle?.loadUnit}`}</VehicleDescriptionExtended>
      </Grid>
    </StyledCard>
  );
};

export default MyVehicleListCard;
