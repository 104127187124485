import { Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import HttpsIcon from "@mui/icons-material/Https";
import { Box, Grid, IconButton } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import { setUser } from "../features/user/userSlice";
import * as validators from "../utils/validators";

import loginRequest from "../api/loginRequest";
// import ReloadApp from "../components/ReloadApp";

const StyledLogoGrid = styled(Grid)`
  padding-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  gap: 12px;
`;

const StyledFormGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  padding-top: 36px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const StyledForgetPasswordLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #084bbb;
`;

const StyledRegisterNowLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;

  color: #3a3a3a;
`;

const SignIn = () => {
  // const [hasVersionUpdate, setHasVersionUpdate] = useState(false);
  const currentVersion = "1.3.23.1803";
  const navigate = useNavigate();
  // eslint-disable-next-line
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState([
    {
      key: "phone",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "password",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    checkHasVersionUpdate();
  }, []);

  const checkHasVersionUpdate = () => {
    if (process.env.REACT_APP_VERSION !== currentVersion) {
      // setHasVersionUpdate(true);
    }
  };

  const handleLogin = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      email: formData.find((q) => q.key === "phone").value,
      password: formData.find((q) => q.key === "password").value,
    };

    const result = await loginRequest(request);

    if (result?.code === 200 && result?.data?.user?.isActive) {
      var userDecodedToken = jwt_decode(result.data?.token?.accessToken);
      result.data.id = userDecodedToken.userId;
      dispatch(setUser(result?.data));
      handleLocalStorage(result?.data);
      navigate("/");
    } else {
      setError("Geçersiz kullanıcı adı veya şifre.");
    }

    setIsLoading(false);
  };

  const handleLocalStorage = (item) => {
    localStorage.setItem("_ygu", JSON.stringify(item));
  };

  const setError = (err) => {
    setShowAlertDetail({
      title: "Hata",
      message: err || "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  return (
    <Grid container>
      {/* {hasVersionUpdate ? <ReloadApp /> : null} */}
      <StyledLogoGrid item xs={12}>
        {/* <LogoSignInIcon /> */}
        <img src={"/images/logo.png"} alt="logo" width={"340"} height={"120"} />
      </StyledLogoGrid>
      <StyledFormGrid item xs={12}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            width: "334px",
          }}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DefaultInputFeild
            id="phone"
            type={"text"}
            // label="Telefon"
            label="E-posta"
            variant="filled"
            backgroundColor="#E5E5E587"
            // startIcon={<PhoneInTalkIcon />}
            startIcon={<Mail />}
            value={formData.find((q) => q.key === "phone").value ?? ""}
            onChange={(e) => {
              let val = formData.find((q) => q.key === "phone");
              val.value = e.target.value;
              val.hasError = !validators.checkIsValidTextField(e.target.value);
              setFormData([...formData]);
            }}
            error={
              isSubmit &&
              !validators.checkIsValidTextField(
                formData.find((q) => q.key === "phone").value
              )
            }
          />

          <div style={{ marginTop: "32px" }}>
            <DefaultInputFeild
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Şifre"
              variant="filled"
              backgroundColor="#E5E5E587"
              borderRadius="8px"
              startIcon={
                isPasswordFocused ? (
                  <>
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <HttpsIcon />
                  </>
                ) : (
                  <HttpsIcon />
                )
              }
              onFocus={() => {
                setIsPasswordFocused(true);
              }}
              value={formData.find((q) => q.key === "password").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "password");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextField(
                  e.target.value
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextField(
                  formData.find((q) => q.key === "password").value
                )
              }
            />
          </div>
          <div
            style={{
              alignSelf: "left",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              marginTop: "14px",
            }}
          >
            <StyledForgetPasswordLabel
              onClick={() => navigate("/forget-password")}
            >
              Şifremi unuttum?
            </StyledForgetPasswordLabel>
            {/* <DefaultCheckbox
              label={"Beni Hatırla"}
              onChange={(e) => console.log("")}
            /> */}
          </div>
          {showAlert ? (
            <Grid item xs={12} style={{ paddingTop: "12px" }}>
              <AlertBox
                severity="error"
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
          <div style={{ marginTop: "26px" }}>
            <DefaultButton
              size={"small"}
              variant={"contained"}
              buttonColor={"#084BBB"}
              buttonColorDisabled={"#084BBB"}
              buttonTextColor={"#FFFFFF"}
              buttonTextTransform={"none"}
              buttonMinHeight={48}
              buttonRadius={"10px"}
              fullWidth={true}
              buttonText={<StyledButtontext>Giriş Yap</StyledButtontext>}
              isProgress={isLoading}
              onClick={(e) => {
                e.preventDefault();
                handleLogin();
              }}
              type="submit"
            />
          </div>
          <div style={{ marginTop: "24px" }}>
            <StyledRegisterNowLabel>
              Hesabınız yok mu?
              <span
                onClick={() => {
                  navigate("/register");
                }}
                style={{ color: "rgba(8, 75, 187, 1)", fontWeight: "600" }}
              >
                &nbsp; Üye Olun
              </span>
            </StyledRegisterNowLabel>
          </div>

          <div>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "10px",
                padding: "10px",
                color: "#CCC",
                fontWeight: "500",
              }}
            >
              {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </Box>
      </StyledFormGrid>
    </Grid>
  );
};

export default SignIn;
