import { Alert, AlertTitle } from "@mui/material";

const AlertBox = ({ severity, title, message }) => {
  return (
    <Alert severity={severity} style={{ borderRadius: "8px" }}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};

export default AlertBox;
