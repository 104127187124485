import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Select from "@mui/material/Select";
import React from "react";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-input {
    border-radius: 8px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.58);
    min-height: 56px;
  }

  & .MuiFormLabel-filled {
    margin-top: 15px !important;
  }

  /* & .Mui-focused {
    margin-top: 15px !important;
  } */

  fieldset {
    border: 1px solid rgba(229, 229, 229, 0.53) !important;
    color: rgba(229, 229, 229, 0.53) !important;
  }
`;

const StyledFormControl = styled(FormControl)`
  & .MuiInputLabel-root {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  & .MuiFormLabel-filled {
    margin-top: 12px !important;
  }

  & .MuiInputLabel-filled.MuiInputLabel-shrink {
    margin: 12px 0 0 0 !important;
  }

  & .Mui-focused.MuiInputLabel-outlined {
    margin: 12px 0 0 0 !important;
  }

  & .MuiSelect-select {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #000 !important;
  }

  fieldset {
    border: 1px solid rgba(229, 229, 229, 0.53) !important;
    color: rgba(229, 229, 229, 0.53) !important;
  }
`;

const DefaultSelect = ({
  id,
  label,
  options,
  width,
  backgroundColor,
  variant,
  error,
  onChange,
  value,
  disabled,
}) => {
  return (
    <StyledFormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        disabled={disabled}
        value={value ?? ""}
        id={id}
        input={<OutlinedInput label={label} />}
        sx={{
          width: width,
          backgroundColor: value && value > 0 ? "#FFFFFF" : backgroundColor,
          border: error
            ? "1px solid red"
            : value && value > 0
            ? "1px solid rgba(8, 75, 187, 1)"
            : backgroundColor,
          borderRadius: "8px",
        }} // className={"selected"}
        onChange={(event, newValue) => {
          let res = { id: event?.target?.value };
          onChange(res);
        }}
        fullWidth={true}
      >
        {options &&
          options.map((option, index) => (
            <MenuItem key={index} value={option.id} id={option.id}>
              {option.label}
            </MenuItem>
          ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default DefaultSelect;
