import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import styled from "styled-components";

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.58) !important;

  &.MuiInputLabel-filled.MuiInputLabel-shrink {
    margin: -5px 0 0 3px;
  }
`;

const StyledInput = styled(OutlinedInput)`
  min-height: 56px;
  /* padding-top: 8px; */

  &.MuiOutlinedInput-root {
    /* background-color: rgba(229, 229, 229, 0.53) !important; */
    border-radius: 8px !important;

    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    gap: 10px;
  }

  fieldset {
    border: 1px solid rgba(229, 229, 229, 0.53) !important;
    color: rgba(229, 229, 229, 0.53) !important;
  }
`;

const DefaultInputFeild = ({
  id,
  label,
  variant,
  backgroundColor,
  startIcon,
  type,
  value,
  onChange,
  error,
  multiline,
  rowCount,
  placeholderText,
  disabled,
  preventChange,
  textTransform,
  maxLength,
  min,
  max,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value ?? "");

  // useEffect(() => {
  //   if (!(value?.length > 0)) setTimeout(() => setInputValue(""), 500);
  // }, []);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <StyledFormControl variant={variant}>
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
      <StyledInput
        autoComplete="new-password"
        label={label}
        id={id}
        type={type}
        value={inputValue}
        multiline={multiline}
        rows={rowCount}
        placeholder={placeholderText}
        disabled={disabled}
        style={{
          backgroundColor:
            inputValue && inputValue?.trim()?.length > 0
              ? "#FFFFFF"
              : backgroundColor,
          border: error
            ? "1px solid red"
            : inputValue && inputValue?.trim()?.length > 0
            ? "1px solid rgba(8, 75, 187, 1)"
            : backgroundColor,
        }}
        onChange={(e) => {
          if (preventChange) return;
          if (maxLength && e?.target?.value?.length > maxLength) return;
          setInputValue(e?.target?.value);
          onChange(e);
        }}
        endAdornment={
          <InputAdornment position="end">{startIcon}</InputAdornment>
        }
        inputProps={{
          type: type,
          lang: "en-US",
          style: { textTransform: textTransform },
          min: min,
          max: max,
        }}
        {...props}
      />
    </StyledFormControl>
  );
};

export default DefaultInputFeild;
