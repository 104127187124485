import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import OfferVehicleRoadIcon from "../assets/icons/OfferVehicleRoadIcon";
import OfferVehicleDetailImage from "../assets/images/OfferVehicleDetailImage";
import * as adStatus from "../components/AdStatus";
import AlertBox from "../components/Alert";
import Loader from "../components/Loader";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultCurrencyField from "../components/UI/DefaultCurrencyField";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import ThinBackButton from "../components/UI/ThinBackButton";

import acceptOfferRequest from "../api/acceptOfferRequest";
import getAgreementAdvertisement from "../api/getAgreementAdvertisement";
import cancelAdvertisementRequest from "../api/cancelAdvertisementRequest";
import cancelUserOfferRequest from "../api/cancelUserOfferRequest";
import getAdvertisementRequest from "../api/getAdvertisementRequest";
import getOfferRequest from "../api/getOfferRequest";
import getSettingsRequest from "../api/getSettingsRequest";
import getUserSettingsRequest from "../api/getUserSettingsRequest";
import offerPaymentVehicleRequest from "../api/offerPaymentVehicleRequest";
import offerRequest from "../api/offerRequest";
import rejectOfferRequest from "../api/rejectOfferRequest";
import { AdvertisementType } from "../enums/advertisement-type.enum";
import { SettingsType } from "../enums/settings-type-enum";
import { AdvertisementStatusType } from "../enums/advertisement-status-type.enum";
import { OfferStatusType } from "../enums/offer-status-type.enum";
import { AgreementTypeEnum } from "../enums/agreement-type.enum";
import Agreement from "../components/Agreement";
import DefaultCheckbox from "../components/UI/DefaultCheckbox";

const ContentWrapper = styled.div``;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledCardDetail = styled.div`
  display: flex;
  justify-content: start;
  gap: 4px;
  padding-top: 18px;
`;

const StyledCardDetailPrimary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.58);
`;

const StyledCardDetailSecondary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0a0909;
`;

const StyledRoadInformation = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: rgba(0, 0, 0, 0.77) !important;
`;

const StyledRoadInformationBubbleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 28px;
`;

const StyledSourcePrimary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #c4c4c4;
`;

const StyledSourceSecondary = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.77);
`;

const StyledWrapperSourceDestination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledInputLabel = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: rgba(10, 9, 9, 0.83);
`;

const StyledAgreementLabel = styled.span`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  :hover,
  :focus {
    text-decoration: underline;
    color: #084bbb;
  }
`;

const OfferVehicleDetail = () => {
  const navigate = useNavigate();
  let { id, confirm, amount, notes, offerId, backUrl } = useParams();
  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});

  const [advertisement, setAdvertisement] = useState();
  const [note, setNote] = useState(notes ?? "");
  const [offer, setOffer] = useState();
  const [offerAmount, setOfferAmount] = useState(amount ?? "");

  // eslint-disable-next-line no-unused-vars
  const [paramIsCustomComission, setParamIsCustomComission] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [paramComission, setParamComission] = useState(0);
  const [paramSettings, setParamSettings] = useState({});

  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [showAdCancel, setShowAdCancel] = useState(true);
  const [agreementAdvertisement, setAgreementAdvertisement] = useState({});
  const [showAgreement, setShowAgreement] = useState(false);
  const [approveAgreement, setApproveAgreement] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadAdvertisement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAdvertisement = async () => {
    const result = await getAdvertisementRequest(id);

    if (result?.code === 200) {
      // * redirect vehicles advertisements
      if (result?.data?.advertisementTypeId === AdvertisementType.CARGO) {
        navigate(`/offer-load/${result?.data?.id}`);
      }
      setAdvertisement(result?.data ?? {});
      await loadOffer(result?.data ?? {});
    } else {
      console.log("error:", result);
      navigate(`/`);
    }
  };

  const loadAgreementAdvertisement = async () => {
    const result = await getAgreementAdvertisement(
      AgreementTypeEnum.JobbingAgreement,
      id
    );

    if (result?.code === 200) {
      setAgreementAdvertisement(result?.data);
      setShowAgreement(true);
    } else {
      console.log("error:", result);
    }
  };

  const loadAgreementCarriage = async () => {
    const result = await getAgreementAdvertisement(
      AgreementTypeEnum.CarriageAgreement,
      id,
      offerId ?? 0
    );

    if (result?.code === 200) {
      setAgreementAdvertisement(result?.data);
      setShowAgreement(true);
    } else {
      console.log("error:", result);
    }
  };

  const loadOffer = async (ad) => {
    if (!offerId) {
      await handleSettings();
      await handleUserSettings(ad);
      return;
    }
    const result = await getOfferRequest(offerId);

    if (result?.code === 200) {
      setOffer(result?.data);
      setOfferAmount(+(result?.data?.offerAmount ?? 0));
    } else {
      setError();
    }
    setIsLoading(false);
  };

  const handleOffer = async () => {
    setIsSubmit(true);
    // * Prevent offering to own advertisement
    if (advertisement?.ownerUserId === user.id) {
      setError("Kendi ilanınıza teklif veremezsiniz!", "İşlem Başarısız!");
      return;
    }

    if (!(offerAmount > 0)) {
      setError("Teklif edeceğin fiyat geçersizdir.", "");
      return;
    }

    if (!approveAgreement) {
      setError("Simsarlık sözleşmesini kabul etmeden teklif veremezsiniz.", "");
      return;
    }

    setIsLoading(true);

    let request = {
      note: note,
      offerAmount: offerAmount,
      advertisementId: +id,
    };

    const result = await offerRequest(request);

    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");

      let params = {
        type: "vehicle",
        title: "Araç ilanı sahibine teklifin iletilmiştir",
        message:
          "Teklifin onaylanması halinde sizlere bilgilendirme yapılacaktır.",
        button: "Tamam",
      };

      handleRedirect(params);
    } else if (result?.message === "Offer.Data.Offer.ActiveOffer") {
      setError(
        "Bu ilana ait aktif bir teklifiniz bulunmaktadır. Yeniden teklif verebilmek için mevcut teklifinizi iptal etmeniz gerekmektedir.",
        "Teklif verilemedi!"
      );
      setIsLoading(false);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const handleAcceptOffer = async () => {
    const result = await acceptOfferRequest(offerId);
    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");

      let params = {
        type: "vehicle",
        title: "İlanınız için verilen teklifi kabul ettiniz",
        message:
          "Taşımanın başlayabilmesi için yükline ekibi en kısa sürede sizlerle iletişime geçeceklerdir.",
        button: "Tamam",
      };

      handleRedirect(params);
    } else {
      setError();
    }
  };

  const handleRejectOffer = async () => {
    const result = await rejectOfferRequest(offerId);
    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");

      let params = {
        type: "vehicle",
        title: "Teklif Reddedilme İşlemi Başarılı",
        message: "Teklif başarıyla reddetiniz...",
        button: "Tamam",
      };

      handleRedirect(params);
    } else {
      setError();
    }
  };

  const handleRedirect = (params) => {
    setTimeout(() => {
      navigate(
        `/success-message/${params.type}/${params.title}/${
          params.message ?? " "
        }/${params.button}`
      );
    }, 1000);
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  const checkCondition = () => {
    if (
      confirm === "true" &&
      advertisement?.advertisementStatusId === AdvertisementStatusType.OPEN &&
      checkAmIAdOwner()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkAmIAdOwner = () => {
    if (advertisement?.ownerUserId === user?.id) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancelAd = async () => {
    setIsLoadingCancel(true);
    const result = await cancelAdvertisementRequest(id);
    if (result?.code === 200) {
      setSuccess(
        "İlanınız başarıyla kaldırıldı. Ana sayfaya yönlendiriliyorsunuz..",
        "İşlem Başarılı!"
      );
      setShowAdCancel(false);
      setTimeout(() => {
        navigate(`/`);
      }, 5000);
    } else {
      setShowAdCancel(true);
      setIsLoadingCancel(false);
      setError();
    }
  };

  const handleCancelMyOffer = async () => {
    const result = await cancelUserOfferRequest(offerId);

    if (result?.code === 200) {
      setSuccess(
        "Teklifinizi iptal talebiniz alındı. Ana sayfaya yönlendiriliyorsunuz..",
        "İşlem Başarılı!"
      );
      setTimeout(() => {
        navigate(`/`);
      }, 3000);
    }
  };

  const handleOfferPayment = async () => {
    if (!approveAgreement) {
      setError(
        "Mesafeli Yük Taşıma Sözleşmesi kabul etmeden ödeme yapamazsınız.",
        ""
      );
      return;
    }

    setIsLoadingPayment(true);
    const result = await offerPaymentVehicleRequest(offerId);

    if (result?.code === 200) {
      setSuccess("Ödeme sayfasına yönlendiriliyorsunuz lütfen bekleyin..", "");
      window.location.href = result?.data;
    } else {
      setError();
      setIsLoadingPayment(false);
    }
  };

  const handleUserSettings = async (ad) => {
    let userId = 0;

    if (ad?.advertisementTypeId === AdvertisementType.VEHICLE) {
      userId = ad?.ownerUserId;
    } else if (ad?.advertisementTypeId === AdvertisementType.CARGO) {
      userId = user?.id;
    }

    const result = await getUserSettingsRequest(userId);

    if (result?.code === 200) {
      var comissionSettings = result?.data?.find(
        (q) => q.key === SettingsType.Comission
      );

      // * check comission is custom or not
      if (comissionSettings?.value?.isCustom) {
        setParamIsCustomComission(true);
        setParamComission(+(comissionSettings?.value?.value ?? 0));
      } else {
        setParamIsCustomComission(false);
      }
    } else {
      console.log("error:", result);
    }
  };

  const handleSettings = async () => {
    const result = await getSettingsRequest(user?.id);

    if (result?.code === 200) {
      let defaultSettings = {
        comission: +(result?.data?.comission ?? 0),
        kdv: +(result?.data?.kdv ?? 0),
        maxOfferLimit: +(result?.data?.maxOfferLimit ?? 0),
        minimumComissionAmount: +(result?.data?.minimumComissionAmount ?? 0),
        tevkifatKdv: +(result?.data?.tevkifatKdv ?? 0),
      };

      setParamSettings(defaultSettings);
    } else {
      console.log("error:", result);
    }
    setIsLoading(false);
  };

  const calculateKdv = () => {
    // * check if has offer
    if (offer?.id > 0) {
      return offer?.kdv ? +offer?.kdv : 0;
    } else {
      if (offerAmount > 0 && paramSettings?.kdv) {
        var result = 0;
        result = offerAmount * paramSettings.kdv;

        return result;
      } else {
        return 0;
      }
    }
  };

  const calculateTevkifat = () => {
    // * check if has offer
    if (offer?.id > 0) {
      return offer?.tevkifatKdv ? +offer?.tevkifatKdv : 0;
    } else {
      if (offerAmount > 0 && paramSettings?.tevkifatKdv) {
        var result = 0;
        result = offerAmount * paramSettings.kdv * paramSettings.tevkifatKdv;

        return result;
      } else {
        return 0;
      }
    }
  };

  const calculateYuklineComission = () => {
    // * check if has offer
    if (offer?.id > 0) {
      return offer?.comission ? +offer?.comission : 0;
    } else {
      if (offerAmount > 0 && paramSettings?.comission) {
        let processComission = 0;
        if (paramIsCustomComission) {
          processComission = paramComission ?? 0;
        } else {
          processComission = paramSettings.comission;
        }
        var result = 0;
        result =
          (offerAmount + offerAmount * paramSettings.kdv) * processComission;

        if (result >= paramSettings.minimumComissionAmount) {
          return result;
        } else return paramSettings.minimumComissionAmount;
      } else {
        return paramSettings.minimumComissionAmount;
      }
    }
  };

  const calculateNetAmount = () => {
    // * check if has offer
    if (offer?.id > 0) {
      return offer?.netAmount ? +offer?.netAmount : 0;
    } else {
      if (offerAmount > 0 && paramSettings?.comission) {
        var result = 0;
        result =
          offerAmount +
          calculateKdv() -
          calculateTevkifat() -
          calculateYuklineComission();
        result = result?.toFixed(2);
        return result;
      } else {
        return 0;
      }
    }
  };

  const calculateTotalAmount = () => {
    // * check if has offer
    if (offer?.id > 0) {
      return offer?.totalAmount ? +offer?.totalAmount : 0;
    } else {
      if (offerAmount > 0 && paramSettings?.comission) {
        var result = 0;
        result = offerAmount + calculateKdv() - calculateTevkifat();
        return result;
      } else {
        return 0;
      }
    }
  };

  const handleShowJobbingAgreement = async () => {
    await loadAgreementAdvertisement();
  };

  const handleShowCarriageAgreement = async () => {
    await loadAgreementCarriage();
  };
  return (
    <>
      <ContentWrapper>
        {showAgreement ? (
          <Agreement
            showAgreement={showAgreement}
            setShowAgreement={setShowAgreement}
            // header={"Şartlar ve Koşullar"}
            content={agreementAdvertisement}
          />
        ) : null}
        <StyledGridContainer container>
          <Grid item xs={12}>
            <ThinBackButton
              label={advertisement?.header}
              icon={<ArrowBackIosIcon />}
              onClick={() => {
                navigate(`/${backUrl ?? ""}`);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <OfferVehicleDetailImage />
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Grid item xs={12}>
                <StyledCardDetail style={{ paddingTop: "0px" }}>
                  <StyledCardDetailPrimary>Açıklama:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {advertisement?.description}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                {advertisement?.note ? (
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>
                      Özellikli Not:
                    </StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {advertisement?.note}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                ) : null}
                <StyledCardDetail>
                  <StyledCardDetailPrimary>Durumu:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {adStatus.convertStatus(advertisement)}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                <StyledCardDetail>
                  <StyledCardDetailPrimary>Tarih:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {advertisement?.closeDate}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                <StyledCardDetail>
                  <StyledCardDetailPrimary>Araç Tipi:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {advertisement?.vehicle?.vehicleType}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                <StyledCardDetail>
                  <StyledCardDetailPrimary>Kasa Tipi:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {advertisement?.vehicle?.trailer}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                <StyledCardDetail>
                  <StyledCardDetailPrimary>Parsiyel:</StyledCardDetailPrimary>
                  <StyledCardDetailSecondary>
                    {advertisement?.isPartialLoad ? "Evet" : "Hayır"}
                  </StyledCardDetailSecondary>
                </StyledCardDetail>
                {advertisement?.isPartialLoad ? (
                  <StyledCardDetail>
                    <StyledCardDetailPrimary>Boyut:</StyledCardDetailPrimary>
                    <StyledCardDetailSecondary>
                      {`Eni:${Math.trunc(advertisement?.partialLoadWidth)}cm 
                      Boyu:${Math.trunc(advertisement?.partialLoadLength)}cm 
                      Yüksekliği:${Math.trunc(
                        advertisement?.partialLoadHeight
                      )}cm`}
                    </StyledCardDetailSecondary>
                  </StyledCardDetail>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <StyledRoadInformation>Yol Bilgisi</StyledRoadInformation>
                <StyledRoadInformationBubbleWrapper>
                  {/* <StyledRoadInformationBubble>
                    <StyledCardDetailPrimary>
                      Ara Durak:
                    </StyledCardDetailPrimary>
                    <StyledCardDetailPrimary style={{ color: "#084BBB" }}>
                      Lorem ipsum
                    </StyledCardDetailPrimary>
                  </StyledRoadInformationBubble> */}
                  <OfferVehicleRoadIcon />
                </StyledRoadInformationBubbleWrapper>
                <StyledWrapperSourceDestination>
                  <div>
                    <StyledSourcePrimary>Nereden:</StyledSourcePrimary>
                    <StyledSourceSecondary>
                      {`${advertisement?.originCity}/${advertisement?.originCounty}`}
                    </StyledSourceSecondary>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <StyledSourcePrimary>Nereye:</StyledSourcePrimary>
                    <StyledSourceSecondary>
                      {`${advertisement?.destinationCity}/${advertisement?.destinationCounty}`}
                    </StyledSourceSecondary>
                  </div>
                </StyledWrapperSourceDestination>
              </Grid>

              {confirm !== "true" && checkAmIAdOwner() ? null : (
                <>
                  {confirm === "true" ? null : (
                    <Grid item xs={12}>
                      <DefaultInputFeild
                        type={"text"}
                        label="Özellikli Not Alanı"
                        variant="filled"
                        backgroundColor="#E5E5E587"
                        value={note}
                        onChange={(e) => {
                          setNote(e?.target?.value);
                        }}
                        // preventChange={confirm === "true"}
                      />
                    </Grid>
                  )}
                  {/* Teklif Tutarı */}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    gap="5px"
                  >
                    {confirm === "true" && checkAmIAdOwner() ? (
                      <StyledInputLabel>Teklif Edilen Fiyat:</StyledInputLabel>
                    ) : (
                      <StyledInputLabel>
                        {offer?.ownerUserId === user?.id
                          ? `Teklif Edilen Fiyat`
                          : `Teklif Edeceğin Fiyat:`}
                      </StyledInputLabel>
                    )}
                    <DefaultCurrencyField
                      value={offerAmount}
                      disabled={confirm === "true"}
                      onChange={(event, value) => {
                        if (!(value > 0)) {
                          setError("Teklif edeceğin fiyat geçersizdir.", "");
                        } else {
                          setShowAlert(false);
                        }
                        setOfferAmount(value);
                      }}
                      maximumValue={
                        process.env.REACT_APP_MAX_OFFER_LIMIT || "99999"
                      }
                      minimumValue={
                        process.env.REACT_APP_MIN_OFFER_LIMIT || "1000"
                      }
                      error={isSubmit && !(offerAmount > 0)}
                    />
                  </Grid>
                  {/* KDV */}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    gap="5px"
                  >
                    <StyledInputLabel>KDV</StyledInputLabel>
                    <DefaultCurrencyField
                      value={calculateKdv()}
                      disabled={true}
                    />
                  </Grid>

                  {/* Tevkifat */}
                  {confirm === "true" && checkAmIAdOwner() ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                    >
                      {paramSettings?.tevkifatKdv ? (
                        <StyledInputLabel>
                          {`%${paramSettings?.tevkifatKdv * 100} `} Tevkifat
                        </StyledInputLabel>
                      ) : (
                        <StyledInputLabel>Tevkifat Tutarı</StyledInputLabel>
                      )}
                      <DefaultCurrencyField
                        value={calculateTevkifat()}
                        disabled={true}
                      />
                    </Grid>
                  ) : null}

                  {/* Yükline Komisyon KDV Dahil */}
                  {confirm === "true" && checkAmIAdOwner() ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                    >
                      <StyledInputLabel>
                        Yükline Komisyonu KDV Dahil
                      </StyledInputLabel>
                      <DefaultCurrencyField
                        value={calculateYuklineComission()}
                        disabled={true}
                      />
                    </Grid>
                  ) : null}

                  {/* Faturaya Konu Tutar */}
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    gap="5px"
                  >
                    <StyledInputLabel>
                      {confirm === "true" && checkAmIAdOwner()
                        ? "Ödenecek Toplam Tutar"
                        : "Faturaya Konu Tutar"}
                    </StyledInputLabel>
                    <DefaultCurrencyField
                      value={calculateTotalAmount()}
                      disabled={true}
                    />
                  </Grid>
                  {/* Bilgilendirme Mesajı */}
                  {advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.OPEN ? (
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                    >
                      <AlertBox
                        severity={"info"}
                        title={"Bilgilendirme"}
                        message={`1 Mart 2001 tarihinden itibaren yürürlüğe giren tebliğ gereğince yurtiçi taşıma işleminde %20 oranında tevkifat uygulanır.`}
                      />

                      <AlertBox
                        severity={"info"}
                        title={"Bilgilendirme"}
                        message={`Teklifinizin kabul edilmesi ve ödemenin yapılması durumunda taşıma sonunda sistemimize tanımlı hesabınıza ${calculateNetAmount()} TL aktarılacak olup, fatura düzenlemeniz gereken tutar ise ${calculateTotalAmount()} TL'dir. 
                        Ayrıca en az ${
                          process.env.REACT_APP_MIN_OFFER_LIMIT || "1000"
                        } TL ve en fazla ${
                          process.env.REACT_APP_MAX_OFFER_LIMIT || "99999"
                        } TL tutarında teklifler geçerlidir.`}
                      />
                    </Grid>
                  ) : null}
                  {/* {checkCondition() ? (
                    <>
                      <Grid item xs={12} display="flex" gap="24px">
                        <Grid item xs={6}>
                          <DefaultButton
                            buttonTextSize={"18px"}
                            variant={"text"}
                            buttonColor={"#BE1010"}
                            buttonTextColor={"  #FFFFFF"}
                            buttonTextTransform={"none"}
                            buttonTextWeight={"600"}
                            buttonTextLineHeight={"21px"}
                            buttonMinHeight={48}
                            buttonRadius={"11px"}
                            fullWidth={true}
                            buttonText={"Reddet"}
                            buttonBorder={"1px solid #C4C4C4"}
                            onClick={() => handleRejectOffer()}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DefaultButton
                            buttonTextSize={"18px"}
                            variant={"contained"}
                            buttonColor={"#068813"}
                            buttonTextColor={"#FFFFFF"}
                            buttonTextTransform={"none"}
                            buttonTextWeight={"600"}
                            buttonTextLineHeight={"21px"}
                            buttonMinHeight={48}
                            buttonRadius={"11px"}
                            fullWidth={true}
                            buttonText={"Kabul Et"}
                            onClick={() => handleAcceptOffer()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} display="flex" gap="24px">
                      <Grid item xs={6}>
                        <DefaultButton
                          buttonTextSize={"18px"}
                          variant={"text"}
                          buttonColor={"#FFFFFF"}
                          buttonTextColor={"#000000"}
                          buttonTextTransform={"none"}
                          buttonTextWeight={"600"}
                          buttonTextLineHeight={"21px"}
                          buttonMinHeight={48}
                          buttonRadius={"11px"}
                          fullWidth={true}
                          buttonText={"Geri"}
                          buttonBorder={"1px solid #C4C4C4"}
                          onClick={() => {
                            navigate("/");
                          }}
                        />
                      </Grid>
                      {advertisement?.advertisementStatusId === 7 &&
                      offer?.ownerUserId === user?.id ? (
                        <Grid item xs={6}>
                          <DefaultButton
                            buttonTextSize={"18px"}
                            variant={"text"}
                            buttonColor={"#05C101"}
                            buttonTextColor={"#FFFFFF"}
                            buttonTextTransform={"none"}
                            buttonTextWeight={"600"}
                            buttonTextLineHeight={"21px"}
                            buttonMinHeight={48}
                            buttonRadius={"11px"}
                            fullWidth={true}
                            buttonText={"Ödeme Yap"}
                            buttonBorder={"1px solid #C4C4C4"}
                            isProgress={isLoadingPayment}
                            onClick={() => handleOfferPayment()}
                          />
                        </Grid>
                      ) : null}
                      {advertisement?.ownerUserId ===
                      user?.id ? null : advertisement?.advertisementStatusId ===
                        1 ? (
                        <Grid item xs={6}>
                          <DefaultButton
                            buttonTextSize={"18px"}
                            variant={"contained"}
                            buttonColor={"#084BBB"}
                            buttonTextColor={"#FFFFFF"}
                            buttonTextTransform={"none"}
                            buttonTextWeight={"600"}
                            buttonTextLineHeight={"21px"}
                            buttonMinHeight={48}
                            buttonRadius={"11px"}
                            fullWidth={true}
                            buttonText={"Teklif Ver"}
                            // onClick={() => navigate("/offer-success-message/vehicle")}
                            onClick={() => handleOffer()}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  )} */}
                </>
              )}

              <Grid container spacing={3}>
                {/* Simsarlık Sözleşmesi */}
                {advertisement?.ownerUserId === user?.id ||
                offer?.ownerUserId ===
                  user?.id ? null : advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.OPEN ? (
                  <Grid item xs={12}>
                    <DefaultCheckbox
                      value={approveAgreement}
                      label={
                        <span>
                          <StyledAgreementLabel
                            onClick={() => {
                              if (!showAgreement) {
                                handleShowJobbingAgreement();
                              }
                            }}
                          >
                            Simsarlık Sözleşmesi
                          </StyledAgreementLabel>
                          'ni okudum ve kabul ediyorum.
                        </span>
                      }
                      onChange={(e) => {
                        setApproveAgreement(e?.target?.checked);
                      }}
                      error={isSubmit && !approveAgreement}
                    />
                  </Grid>
                ) : null}

                {/* Mesafeli Yük Taşıma Sözleşmesi*/}
                {!checkCondition() &&
                advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.PENDING_PAYMENT &&
                offer?.ownerUserId === user?.id ? (
                  <Grid item xs={12}>
                    <DefaultCheckbox
                      value={approveAgreement}
                      label={
                        <span>
                          <StyledAgreementLabel
                            onClick={() => {
                              if (!showAgreement) {
                                handleShowCarriageAgreement();
                              }
                            }}
                          >
                            Mesafeli Yük Taşıma Sözleşmesi
                          </StyledAgreementLabel>
                          'ni okudum ve kabul ediyorum.
                        </span>
                      }
                      onChange={(e) => {
                        setApproveAgreement(e?.target?.checked);
                      }}
                      error={isSubmit && !approveAgreement}
                    />
                  </Grid>
                ) : null}
                {showAlert ? (
                  <Grid item xs={12}>
                    <AlertBox
                      severity={showAlertDetail?.severity}
                      title={showAlertDetail?.title}
                      message={showAlertDetail?.message}
                    />
                  </Grid>
                ) : null}

                {/* Geri Butonu */}
                <Grid item xs={6}>
                  <DefaultButton
                    buttonTextSize={"18px"}
                    variant={"text"}
                    buttonColor={"#FFFFFF"}
                    buttonTextColor={"#000000"}
                    buttonTextTransform={"none"}
                    buttonTextWeight={"600"}
                    buttonTextLineHeight={"21px"}
                    buttonMinHeight={48}
                    buttonRadius={"11px"}
                    fullWidth={true}
                    buttonText={"Geri"}
                    buttonBorder={"1px solid #C4C4C4"}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </Grid>

                {/* İlanı İptal Et Butonu */}
                {showAdCancel &&
                checkAmIAdOwner() &&
                advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.OPEN ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"text"}
                      buttonColor={"#E9101D"}
                      buttonTextColor={"#fff"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"İlanı İptal Et"}
                      buttonBorder={"1px solid #C4C4C4"}
                      isProgress={isLoadingCancel}
                      onClick={() => handleCancelAd()}
                    />
                  </Grid>
                ) : null}

                {/* Teklif Ver Butonu */}
                {advertisement?.ownerUserId === user?.id ||
                offer?.ownerUserId ===
                  user?.id ? null : advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.OPEN ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"contained"}
                      buttonColor={"#084BBB"}
                      buttonTextColor={"#FFFFFF"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"Teklif Ver"}
                      onClick={() => handleOffer()}
                      disabled={
                        offerAmount <
                          +(process.env.REACT_APP_MIN_OFFER_LIMIT || "1000") ||
                        offerAmount >
                          +(process.env.REACT_APP_MAX_OFFER_LIMIT || "99999")
                      }
                    />
                  </Grid>
                ) : null}

                {/* Teklifimi İptal Et Butonu */}
                {user.id === offer?.ownerUserId &&
                (offer?.offerStatusId === OfferStatusType.NEW ||
                  offer?.offerStatusId === OfferStatusType.ACCEPTED) &&
                (advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.OPEN ||
                  advertisement?.advertisementStatusId ===
                    AdvertisementStatusType.PENDING_PAYMENT) ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"text"}
                      buttonColor={"#E9101D"}
                      buttonTextColor={"#fff"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"Teklifimi İptal Et"}
                      buttonBorder={"1px solid #C4C4C4"}
                      isProgress={isLoadingCancel}
                      onClick={() => handleCancelMyOffer()}
                    />
                  </Grid>
                ) : null}

                {/* Teklifi Reddet Butonu */}
                {checkCondition() &&
                offer?.offerStatusId === OfferStatusType.NEW ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"text"}
                      buttonColor={"#BE1010"}
                      buttonTextColor={"  #FFFFFF"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"Teklifi Reddet"}
                      buttonBorder={"1px solid #C4C4C4"}
                      onClick={() => handleRejectOffer()}
                    />
                  </Grid>
                ) : null}

                {/* Teklifi Kabul Et Butonu */}
                {checkCondition() &&
                offer?.offerStatusId === OfferStatusType.NEW ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"contained"}
                      buttonColor={"#068813"}
                      buttonTextColor={"#FFFFFF"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"Teklifi Kabul Et"}
                      onClick={() => handleAcceptOffer()}
                    />
                  </Grid>
                ) : null}

                {/* Ödeme Yap */}
                {!checkCondition() &&
                advertisement?.advertisementStatusId ===
                  AdvertisementStatusType.PENDING_PAYMENT &&
                offer?.ownerUserId === user?.id ? (
                  <Grid item xs={6}>
                    <DefaultButton
                      buttonTextSize={"18px"}
                      variant={"text"}
                      buttonColor={"#05C101"}
                      buttonTextColor={"#FFFFFF"}
                      buttonTextTransform={"none"}
                      buttonTextWeight={"600"}
                      buttonTextLineHeight={"21px"}
                      buttonMinHeight={48}
                      buttonRadius={"11px"}
                      fullWidth={true}
                      buttonText={"Ödeme Yap"}
                      buttonBorder={"1px solid #C4C4C4"}
                      isProgress={isLoadingPayment}
                      onClick={() => handleOfferPayment()}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
        </StyledGridContainer>
      </ContentWrapper>
    </>
  );
};

export default OfferVehicleDetail;
