import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// eslint-disable-next-line no-unused-vars
import "dayjs/locale/tr";
import React, { useState } from "react";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  width: 100%;
  min-height: 56px;

  & .MuiInputLabel-formControl {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }

  & .MuiOutlinedInput-root {
    border-radius: 8px !important;

    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    gap: 10px;
  }

  & .MuiFormLabel-filled {
    margin-top: 15px !important;
  }
  /*   
  & .Mui-focused {
    margin-top: 15px !important;
  } */

  fieldset {
    border: 1px solid rgba(229, 229, 229, 0.53) !important;
    color: rgba(229, 229, 229, 0.53) !important;
  }
`;

const DefaultDatepicker = ({
  id,
  label,
  error,
  value,
  onChange,
  backgroundColor = "#e5e5e587",
  disablePast,
}) => {
  const [inputValue, setValue] = useState(value ?? null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
      <DatePicker
        disablePast={disablePast}
        inputFormat="DD.MM.YYYY"
        mask="__.__.____"
        id={id}
        label={label}
        value={inputValue}
        onChange={(newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        renderInput={(params) => (
          <StyledTextField
            className={inputValue ? "filledWithValue" : ""}
            style={{
              backgroundColor: inputValue ? "#FFFFFF" : backgroundColor,
              border: error
                ? "1px solid red"
                : inputValue
                ? "1px solid rgba(8, 75, 187, 1)"
                : backgroundColor,
              borderRadius: "8px",
            }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DefaultDatepicker;
