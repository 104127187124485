import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

const DefaultCheckbox = ({ label, defaultChecked, error, onChange, value }) => {
  const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label {
      font-family: "Work Sans" !important;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #3a3a3a !important;
    }
  `;

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e)}
          style={{
            color: error ? "red" : "",
          }}
          // defaultChecked={defaultChecked}
        />
      }
      label={
        <span
          style={{
            color: error ? "red" : "",
          }}
        >
          {label}
        </span>
      }
    />
  );
};

export default DefaultCheckbox;
