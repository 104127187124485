export const convertStatus = (ad) => {
  switch (ad?.advertisementStatusId) {
    case 1:
      return <span style={{ color: "green" }}>{ad?.advertisementStatus}</span>;
    case 2:
      return <span style={{ color: "gray" }}>{ad?.advertisementStatus}</span>;
    case 3:
      return <span style={{ color: "red" }}>{ad?.advertisementStatus}</span>;
    case 4:
      return <span style={{ color: "orange" }}>{ad?.advertisementStatus}</span>;
    case 6:
      return <span style={{ color: "red" }}>{ad?.advertisementStatus}</span>;
    case 7:
      return <span style={{ color: "purple" }}>{ad?.advertisementStatus}</span>;

    default:
      return <span>-</span>;
  }
};

export const convertStatusWithId = (statusId) => {
  switch (statusId) {
    case 1:
      return <span style={{ color: "green" }}>Açık</span>;
    case 2:
      return <span style={{ color: "gray" }}>Kapalı</span>;
    case 3:
      return <span style={{ color: "red" }}>İptal</span>;
    case 4:
      return <span style={{ color: "orange" }}>İşlemde</span>;
    case 6:
      return <span style={{ color: "red" }}>İptal Talebi</span>;
    case 7:
      return <span style={{ color: "purple" }}>Ödeme</span>;

    default:
      return <span>-</span>;
  }
};
