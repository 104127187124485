import React from "react";

function NavbarLogisticIcon() {
  return (
    <svg
      width="132"
      height="88"
      viewBox="0 0 132 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8747 55.9627H10.7606V73.7898H33.8747V55.9627Z"
        fill="#EBEBEB"
      />
      <path
        d="M28.0843 73.6631C28.0579 73.6631 28.0368 69.6907 28.0368 64.7891C28.0368 59.8875 28.0579 55.9135 28.0843 55.9135C28.1107 55.9135 28.1301 59.8875 28.1301 64.7891C28.1301 69.6907 28.109 73.6631 28.0843 73.6631Z"
        fill="#E0E0E0"
      />
      <path
        d="M31.9158 69.7312H30.1435V71.7341H31.9158V69.7312Z"
        fill="#FAFAFA"
      />
      <path
        d="M20.4388 56.0085H17.8129V59.8118H20.4388V56.0085Z"
        fill="#E0E0E0"
      />
      <path
        d="M20.4388 69.9319H17.8129V73.7352H20.4388V69.9319Z"
        fill="#E0E0E0"
      />
      <path
        d="M37.4897 38.1762H14.3757V56.0032H37.4897V38.1762Z"
        fill="#EBEBEB"
      />
      <path
        d="M31.6976 55.8765C31.673 55.8765 31.6519 51.9042 31.6519 47.0026C31.6519 42.101 31.673 38.1287 31.6976 38.1287C31.7223 38.1287 31.7434 42.101 31.7434 47.0026C31.7434 51.9042 31.724 55.8765 31.6976 55.8765Z"
        fill="#E0E0E0"
      />
      <path
        d="M35.5291 51.9446H33.7568V53.9475H35.5291V51.9446Z"
        fill="#FAFAFA"
      />
      <path
        d="M24.0521 38.2219H21.4262V42.0253H24.0521V38.2219Z"
        fill="#E0E0E0"
      />
      <path
        d="M24.0521 52.147H21.4262V55.9504H24.0521V52.147Z"
        fill="#E0E0E0"
      />
      <path
        d="M35.9005 23.8885H17.3149V38.2219H35.9005V23.8885Z"
        fill="#F5F5F5"
      />
      <path
        d="M31.2435 38.1216C31.2241 38.1216 31.2065 34.9254 31.2065 30.9848C31.2065 27.0442 31.2241 23.8498 31.2435 23.8498C31.2629 23.8498 31.2805 27.0442 31.2805 30.9848C31.2805 34.9254 31.2646 38.1216 31.2435 38.1216Z"
        fill="#E0E0E0"
      />
      <path
        d="M34.3253 34.9589H32.8997V36.5693H34.3253V34.9589Z"
        fill="#FAFAFA"
      />
      <path
        d="M25.0958 23.9254H22.9856V26.9843H25.0958V23.9254Z"
        fill="#E0E0E0"
      />
      <path
        d="M25.0958 35.1208H22.9856V38.1797H25.0958V35.1208Z"
        fill="#E0E0E0"
      />
      <path
        d="M118.863 33.8395H101.406V47.3035H118.863V33.8395Z"
        fill="#F5F5F5"
      />
      <path
        d="M114.49 47.2102C114.47 47.2102 114.455 44.2077 114.455 40.5064C114.455 36.8051 114.47 33.8043 114.49 33.8043C114.509 33.8043 114.523 36.8051 114.523 40.5064C114.523 44.2077 114.509 47.2102 114.49 47.2102Z"
        fill="#E0E0E0"
      />
      <path
        d="M117.381 44.2394H116.044V45.753H117.381V44.2394Z"
        fill="#FAFAFA"
      />
      <path
        d="M108.715 33.8747H106.732V36.747H108.715V33.8747Z"
        fill="#E0E0E0"
      />
      <path
        d="M108.715 44.3925H106.732V47.2648H108.715V44.3925Z"
        fill="#E0E0E0"
      />
      <path
        d="M124.714 62.7845H104.593V78.3042H124.714V62.7845Z"
        fill="#EBEBEB"
      />
      <path
        d="M119.68 78.1933C119.659 78.1933 119.641 74.7349 119.641 70.4687C119.641 66.2024 119.659 62.7422 119.68 62.7422C119.701 62.7422 119.72 66.2007 119.72 70.4687C119.72 74.7367 119.696 78.1933 119.68 78.1933Z"
        fill="#E0E0E0"
      />
      <path
        d="M123.008 74.7701H121.466V76.5142H123.008V74.7701Z"
        fill="#FAFAFA"
      />
      <path
        d="M113.018 62.8232H110.732V66.1338H113.018V62.8232Z"
        fill="#E0E0E0"
      />
      <path
        d="M113.018 74.9461H110.732V78.2566H113.018V74.9461Z"
        fill="#E0E0E0"
      />
      <path
        d="M121.935 47.3053H101.814V62.825H121.935V47.3053Z"
        fill="#EBEBEB"
      />
      <path
        d="M116.894 62.7141C116.871 62.7141 116.853 59.2557 116.853 54.9895C116.853 50.7232 116.871 47.2631 116.894 47.2631C116.917 47.2631 116.934 50.7215 116.934 54.9895C116.934 59.2575 116.917 62.7141 116.894 62.7141Z"
        fill="#E0E0E0"
      />
      <path
        d="M120.229 59.2909H118.687V61.0351H120.229V59.2909Z"
        fill="#FAFAFA"
      />
      <path
        d="M110.239 47.344H107.953V50.6546H110.239V47.344Z"
        fill="#E0E0E0"
      />
      <path
        d="M110.239 59.4669H107.953V62.7774H110.239V59.4669Z"
        fill="#E0E0E0"
      />
      <path d="M84.8267 34.056H67.3693V47.52H84.8267V34.056Z" fill="#F5F5F5" />
      <path
        d="M80.4531 47.4267C80.4338 47.4267 80.4197 44.4242 80.4197 40.7229C80.4197 37.0216 80.4338 34.0208 80.4531 34.0208C80.4725 34.0208 80.4883 37.0128 80.4883 40.7229C80.4883 44.433 80.4725 47.4267 80.4531 47.4267Z"
        fill="#E0E0E0"
      />
      <path
        d="M83.3466 44.4558H82.009V45.9694H83.3466V44.4558Z"
        fill="#FAFAFA"
      />
      <path
        d="M74.6785 34.0912H72.695V36.9635H74.6785V34.0912Z"
        fill="#E0E0E0"
      />
      <path d="M74.6785 44.609H72.695V47.4813H74.6785V44.609Z" fill="#E0E0E0" />
      <path
        d="M86.3755 20.6272H68.9181V34.0912H86.3755V20.6272Z"
        fill="#EBEBEB"
      />
      <path
        d="M82.0019 33.9962C81.9826 33.9962 81.9667 31.0042 81.9667 27.2941C81.9667 23.584 81.9826 20.5902 82.0019 20.5902C82.0213 20.5902 82.0371 23.5822 82.0371 27.2941C82.0371 31.0059 82.016 33.9962 82.0019 33.9962Z"
        fill="#E0E0E0"
      />
      <path
        d="M84.8953 31.027H83.5577V32.5406H84.8953V31.027Z"
        fill="#FAFAFA"
      />
      <path
        d="M76.2274 20.6606H74.2438V23.533H76.2274V20.6606Z"
        fill="#E0E0E0"
      />
      <path
        d="M76.2274 31.1784H74.2438V34.0507H76.2274V31.1784Z"
        fill="#E0E0E0"
      />
      <path
        d="M85.7208 11.2816H73.23V20.9159H85.7208V11.2816Z"
        fill="#EBEBEB"
      />
      <path
        d="M82.5932 20.849C82.5792 20.849 82.5686 18.7018 82.5686 16.053C82.5686 13.4042 82.5792 11.264 82.5932 11.264C82.6073 11.264 82.6179 13.4112 82.6179 16.06C82.6179 18.7088 82.6056 20.849 82.5932 20.849Z"
        fill="#E0E0E0"
      />
      <path d="M84.663 18.7246H83.7056V19.807H84.663V18.7246Z" fill="#FAFAFA" />
      <path
        d="M78.4608 11.308H77.0422V13.3637H78.4608V11.308Z"
        fill="#E0E0E0"
      />
      <path
        d="M78.4608 18.832H77.0422V20.8877H78.4608V18.832Z"
        fill="#E0E0E0"
      />
      <path
        d="M90.6787 63.001H70.5584V78.5207H90.6787V63.001Z"
        fill="#EBEBEB"
      />
      <path
        d="M85.6381 78.4098C85.6153 78.4098 85.5977 74.9514 85.5977 70.6851C85.5977 66.4189 85.6153 62.9587 85.6381 62.9587C85.661 62.9587 85.6769 66.4171 85.6769 70.6851C85.6769 74.9531 85.6593 78.4098 85.6381 78.4098Z"
        fill="#E0E0E0"
      />
      <path
        d="M88.9716 74.9866H87.4298V76.7307H88.9716V74.9866Z"
        fill="#FAFAFA"
      />
      <path
        d="M78.9817 63.0397H76.6955V66.3502H78.9817V63.0397Z"
        fill="#E0E0E0"
      />
      <path
        d="M78.9817 75.1626H76.6955V78.4731H78.9817V75.1626Z"
        fill="#E0E0E0"
      />
      <path
        d="M87.8979 47.5218H67.7776V63.0414H87.8979V47.5218Z"
        fill="#EBEBEB"
      />
      <path
        d="M82.859 62.9306C82.8361 62.9306 82.8185 59.4722 82.8185 55.2059C82.8185 50.9397 82.8361 47.4795 82.859 47.4795C82.8819 47.4795 82.8977 50.9379 82.8977 55.2059C82.8977 59.4739 82.8801 62.9306 82.859 62.9306Z"
        fill="#E0E0E0"
      />
      <path
        d="M86.1925 59.5074H84.6508V61.2515H86.1925V59.5074Z"
        fill="#FAFAFA"
      />
      <path
        d="M76.2027 47.5605H73.9165V50.871H76.2027V47.5605Z"
        fill="#E0E0E0"
      />
      <path
        d="M76.2027 59.6833H73.9165V62.9939H76.2027V59.6833Z"
        fill="#E0E0E0"
      />
      <path
        d="M44.1408 75.4459V73.7898H10.2608C10.1408 73.7898 10.0258 73.8374 9.94095 73.9222C9.85613 74.0071 9.80847 74.1221 9.80847 74.2421V74.9936C9.80847 75.1136 9.85613 75.2286 9.94095 75.3134C10.0258 75.3983 10.1408 75.4459 10.2608 75.4459H11.2042V76.208C11.2042 76.2665 11.1926 76.3244 11.1703 76.3784C11.1479 76.4324 11.1151 76.4815 11.0737 76.5229C11.0324 76.5642 10.9833 76.597 10.9293 76.6194C10.8752 76.6418 10.8173 76.6533 10.7589 76.6533H10.2538C10.1375 76.6532 10.0258 76.6987 9.94257 76.7799C9.85936 76.8612 9.81123 76.9717 9.80847 77.088V77.8536C9.80847 77.9717 9.85539 78.085 9.93889 78.1685C10.0224 78.252 10.1357 78.2989 10.2538 78.2989H44.1408V76.6427H36.2437V75.4424L44.1408 75.4459ZM19.3248 75.4459H21.3558V76.6462H19.3248V75.4459ZM17.9714 76.6462H15.9421V75.4459H17.9714V76.6462ZM22.704 75.4459H24.7333V76.6462H22.704V75.4459ZM26.0867 75.4459H28.1178V76.6462H26.092L26.0867 75.4459ZM29.4712 75.4459H31.504V76.6462H29.4765L29.4712 75.4459ZM12.5523 75.4459H14.5886V76.6462H12.5576L12.5523 75.4459ZM34.885 76.6462H32.8592V75.4459H34.8902L34.885 76.6462Z"
        fill="#E0E0E0"
      />
      <path d="M42.9986 40.92H24.6629V55.0633H42.9986V40.92Z" fill="#407BFF" />
      <path
        opacity="0.3"
        d="M42.9986 40.92H24.6629V55.0633H42.9986V40.92Z"
        fill="black"
      />
      <path
        d="M38.405 54.963C38.3891 54.963 38.3751 51.8109 38.3751 47.923C38.3751 44.0352 38.3891 40.883 38.405 40.883C38.4208 40.883 38.4349 44.0352 38.4349 47.923C38.4349 51.8109 38.4208 54.963 38.405 54.963Z"
        fill="#263238"
      />
      <path
        d="M41.4445 51.8443H40.0383V53.4336H41.4445V51.8443Z"
        fill="#F5F5F5"
      />
      <path
        d="M32.3382 40.9569H30.2562V43.9753H32.3382V40.9569Z"
        fill="#263238"
      />
      <path
        d="M32.3382 52.0027H30.2562V55.0211H32.3382V52.0027Z"
        fill="#263238"
      />
      <path
        d="M42.8401 40.92H42.7926H42.6553H42.1273L40.179 40.9288H33.7533H27.3257L25.3792 40.9182H24.8512H24.7139H24.6664H24.7139H24.8512H25.3792L27.3257 40.9094H33.7533H40.179L42.1273 40.9182H42.6553H42.7926L42.8401 40.92Z"
        fill="#263238"
      />
      <path
        d="M58.2631 40.9024H39.9274V55.0457H58.2631V40.9024Z"
        fill="#407BFF"
      />
      <path
        d="M53.6694 54.9454C53.6536 54.9454 53.6395 51.7933 53.6395 47.9054C53.6395 44.0176 53.6536 40.8654 53.6694 40.8654C53.6853 40.8654 53.6994 44.0158 53.6994 47.9054C53.6994 51.795 53.68 54.9454 53.6694 54.9454Z"
        fill="#263238"
      />
      <path d="M56.709 51.8267H55.3027V53.416H56.709V51.8267Z" fill="#F5F5F5" />
      <path
        opacity="0.5"
        d="M47.6027 40.9394H45.5206V43.9578H47.6027V40.9394Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M47.6027 51.9851H45.5206V55.0035H47.6027V51.9851Z"
        fill="white"
      />
      <path
        d="M58.1029 40.9024H58.0571H57.9181H57.3901L55.4435 40.9112H49.0178H42.5903L40.6419 40.9024H40.1139H39.9749H39.9291H39.9749H40.1139H40.6419L42.5903 40.8918L49.0178 40.883L55.4435 40.8918L57.3901 40.9024H57.9181H58.0571H58.1029Z"
        fill="#263238"
      />
      <path d="M59.2927 26.759H40.957V40.9024H59.2927V26.759Z" fill="#407BFF" />
      <path
        d="M54.6991 40.8021C54.6815 40.8021 54.6691 37.6499 54.6691 33.7621C54.6691 29.8742 54.6815 26.7221 54.6991 26.7221C54.7167 26.7221 54.729 29.8725 54.729 33.7621C54.729 37.6517 54.7149 40.8021 54.6991 40.8021Z"
        fill="#263238"
      />
      <path
        d="M57.7386 37.6833H56.3323V39.2726H57.7386V37.6833Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.6323 26.796H46.5502V29.8144H48.6323V26.796Z"
        fill="#263238"
      />
      <path
        d="M48.6323 37.8417H46.5502V40.8601H48.6323V37.8417Z"
        fill="#263238"
      />
      <path
        d="M59.136 26.752C59.1209 26.7546 59.1054 26.7546 59.0903 26.752H58.9512H58.4232H56.4767L50.0509 26.7608L43.6234 26.752H41.6751H41.1471H41.008C40.9929 26.7546 40.9774 26.7546 40.9623 26.752H41.008H41.1471H41.6751L43.6234 26.7414H50.0509H56.4767L58.4232 26.752H58.9512H59.0903H59.136Z"
        fill="#263238"
      />
      <path
        d="M57.332 70.8101V69.1539H23.4538C23.3336 69.1539 23.2184 69.2015 23.1333 69.2863C23.0482 69.371 23.0002 69.4861 22.9997 69.6062V70.3577C23.0002 70.4779 23.0482 70.5929 23.1333 70.6777C23.2184 70.7625 23.3336 70.8101 23.4538 70.8101H24.3971V71.5633C24.3971 71.6819 24.35 71.7956 24.2662 71.8794C24.1824 71.9633 24.0687 72.0104 23.9501 72.0104H23.4467C23.3285 72.0104 23.215 72.0572 23.1313 72.1407C23.0475 72.2241 23.0002 72.3374 22.9997 72.4557V73.216C23.0002 73.3342 23.0475 73.4475 23.1313 73.5309C23.215 73.6144 23.3285 73.6613 23.4467 73.6613H57.332V72.0051H49.4349V70.8048L57.332 70.8101ZM32.516 70.8101H34.5453V72.0104H32.5178L32.516 70.8101ZM31.1626 72.0104H29.1333V70.8101H31.1643L31.1626 72.0104ZM35.904 70.8101H37.9351V72.0104H35.904V70.8101ZM39.2885 70.8101H41.3178V72.0104H39.285L39.2885 70.8101ZM42.6712 70.8101H44.704V72.0104H42.6677L42.6712 70.8101ZM25.7541 70.8101H27.7834V72.0104H25.7506L25.7541 70.8101ZM48.085 72.0104H46.0504V70.8101H48.0815L48.085 72.0104Z"
        fill="#455A64"
      />
      <path
        d="M84.6402 42.277C84.6957 42.2203 84.7393 42.153 84.7685 42.0793C84.7977 42.0055 84.8119 41.9267 84.8103 41.8473C84.8086 41.768 84.7912 41.6898 84.759 41.6173C84.7267 41.5448 84.6804 41.4795 84.6226 41.4251L78.9079 36.0237C78.7937 35.9165 78.643 35.8569 78.4864 35.8569C78.3298 35.8569 78.179 35.9165 78.0648 36.0237V36.0237C78.0048 36.0795 77.9565 36.1467 77.9227 36.2215C77.889 36.2962 77.8705 36.3769 77.8684 36.4589C77.8663 36.5408 77.8805 36.6224 77.9103 36.6988C77.9401 36.7752 77.9849 36.8448 78.042 36.9037L80.3212 39.3008L79.1349 40.443L78.5172 39.952L74.2069 44.8888L71.9031 42.673L69.1258 50.8411L69.6767 61.9291H76.0655L75.6184 48.752L79.904 42.6483L79.9375 41.1963L81.1695 39.9344L83.8253 42.3069C83.9405 42.4094 84.0908 42.4636 84.2449 42.458C84.399 42.4524 84.5451 42.3876 84.6525 42.277H84.6402Z"
        fill="#263238"
      />
      <path
        d="M67.408 63.983C67.3834 63.983 67.3622 53.8666 67.3622 41.3899C67.3622 28.9133 67.3834 18.7933 67.408 18.7933C67.4326 18.7933 67.4538 28.908 67.4538 41.3899C67.4538 53.8718 67.4274 63.983 67.408 63.983Z"
        fill="#263238"
      />
      <path
        d="M81.708 61.1336L85.5941 50.336L84.1333 49.7446V47.659L94.7584 47.4953C95.0089 47.4905 95.249 47.3949 95.4342 47.2262C95.6194 47.0575 95.737 46.8273 95.7651 46.5784L97.328 32.7061C97.363 32.3958 97.511 32.1094 97.7438 31.9013C97.9765 31.6932 98.2777 31.5781 98.5899 31.5779C98.7611 31.5778 98.9305 31.6123 99.0881 31.6793C99.2456 31.7463 99.3879 31.8446 99.5064 31.9681C99.625 32.0915 99.7173 32.2377 99.7779 32.3978C99.8385 32.5579 99.866 32.7286 99.8589 32.8997L99.264 47.1944L104.812 47.2243L105 49.8309L88.5931 50.4733L84.3146 60.9787L81.708 61.1336Z"
        fill="#455A64"
      />
      <path
        d="M84.1526 60.8696L70.3401 60.9787V61.8992L76.0619 61.8253L85.1752 61.7549L107.27 58.6362L104.766 49.104H89.8057C89.5471 49.1002 89.2915 49.1594 89.0609 49.2765C88.8303 49.3937 88.6317 49.5652 88.4822 49.7763L84.1526 60.8696Z"
        fill="#263238"
      />
      <path
        d="M77.5333 21.0074L67.5013 53.027L67.5717 61.9344H70.752V53.0834L78.9782 21.0936L101.267 20.9686L105.53 54.2907H108.045L102.858 21.2027C102.776 20.7699 102.546 20.3792 102.207 20.098C101.868 19.8168 101.442 19.6629 101.001 19.6627H79.3426C78.9365 19.6625 78.5412 19.7933 78.2153 20.0355C77.8894 20.2777 77.6502 20.6185 77.5333 21.0074Z"
        fill="#455A64"
      />
      <path
        d="M65.6902 72.2216V63.668L67.5629 61.9344L85.1629 61.7073L88.3309 59.1307C88.9209 58.6516 89.647 58.3707 90.4059 58.3281L93.7957 58.1363C93.7957 58.1363 95.3163 57.0891 96.7877 56.0507C98.6512 54.7302 100.879 54.0206 103.162 54.0197H112.918C113.272 54.0197 113.612 54.1604 113.863 54.4109C114.113 54.6614 114.254 55.0012 114.254 55.3555V72.2216H65.6902Z"
        fill="#407BFF"
      />
      <path
        d="M114.25 69.4654H65.5178V72.2779H114.25V69.4654Z"
        fill="#263238"
      />
      <path
        d="M68.0398 72.1899C68.0222 73.3818 68.3584 74.5521 69.006 75.5528C69.6535 76.5535 70.5833 77.3398 71.6777 77.8121C72.7721 78.2844 73.982 78.4215 75.1544 78.2061C76.3267 77.9907 77.4089 77.4325 78.2639 76.602C79.119 75.7716 79.7086 74.7062 79.9581 73.5406C80.2077 72.3751 80.1059 71.1617 79.6658 70.054C79.2257 68.9462 78.467 67.9939 77.4856 67.3174C76.5042 66.6409 75.3442 66.2706 74.1523 66.2534C72.5546 66.2304 71.0132 66.8428 69.8669 67.9561C68.7207 69.0693 68.0635 70.5922 68.0398 72.1899V72.1899Z"
        fill="#263238"
      />
      <path
        d="M68.0416 72.1758C68.0416 72.1758 68.0416 72.1846 68.0416 72.1899C68.0325 73.7789 68.6513 75.3071 69.7635 76.4421C70.8756 77.577 72.3909 78.2268 73.9797 78.25C75.5686 78.2732 77.1023 77.668 78.247 76.566C79.3918 75.464 80.055 73.9544 80.0923 72.3659C80.0923 72.3659 80.0923 72.3553 80.0923 72.3501L68.0416 72.1758Z"
        fill="#263238"
      />
      <path
        d="M69.8351 72.2163C69.8244 73.0511 70.0613 73.8703 70.516 74.5706C70.9706 75.2708 71.6225 75.8206 72.3894 76.1505C73.1564 76.4804 74.0038 76.5757 74.8249 76.4243C75.6459 76.2728 76.4036 75.8815 77.0024 75.2997C77.6011 74.7179 78.014 73.9717 78.189 73.1554C78.3639 72.339 78.293 71.4892 77.9852 70.7131C77.6774 69.9371 77.1466 69.2696 76.4597 68.7951C75.7728 68.3205 74.9607 68.0601 74.1259 68.0469C73.5702 68.038 73.0182 68.1389 72.5015 68.3438C71.9848 68.5487 71.5137 68.8536 71.1151 69.2409C70.7165 69.6283 70.3982 70.0905 70.1785 70.6011C69.9589 71.1116 69.8422 71.6605 69.8351 72.2163V72.2163Z"
        fill="#FAFAFA"
      />
      <path
        d="M74.1259 68.0469C73.5702 68.038 73.0181 68.1389 72.5015 68.3438C71.9848 68.5487 71.5137 68.8536 71.115 69.2409C70.7164 69.6283 70.3982 70.0905 70.1785 70.6011C69.9589 71.1116 69.8421 71.6605 69.835 72.2163L78.2953 72.3377C78.3042 71.782 78.2033 71.23 77.9984 70.7133C77.7935 70.1966 77.4886 69.7255 77.1013 69.3269C76.7139 68.9283 76.2517 68.61 75.7411 68.3903C75.2306 68.1707 74.6817 68.054 74.1259 68.0469V68.0469Z"
        fill="#E0E0E0"
      />
      <path
        d="M71.4877 72.2409C71.4815 72.7505 71.6265 73.2505 71.9044 73.6776C72.1823 74.1047 72.5806 74.4399 73.049 74.6407C73.5174 74.8414 74.0348 74.8988 74.5357 74.8055C75.0367 74.7123 75.4988 74.4725 75.8636 74.1166C76.2283 73.7608 76.4793 73.3047 76.5849 72.8062C76.6904 72.3076 76.6458 71.789 76.4566 71.3158C76.2674 70.8426 75.9422 70.4362 75.522 70.1479C75.1018 69.8595 74.6056 69.7023 74.096 69.696C73.4128 69.688 72.7543 69.9515 72.2652 70.4287C71.7762 70.9059 71.4965 71.5577 71.4877 72.2409V72.2409Z"
        fill="#263238"
      />
      <path
        d="M97.5269 72.1899C97.5093 73.3818 97.8455 74.5521 98.493 75.5528C99.1406 76.5535 100.07 77.3398 101.165 77.8121C102.259 78.2844 103.469 78.4215 104.641 78.2061C105.814 77.9907 106.896 77.4325 107.751 76.602C108.606 75.7716 109.196 74.7062 109.445 73.5406C109.695 72.3751 109.593 71.1617 109.153 70.054C108.713 68.9462 107.954 67.9939 106.973 67.3174C105.991 66.6409 104.831 66.2706 103.639 66.2534C102.042 66.2304 100.5 66.8428 99.354 67.9561C98.2077 69.0693 97.5505 70.5922 97.5269 72.1899V72.1899Z"
        fill="#263238"
      />
      <path
        d="M97.5287 72.1758C97.5287 72.1758 97.5287 72.1846 97.5287 72.1899C97.5196 73.7789 98.1384 75.3071 99.2505 76.4421C100.363 77.577 101.878 78.2268 103.467 78.25C105.056 78.2732 106.589 77.668 107.734 76.566C108.879 75.464 109.542 73.9544 109.579 72.3659C109.579 72.3659 109.579 72.3553 109.579 72.3501L97.5287 72.1758Z"
        fill="#263238"
      />
      <path
        d="M99.3221 72.2163C99.3114 73.0511 99.5484 73.8703 100.003 74.5706C100.458 75.2708 101.11 75.8206 101.877 76.1505C102.643 76.4804 103.491 76.5757 104.312 76.4243C105.133 76.2728 105.891 75.8815 106.489 75.2997C107.088 74.7179 107.501 73.9717 107.676 73.1554C107.851 72.339 107.78 71.4892 107.472 70.7131C107.164 69.9371 106.634 69.2696 105.947 68.7951C105.26 68.3205 104.448 68.0601 103.613 68.0469C103.057 68.038 102.505 68.1389 101.989 68.3438C101.472 68.5487 101.001 68.8536 100.602 69.2409C100.204 69.6283 99.8853 70.0905 99.6656 70.6011C99.446 71.1116 99.3292 71.6605 99.3221 72.2163V72.2163Z"
        fill="#FAFAFA"
      />
      <path
        d="M103.613 68.0469C103.057 68.038 102.505 68.1389 101.989 68.3438C101.472 68.5487 101.001 68.8536 100.602 69.2409C100.204 69.6283 99.8853 70.0905 99.6656 70.6011C99.446 71.1116 99.3293 71.6605 99.3221 72.2163L107.782 72.3377C107.791 71.782 107.69 71.23 107.485 70.7133C107.281 70.1966 106.976 69.7255 106.588 69.3269C106.201 68.9283 105.739 68.61 105.228 68.3903C104.718 68.1707 104.169 68.054 103.613 68.0469Z"
        fill="#E0E0E0"
      />
      <path
        d="M100.977 72.2409C100.97 72.7503 101.115 73.25 101.393 73.677C101.671 74.104 102.069 74.4392 102.537 74.6401C103.005 74.841 103.522 74.8987 104.023 74.8058C104.524 74.713 104.986 74.4737 105.351 74.1184C105.715 73.763 105.967 73.3075 106.073 72.8093C106.179 72.3111 106.135 71.7927 105.947 71.3194C105.759 70.8462 105.434 70.4394 105.015 70.1504C104.595 69.8615 104.099 69.7033 103.59 69.696C103.251 69.6911 102.915 69.7531 102.6 69.8784C102.285 70.0037 101.998 70.1899 101.755 70.4263C101.513 70.6627 101.319 70.9447 101.185 71.256C101.052 71.5674 100.981 71.9021 100.977 72.2409V72.2409Z"
        fill="#263238"
      />
      <path
        d="M111.394 67.9888C111.383 67.9755 111.373 67.9613 111.364 67.9465L111.287 67.8198L111.163 67.6121C111.11 67.517 111.051 67.4248 110.987 67.3358C110.601 66.7543 110.145 66.2224 109.629 65.7518C109.474 65.5952 109.285 65.4597 109.101 65.303C108.916 65.1464 108.705 65.0126 108.493 64.863C108.282 64.7134 108.05 64.5761 107.8 64.4547C107.679 64.3913 107.555 64.3227 107.429 64.2611L107.033 64.0851C106.471 63.8482 105.886 63.6712 105.287 63.5571C104.644 63.4335 103.989 63.3839 103.335 63.4093C102.067 63.48 100.831 63.8323 99.7164 64.4406L99.3397 64.6518C99.2183 64.7257 99.1039 64.8067 98.9877 64.8806C98.7501 65.0249 98.546 65.2009 98.3365 65.3541C98.1271 65.5072 97.9458 65.6814 97.7733 65.8486C97.6008 66.0158 97.4319 66.176 97.2911 66.3467C96.8221 66.8645 96.4184 67.4378 96.089 68.0539C96.0338 68.1482 95.9845 68.2458 95.9412 68.3461C95.9007 68.4305 95.8655 68.5027 95.8373 68.5643L95.7722 68.6981C95.7653 68.7134 95.7571 68.7282 95.7476 68.7421C95.7505 68.7255 95.7559 68.7095 95.7634 68.6945L95.8197 68.5573L95.9147 68.3355C95.9563 68.2336 96.0039 68.1343 96.0573 68.0381C96.3767 67.4117 96.7754 66.829 97.2436 66.3045C97.3826 66.1285 97.5568 65.9736 97.724 65.7976C97.8912 65.6216 98.0919 65.4667 98.289 65.2942C98.4861 65.1217 98.7044 64.9616 98.942 64.8137C99.0581 64.7381 99.1743 64.6571 99.294 64.5814L99.6724 64.3649C100.214 64.0715 100.784 63.8355 101.374 63.6609C102.01 63.4741 102.665 63.3608 103.326 63.323C104.608 63.2731 105.885 63.5106 107.063 64.0182L107.46 64.1942C107.589 64.2558 107.712 64.3262 107.835 64.3913C108.087 64.5145 108.307 64.6729 108.539 64.8067C108.772 64.9405 108.96 65.1024 109.148 65.2555C109.337 65.4086 109.521 65.5529 109.676 65.7113C110.191 66.1897 110.643 66.7321 111.021 67.3253C111.085 67.4158 111.143 67.5098 111.197 67.6069L111.311 67.8198C111.341 67.8744 111.364 67.9166 111.382 67.9518C111.399 67.987 111.396 67.987 111.394 67.9888Z"
        fill="#263238"
      />
      <path
        d="M81.9333 69.0976C81.923 69.0829 81.9147 69.0669 81.9087 69.0501C81.8946 69.0131 81.8752 68.9656 81.8506 68.9058L81.7555 68.6717C81.7133 68.5802 81.6658 68.4746 81.613 68.3566C81.3062 67.6915 80.9241 67.0638 80.4742 66.4858C79.818 65.6194 78.9807 64.9065 78.0208 64.3966C77.446 64.116 76.8364 63.9129 76.208 63.793C75.5349 63.6543 74.8483 63.5917 74.1611 63.6064C73.474 63.616 72.7902 63.704 72.1231 63.8686C71.5023 64.0242 70.9039 64.2577 70.3419 64.5638C69.382 65.0742 68.5237 65.756 67.8093 66.5755C67.3343 67.1309 66.9212 67.7364 66.5773 68.3813C66.4541 68.6101 66.3608 68.7896 66.3027 68.9093C66.2746 68.9674 66.2499 69.0131 66.2323 69.0483C66.2256 69.0647 66.2168 69.0801 66.2059 69.0941C66.2091 69.0768 66.215 69.0602 66.2235 69.0448L66.2869 68.9022C66.3397 68.7755 66.4277 68.5942 66.5456 68.3619C66.8788 67.7061 67.2863 67.0908 67.76 66.528C68.4735 65.6955 69.3352 65.0027 70.3015 64.4846C70.8689 64.1749 71.4733 63.9384 72.1002 63.7806C72.7742 63.6141 73.4651 63.5249 74.1594 63.5149C74.8539 63.5028 75.5476 63.5689 76.2274 63.712C76.8628 63.8348 77.479 64.042 78.0595 64.328C79.0273 64.8468 79.8686 65.5727 80.5235 66.4541C80.9715 67.039 81.3479 67.6753 81.6447 68.3496L81.7784 68.6682C81.8118 68.7614 81.8418 68.8442 81.8647 68.9058C81.8875 68.9674 81.9034 69.0149 81.9157 69.0536C81.9233 69.0675 81.9292 69.0823 81.9333 69.0976Z"
        fill="#263238"
      />
      <path
        d="M65.6779 69.1539H38.9647V72.2779H65.6779V69.1539Z"
        fill="#263238"
      />
      <path
        d="M64.9546 70.4792C64.9546 70.633 64.909 70.7834 64.8235 70.9114C64.738 71.0393 64.6165 71.139 64.4744 71.1979C64.3322 71.2568 64.1758 71.2722 64.0249 71.2422C63.874 71.2121 63.7354 71.138 63.6266 71.0293C63.5178 70.9205 63.4437 70.7818 63.4137 70.6309C63.3837 70.48 63.3991 70.3236 63.458 70.1815C63.5168 70.0393 63.6165 69.9178 63.7445 69.8324C63.8724 69.7469 64.0228 69.7013 64.1767 69.7013C64.383 69.7013 64.5808 69.7832 64.7267 69.9291C64.8726 70.075 64.9546 70.2729 64.9546 70.4792V70.4792Z"
        fill="#455A64"
      />
      <path
        d="M65.3277 19.4163H63.0978V63.712H65.3277V19.4163Z"
        fill="#455A64"
      />
      <path
        d="M97.0394 66.2358C97.0394 66.2605 93.28 66.2816 88.6442 66.2816C84.0083 66.2816 80.249 66.2605 80.249 66.2358C80.249 66.2112 84.0066 66.1901 88.6442 66.1901C93.2818 66.1901 97.0394 66.2112 97.0394 66.2358Z"
        fill="#263238"
      />
      <path
        d="M93.6179 65.5723C93.5915 65.5723 93.5721 63.9373 93.5721 61.9203C93.5721 59.9034 93.5915 58.2701 93.6179 58.2701C93.6443 58.2701 93.6637 59.9034 93.6637 61.9203C93.6637 63.9373 93.6425 65.5723 93.6179 65.5723Z"
        fill="#263238"
      />
      <path
        d="M67.4098 18.4008H62.8584V20.4318H67.4098V18.4008Z"
        fill="#263238"
      />
      <path
        d="M65.678 63.3459H62.7476V69.1926H65.678V63.3459Z"
        fill="#263238"
      />
      <path
        d="M58.8738 55.051H40.5381V69.1944H58.8738V55.051Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M58.8738 55.051H40.5381V69.1944H58.8738V55.051Z"
        fill="black"
      />
      <path
        d="M54.2802 69.0941C54.2643 69.0941 54.2502 65.9419 54.2502 62.0541C54.2502 58.1662 54.2643 55.0141 54.2802 55.0141C54.296 55.0141 54.3101 58.1662 54.3101 62.0541C54.3101 65.9419 54.296 69.0941 54.2802 69.0941Z"
        fill="#263238"
      />
      <path
        d="M57.3197 65.9753H55.9135V67.5646H57.3197V65.9753Z"
        fill="#F5F5F5"
      />
      <path
        d="M48.2134 55.088H46.1313V58.1064H48.2134V55.088Z"
        fill="#263238"
      />
      <path
        d="M48.2134 66.1338H46.1313V69.1522H48.2134V66.1338Z"
        fill="#263238"
      />
      <path
        d="M58.7136 55.051H58.6678H58.5288H58.0008L56.0525 55.0598L49.632 55.088H43.2045L41.2579 55.0792H40.7299H40.5909H40.5451C40.5603 55.0777 40.5756 55.0777 40.5909 55.0792H40.7299H41.2579L43.2045 55.0704L49.632 55.0616L56.0577 55.0704L58.0061 55.0792H58.5341H58.6731C58.6856 55.0684 58.6991 55.059 58.7136 55.051Z"
        fill="#263238"
      />
      <path
        d="M39.6405 55.051H21.3048V69.1944H39.6405V55.051Z"
        fill="#407BFF"
      />
      <path
        d="M35.0487 69.0941C35.0311 69.0941 35.0187 65.9419 35.0187 62.0541C35.0187 58.1662 35.0311 55.0141 35.0487 55.0141C35.0663 55.0141 35.0768 58.1662 35.0768 62.0541C35.0768 65.9419 35.0645 69.0941 35.0487 69.0941Z"
        fill="#263238"
      />
      <path
        d="M38.0882 65.9753H36.6819V67.5646H38.0882V65.9753Z"
        fill="#F5F5F5"
      />
      <path
        opacity="0.5"
        d="M28.9819 55.088H26.8998V58.1064H28.9819V55.088Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M28.9819 66.1338H26.8998V69.1522H28.9819V66.1338Z"
        fill="white"
      />
      <path
        d="M39.4821 55.051H39.4346H39.2973H38.7693L36.8228 55.0598H30.397H23.9695L22.0212 55.051H21.4932H21.3559H21.3083C21.3242 55.0496 21.3401 55.0496 21.3559 55.051H21.4932H22.0212L23.9695 55.0422L30.397 55.0334L36.8228 55.0422L38.7693 55.051H39.2973H39.4346C39.4504 55.0495 39.4663 55.0495 39.4821 55.051V55.051Z"
        fill="#263238"
      />
      <path
        d="M115.02 58.8949C115.965 59.1237 116.758 60.0829 116.743 61.0561C116.723 61.5437 116.543 62.0111 116.232 62.3869C115.921 62.7627 115.495 63.026 115.02 63.1365"
        fill="#407BFF"
      />
      <g opacity="0.6">
        <path
          d="M115.02 58.8949C115.965 59.1237 116.758 60.0829 116.743 61.0561C116.723 61.5437 116.543 62.0111 116.232 62.3869C115.921 62.7627 115.495 63.026 115.02 63.1365"
          fill="white"
        />
      </g>
      <path
        d="M115.058 58.3493H114.25V63.7014H115.058V58.3493Z"
        fill="#407BFF"
      />
      <path
        opacity="0.3"
        d="M115.058 58.3493H114.25V63.7014H115.058V58.3493Z"
        fill="black"
      />
      <path
        d="M76.6216 36.9019C76.5245 36.8266 76.4577 36.7188 76.4335 36.5983C76.4092 36.4777 76.4291 36.3525 76.4896 36.2455C76.6251 36.0096 76.7835 35.7315 76.8557 35.6154C76.9439 35.478 77.0244 35.3358 77.0968 35.1895C77.1191 35.102 77.1656 35.0226 77.231 34.9603C77.2964 34.8981 77.3779 34.8555 77.4664 34.8375C77.6814 34.8098 77.8994 34.8163 78.1123 34.8568L80.3141 35.332L81.3402 35.8389L80.3422 36.7189L79.7878 36.5429C79.6206 36.6872 78.9677 36.7629 78.7459 36.7365C78.4626 36.7013 77.8958 36.5165 77.5738 36.5781L77.2622 36.6221L77.4101 36.7453C77.6809 36.7258 77.9518 36.7812 78.1933 36.9055C78.2319 36.9315 78.2614 36.9688 78.2777 37.0124C78.294 37.056 78.2963 37.1036 78.2843 37.1485C78.2723 37.1935 78.2466 37.2335 78.2107 37.2632C78.1748 37.2928 78.1306 37.3105 78.0842 37.3138L77.3608 37.3613C77.165 37.2694 76.9767 37.1623 76.7976 37.041C76.7415 36.9914 76.6828 36.945 76.6216 36.9019V36.9019Z"
        fill="#FFBE9D"
      />
      <path
        d="M77.264 36.6291C77.2284 36.6534 77.1883 36.6702 77.1461 36.6784C77.0311 36.712 76.909 36.712 76.7941 36.6784C76.7114 36.6513 76.6386 36.6005 76.5846 36.5323C76.5538 36.491 76.5319 36.4436 76.5204 36.3933C76.5089 36.343 76.508 36.2909 76.5178 36.2402C76.5309 36.1821 76.5632 36.1301 76.6093 36.0923C76.6551 36.0588 76.7063 36.0333 76.7606 36.0167C76.866 35.9817 76.9736 35.954 77.0827 35.9339C77.2903 35.8939 77.5001 35.8669 77.711 35.853C77.8078 35.853 77.8994 35.8424 77.9874 35.8424H78.0542H78.107C78.1437 35.8372 78.1802 35.8302 78.2162 35.8213C78.3659 35.7731 78.5181 35.7326 78.672 35.6999C78.5352 35.7808 78.3868 35.8401 78.232 35.8759C78.1947 35.8883 78.1564 35.8978 78.1176 35.904C78.0942 35.9068 78.0706 35.9068 78.0472 35.904H77.9874C77.9046 35.904 77.8114 35.9146 77.7181 35.9234C77.5108 35.9417 77.3047 35.9705 77.1003 36.0096C76.9956 36.029 76.8921 36.0548 76.7906 36.0871C76.6885 36.117 76.6146 36.1627 76.6005 36.2455C76.5927 36.2847 76.5927 36.3252 76.6006 36.3644C76.6084 36.4037 76.6239 36.441 76.6462 36.4743C76.6918 36.533 76.7527 36.5781 76.8222 36.6045C76.9272 36.6413 77.0397 36.6515 77.1496 36.6344C77.2165 36.6327 77.264 36.6203 77.264 36.6291Z"
        fill="#EB996E"
      />
      <path
        d="M77.5368 35.4816C77.5614 35.4816 77.6037 35.5907 77.5597 35.7298C77.5157 35.8688 77.4171 35.9427 77.4013 35.9234C77.3854 35.904 77.4382 35.8195 77.4734 35.7034C77.5086 35.5872 77.5104 35.4851 77.5368 35.4816Z"
        fill="#EB996E"
      />
      <path
        d="M77.8818 35.6207C77.9064 35.6207 77.9134 35.7087 77.8518 35.7967C77.7902 35.8847 77.7075 35.8917 77.6987 35.8671C77.6899 35.8424 77.7357 35.8002 77.7797 35.7403C77.8237 35.6805 77.8554 35.6171 77.8818 35.6207Z"
        fill="#EB996E"
      />
      <path
        d="M86.5498 35.1296C86.8525 35.0029 84.8109 37.1026 84.8109 37.1026L81.0375 35.6946L79.7051 36.513L83.7619 39.3695C84.1788 39.6635 84.6872 39.7985 85.1951 39.7501C85.7029 39.7017 86.1766 39.473 86.5304 39.1055L88.9258 36.337L86.5498 35.1296Z"
        fill="#FFBE9D"
      />
      <path
        d="M88.7621 26.7168C88.2341 26.6147 87.9067 26.4528 87.8927 26.2504C87.8645 25.8315 89.1933 25.4021 90.8618 25.2877C92.5303 25.1733 93.9031 25.4232 93.933 25.8403C93.9453 26.0427 93.6443 26.2469 93.141 26.4176"
        fill="#407BFF"
      />
      <g opacity="0.4">
        <path
          d="M89.056 26.6816C88.5896 26.5936 88.2852 26.4493 88.2852 26.2698C88.2852 25.7682 89.438 25.5165 90.9164 25.4162C92.3948 25.3158 93.6144 25.5358 93.6391 25.9072C93.6514 26.0832 93.3839 26.2592 92.9351 26.4176"
          fill="black"
        />
      </g>
      <path
        d="M88.2939 26.0251C88.2939 26.0251 88.6055 24.2211 88.6371 24.1314C88.6688 24.0416 89.1211 23.232 90.3162 22.9275L91.3159 22.8589C91.3159 22.8589 92.7661 23.2197 93.0477 23.5488C93.3293 23.8779 93.5546 25.5464 93.5546 25.5464L90.7104 25.1275L88.836 25.7206L88.2939 26.0251Z"
        fill="#407BFF"
      />
      <path
        d="M90.6294 22.704L90.2 22.7322C90.0998 22.865 90.05 23.029 90.0592 23.1951C90.0592 23.5066 90.1349 25.6432 90.1349 25.6432L91.3845 25.5024L91.168 22.8695L91.5992 22.9363L91.5763 22.7867L91.4549 22.6618L90.6294 22.704Z"
        fill="#407BFF"
      />
      <path
        d="M93.2413 32.5371L90.1789 32.604C90.1401 32.2819 90.1683 30.2667 90.1683 30.2667C90.1683 30.2667 89.0032 30.1647 88.9099 29.0119C88.8166 27.8591 88.8377 25.5429 88.8377 25.5429L88.8501 25.4408C90.1472 24.7773 91.6907 24.6207 92.8981 25.4303L93.0741 25.5499L93.2413 32.5371Z"
        fill="#FFBE9D"
      />
      <path
        d="M90.1683 30.272C90.7016 30.2341 91.2128 30.0441 91.6414 29.7246C91.6414 29.7246 91.3229 30.6046 90.1648 30.5712L90.1683 30.272Z"
        fill="#EB996E"
      />
      <path
        d="M93.0212 26.752C93.0406 26.752 93.7974 26.4651 93.8256 27.2554C93.8537 28.0456 93.0547 27.9101 93.0529 27.889C93.0512 27.8679 93.0212 26.752 93.0212 26.752Z"
        fill="#FFBE9D"
      />
      <path
        d="M93.28 27.581C93.28 27.581 93.2941 27.5915 93.317 27.6003C93.3334 27.6073 93.3511 27.611 93.3689 27.611C93.3868 27.611 93.4044 27.6073 93.4208 27.6003C93.4707 27.5661 93.5105 27.5192 93.5362 27.4644C93.5619 27.4097 93.5725 27.349 93.5669 27.2888C93.5668 27.221 93.5511 27.1542 93.5211 27.0934C93.5128 27.069 93.4987 27.047 93.48 27.0292C93.4613 27.0115 93.4386 26.9985 93.4138 26.9914C93.3979 26.988 93.3814 26.9901 93.367 26.9975C93.3526 27.0049 93.3411 27.017 93.3346 27.0318C93.324 27.0547 93.3346 27.0706 93.3258 27.0723C93.317 27.0741 93.3082 27.06 93.3135 27.0266C93.3179 27.0068 93.3283 26.989 93.3434 26.9755C93.3647 26.9584 93.3918 26.9502 93.4191 26.9526C93.4518 26.9578 93.4826 26.9715 93.5085 26.9921C93.5344 27.0128 93.5546 27.0398 93.5669 27.0706C93.6044 27.1344 93.6267 27.2061 93.632 27.28C93.6398 27.351 93.6258 27.4227 93.5919 27.4856C93.5579 27.5485 93.5056 27.5996 93.4419 27.632C93.421 27.6393 93.3987 27.6416 93.3767 27.6389C93.3548 27.6361 93.3337 27.6283 93.3152 27.6162C93.28 27.6003 93.28 27.5827 93.28 27.581Z"
        fill="#EB996E"
      />
      <path
        d="M89.2197 26.9667C89.2245 27.01 89.2451 27.0499 89.2776 27.0789C89.3102 27.1078 89.3522 27.1236 89.3957 27.1234C89.4382 27.1234 89.4789 27.1065 89.509 27.0765C89.539 27.0464 89.5559 27.0057 89.5559 26.9632C89.5511 26.92 89.5305 26.88 89.498 26.8511C89.4655 26.8222 89.4234 26.8063 89.3799 26.8066C89.3374 26.8066 89.2967 26.8235 89.2666 26.8535C89.2366 26.8835 89.2197 26.9243 89.2197 26.9667V26.9667Z"
        fill="#263238"
      />
      <path
        d="M89.1669 26.8048C89.188 26.8242 89.3077 26.7239 89.4855 26.7115C89.6632 26.6992 89.7988 26.7802 89.8164 26.7573C89.834 26.7344 89.8005 26.7098 89.7424 26.6728C89.6617 26.6253 89.5684 26.6032 89.4749 26.6095C89.3825 26.6155 89.2944 26.6506 89.2232 26.7098C89.1722 26.752 89.1564 26.796 89.1669 26.8048Z"
        fill="#263238"
      />
      <path
        d="M90.9673 26.9016C90.9684 26.9235 90.9739 26.9449 90.9834 26.9647C90.9928 26.9845 91.0062 27.0022 91.0225 27.0167C91.0389 27.0313 91.058 27.0425 91.0787 27.0496C91.0995 27.0567 91.1214 27.0597 91.1433 27.0582C91.1642 27.0582 91.185 27.0541 91.2043 27.046C91.2237 27.0379 91.2412 27.0261 91.2559 27.0112C91.2707 26.9963 91.2823 26.9787 91.2902 26.9592C91.298 26.9398 91.3019 26.919 91.3017 26.8981C91.3006 26.8762 91.2951 26.8547 91.2856 26.835C91.2762 26.8152 91.2628 26.7975 91.2465 26.7829C91.2301 26.7684 91.211 26.7572 91.1902 26.7501C91.1695 26.7429 91.1476 26.74 91.1257 26.7414C91.1047 26.7414 91.084 26.7456 91.0647 26.7537C91.0453 26.7617 91.0278 26.7736 91.0131 26.7885C90.9983 26.8033 90.9867 26.821 90.9788 26.8404C90.971 26.8599 90.9671 26.8807 90.9673 26.9016V26.9016Z"
        fill="#263238"
      />
      <path
        d="M90.9128 26.7186C90.9339 26.7397 91.0518 26.6394 91.2296 26.6271C91.4073 26.6147 91.5428 26.6957 91.5604 26.6728C91.578 26.6499 91.5464 26.6253 91.4865 26.5866C91.4065 26.5389 91.3136 26.5173 91.2208 26.525C91.1282 26.5301 91.0398 26.5654 90.9691 26.6253C90.9181 26.6711 90.9022 26.7098 90.9128 26.7186Z"
        fill="#263238"
      />
      <path
        d="M90.3584 28.0773C90.2625 28.0557 90.1645 28.0451 90.0662 28.0456C90.0204 28.0456 89.9764 28.0456 89.9676 28.0069C89.9604 27.9606 89.9678 27.9133 89.9887 27.8714L90.0996 27.5194C90.2027 27.2185 90.2827 26.9102 90.339 26.5971C90.2103 26.8885 90.1055 27.1899 90.0257 27.4983C89.9888 27.6215 89.9536 27.7394 89.9201 27.8503C89.8969 27.9061 89.8932 27.9681 89.9096 28.0263C89.9173 28.0416 89.9285 28.055 89.9422 28.0654C89.9559 28.0757 89.9719 28.0828 89.9887 28.0861C90.0145 28.0894 90.0405 28.0894 90.0662 28.0861C90.1635 28.0962 90.2618 28.0932 90.3584 28.0773V28.0773Z"
        fill="#263238"
      />
      <path
        d="M90.8881 28.16C90.8582 28.16 90.8723 28.3518 90.7121 28.4997C90.5519 28.6475 90.3601 28.6405 90.3601 28.6757C90.3601 28.7109 90.4076 28.7091 90.4956 28.7056C90.6078 28.699 90.7142 28.6536 90.7966 28.5771C90.8755 28.5025 90.9227 28.4004 90.9286 28.292C90.9251 28.2093 90.9004 28.16 90.8881 28.16Z"
        fill="#263238"
      />
      <path
        d="M90.816 26.0269C90.8372 26.0744 91.0114 26.0392 91.2208 26.0515C91.4303 26.0638 91.6028 26.1078 91.6274 26.0621C91.652 26.0163 91.6063 25.997 91.5359 25.9547C91.442 25.9026 91.337 25.8736 91.2296 25.8702C91.1221 25.8648 91.0148 25.8847 90.9164 25.9283C90.846 25.9653 90.816 26.0058 90.816 26.0269Z"
        fill="#263238"
      />
      <path
        d="M89.1968 26.2874C89.232 26.3243 89.3499 26.2751 89.4978 26.2627C89.6456 26.2504 89.7706 26.2715 89.797 26.2275C89.8234 26.1835 89.7847 26.1677 89.7283 26.1325C89.6523 26.0919 89.5659 26.0747 89.48 26.0832C89.3942 26.0916 89.3128 26.1253 89.2461 26.18C89.1968 26.224 89.1827 26.268 89.1968 26.2874Z"
        fill="#263238"
      />
      <path
        d="M93.104 26.004C93.0952 26.3261 93.1269 26.6482 93.1181 26.9702C93.1077 27.0919 93.0767 27.2109 93.0266 27.3222C93.0099 27.3867 92.9734 27.4444 92.9223 27.4871C92.8711 27.5297 92.8079 27.5553 92.7414 27.5602C92.675 27.565 92.6087 27.5489 92.5519 27.5141C92.4951 27.4794 92.4506 27.4276 92.4247 27.3662C92.362 27.2391 92.3283 27.0997 92.3261 26.9579C92.3249 26.816 92.3012 26.6752 92.2557 26.5408C92.1807 26.4014 92.091 26.2705 91.9882 26.1501C91.9377 26.0889 91.902 26.0169 91.884 25.9396C91.866 25.8624 91.8661 25.782 91.8844 25.7048C92.3103 25.6918 92.7319 25.7946 93.104 26.0022"
        fill="#263238"
      />
      <path
        d="M88.8377 26.1466L88.8184 25.5006L90.6664 24.8107L93.0688 25.3387L93.1145 26.0322C91.7263 25.5247 90.1968 25.5656 88.8377 26.1466V26.1466Z"
        fill="#455A64"
      />
      <path
        d="M88.9276 25.3563L88.8501 25.4408L88.2007 25.9142C89.0511 25.6293 89.9339 25.4519 90.8284 25.3862C91.8108 25.3638 92.7924 25.456 93.7535 25.6608L92.8242 25.1838C92.6734 25.043 92.4958 24.9338 92.302 24.8628C92.1082 24.7917 91.9022 24.7603 91.696 24.7702L89.9994 24.8512C89.7963 24.8608 89.5971 24.9104 89.4132 24.9971C89.2293 25.0837 89.0643 25.2058 88.9276 25.3563V25.3563Z"
        fill="#407BFF"
      />
      <path
        d="M90.1121 24.7931C90.1433 24.4932 90.1515 24.1914 90.1367 23.8902C90.1441 23.5886 90.1288 23.2868 90.0909 22.9874C90.0576 23.2871 90.0493 23.5891 90.0663 23.8902C90.0578 24.1919 90.0731 24.4938 90.1121 24.7931Z"
        fill="#263238"
      />
      <path
        d="M91.5974 22.9363C91.4932 22.898 91.3843 22.8743 91.2736 22.8659C91.2393 22.8686 91.2073 22.884 91.1838 22.9091C91.1604 22.9341 91.1472 22.9671 91.1469 23.0015L91.1574 23.1915C91.1697 23.3271 91.1803 23.4714 91.1926 23.621C91.2173 23.9272 91.2454 24.2035 91.2683 24.4024C91.2733 24.5115 91.2911 24.6195 91.3211 24.7245C91.3338 24.6163 91.3338 24.5071 91.3211 24.3989C91.3105 24.1983 91.2912 23.9219 91.2648 23.6175C91.2507 23.4661 91.2384 23.3218 91.2261 23.1863C91.2261 23.1229 91.212 23.0613 91.2067 23.0015C91.2065 22.9806 91.2139 22.9603 91.2274 22.9444C91.241 22.9286 91.2599 22.9182 91.2806 22.9152C91.3855 22.9308 91.4914 22.9379 91.5974 22.9363V22.9363Z"
        fill="#263238"
      />
      <path
        d="M88.1566 25.909C88.1566 25.909 88.2341 25.8703 88.3766 25.8087C88.447 25.777 88.5333 25.7418 88.6318 25.6872C88.7462 25.6281 88.8437 25.5409 88.9152 25.4338C88.9979 25.3106 89.0032 25.1469 89.0912 25.0237C89.1792 24.9005 89.3622 24.8811 89.5417 24.8635C89.9043 24.8231 90.3038 24.7931 90.7245 24.7755C91.1451 24.7579 91.5481 24.7544 91.9125 24.7579C92.0797 24.7439 92.2467 24.7874 92.3859 24.8811C92.4422 24.935 92.4934 24.9939 92.539 25.0571L92.6112 25.1434L92.6481 25.1839C92.6633 25.1976 92.6805 25.2089 92.6992 25.2173C92.9597 25.3211 93.1726 25.3933 93.3187 25.4408C93.3927 25.4714 93.4685 25.4973 93.5457 25.5183C93.5287 25.5058 93.5104 25.4952 93.4912 25.4866C93.4542 25.469 93.3997 25.4443 93.3275 25.4144C93.1867 25.3528 92.9755 25.2789 92.7221 25.1715C92.7088 25.1655 92.6964 25.1578 92.6851 25.1487L92.6517 25.1082L92.583 25.0219C92.535 24.9591 92.4821 24.9002 92.4246 24.8459C92.2766 24.7395 92.096 24.6884 91.9142 24.7016C91.5481 24.7016 91.1451 24.7016 90.7227 24.7139C90.3003 24.7263 89.8973 24.7632 89.5329 24.8072C89.4428 24.8143 89.3533 24.8284 89.2654 24.8495C89.181 24.871 89.1069 24.9214 89.056 24.992C88.9539 25.1363 88.9539 25.3 88.88 25.4091C88.8153 25.5115 88.7257 25.5957 88.6195 25.6538C88.5262 25.7101 88.4435 25.7506 88.3713 25.7858C88.2992 25.821 88.2499 25.8509 88.2147 25.872C88.1937 25.8815 88.1741 25.8939 88.1566 25.909V25.909Z"
        fill="#263238"
      />
      <path
        d="M88.4172 25.9917C88.4342 25.9845 88.4502 25.975 88.4647 25.9635C88.4946 25.9424 88.5404 25.9143 88.6002 25.8808C88.7742 25.7943 88.9564 25.7253 89.144 25.6749C89.7664 25.5115 90.4087 25.4368 91.0519 25.4531C91.4303 25.4531 91.7893 25.4531 92.1079 25.4672C92.4264 25.4813 92.7256 25.5323 92.9685 25.5728C93.0776 25.5913 93.1857 25.6154 93.2924 25.645C93.373 25.6596 93.4494 25.6921 93.5159 25.74C93.5422 25.7611 93.5634 25.7879 93.5777 25.8184C93.5921 25.8489 93.5992 25.8823 93.5986 25.916C93.6084 25.899 93.6122 25.8791 93.6092 25.8597C93.6029 25.8037 93.5767 25.7518 93.5352 25.7136C93.4684 25.658 93.3894 25.6188 93.3047 25.5992C93.1971 25.5658 93.0878 25.5382 92.9773 25.5165C92.6916 25.4607 92.4032 25.4201 92.1132 25.3951C91.784 25.3704 91.4215 25.3775 91.0448 25.3757C90.3954 25.3582 89.7472 25.4408 89.1229 25.6203C88.9327 25.6742 88.75 25.7517 88.5791 25.8509C88.5329 25.878 88.4893 25.9092 88.4488 25.9442C88.4277 25.9759 88.4154 25.9899 88.4172 25.9917Z"
        fill="#263238"
      />
      <path
        d="M90.3848 22.7744C90.4433 22.7789 90.5022 22.7789 90.5608 22.7744L90.9867 22.7586C91.1521 22.7586 91.3052 22.7322 91.4108 22.7427C91.4395 22.7438 91.4676 22.7512 91.4931 22.7642C91.5187 22.7773 91.541 22.7958 91.5587 22.8184C91.5587 22.8184 91.5587 22.8008 91.5341 22.7779C91.5005 22.7471 91.4579 22.728 91.4126 22.7234C91.2703 22.7123 91.1273 22.7123 90.9849 22.7234L90.559 22.748C90.5001 22.7502 90.4417 22.7591 90.3848 22.7744Z"
        fill="#FAFAFA"
      />
      <path
        d="M90.3109 24.8635C90.3408 24.8666 90.3708 24.8666 90.4007 24.8635H90.6436C90.8495 24.8635 91.1328 24.8635 91.4461 24.8635L91.8879 24.8741C92.0058 24.8735 92.1236 24.8829 92.2399 24.9023C92.3187 24.9186 92.3895 24.9615 92.4405 25.0237C92.4576 25.0471 92.4718 25.0726 92.4827 25.0994C92.4794 25.0693 92.4678 25.0407 92.4493 25.0167C92.3994 24.9492 92.327 24.9017 92.2452 24.8829C92.1291 24.8605 92.0113 24.8487 91.8932 24.8477C91.7576 24.8477 91.608 24.8371 91.4514 24.8354C91.1363 24.8354 90.853 24.8354 90.6471 24.8442L90.4042 24.8565C90.3729 24.8561 90.3417 24.8584 90.3109 24.8635V24.8635Z"
        fill="#FAFAFA"
      />
      <path
        d="M91.2383 24.5502C91.2407 24.5292 91.2407 24.5079 91.2383 24.4869C91.2383 24.4288 91.2225 24.3725 91.2137 24.3109C91.1943 24.1648 91.1697 23.9589 91.1468 23.7389C91.1239 23.5189 91.1116 23.313 91.1028 23.1669C91.1028 23.107 91.0993 23.0484 91.0923 22.9909C91.0953 22.9699 91.0953 22.9485 91.0923 22.9275C91.0897 22.9486 91.0897 22.9698 91.0923 22.9909C91.0923 23.0314 91.0923 23.0912 91.0923 23.1669C91.0923 23.3147 91.1063 23.5189 91.1275 23.7424C91.1486 23.9659 91.1785 24.1683 91.2031 24.3126C91.2155 24.3866 91.2278 24.4446 91.2366 24.4886C91.2342 24.5091 91.2348 24.5299 91.2383 24.5502V24.5502Z"
        fill="#FAFAFA"
      />
      <path
        d="M92.7028 25.2507C92.7206 25.261 92.7395 25.2693 92.7591 25.2754L92.914 25.3335L93.4279 25.5095C93.5226 25.5396 93.6155 25.5755 93.706 25.6168C93.7783 25.6501 93.8363 25.7082 93.8696 25.7805C93.8913 25.8297 93.8968 25.8846 93.8855 25.9371C93.8732 25.9723 93.8591 25.9899 93.8626 25.9917C93.8774 25.9774 93.8889 25.96 93.896 25.9407C93.916 25.8867 93.916 25.8274 93.896 25.7735C93.8608 25.6953 93.7982 25.6327 93.72 25.5975C93.6293 25.553 93.5351 25.5159 93.4384 25.4866L92.921 25.3106L92.7644 25.2613C92.7443 25.2553 92.7236 25.2517 92.7028 25.2507Z"
        fill="#FAFAFA"
      />
      <path
        d="M79.6945 56.7037L79.8547 59.5619C79.8547 59.5619 77.0035 60.6831 76.9824 61.2128L82.5334 61.1037L82.4665 56.6421L79.6945 56.7037Z"
        fill="#407BFF"
      />
      <g opacity="0.4">
        <path
          d="M79.6945 56.7037L79.8547 59.5619C79.8547 59.5619 77.0035 60.6831 76.9824 61.2128L82.5334 61.1037L82.4665 56.6421L79.6945 56.7037Z"
          fill="black"
        />
      </g>
      <path
        d="M81.5654 59.2874C81.6192 59.3023 81.6656 59.3366 81.6955 59.3838C81.7254 59.4309 81.7368 59.4875 81.7273 59.5426C81.7143 59.5968 81.6809 59.6439 81.634 59.674C81.5871 59.7042 81.5304 59.7151 81.4756 59.7045C81.4175 59.6898 81.3671 59.6538 81.3345 59.6035C81.3018 59.5532 81.2894 59.4925 81.2996 59.4335C81.3193 59.3785 81.3593 59.3333 81.4114 59.3071C81.4635 59.2808 81.5237 59.2757 81.5795 59.2927"
        fill="#E0E0E0"
      />
      <path
        d="M82.5334 61.1037L82.5088 60.6549L77.1953 60.9277C77.1953 60.9277 76.9507 61.0403 76.9824 61.2128L82.5334 61.1037Z"
        fill="#E0E0E0"
      />
      <path
        d="M79.7649 59.5355C79.7649 59.5619 79.9005 59.5707 80.036 59.6552C80.1715 59.7397 80.2454 59.8523 80.2701 59.8418C80.2947 59.8312 80.2507 59.6746 80.0853 59.576C79.9198 59.4774 79.7579 59.5109 79.7649 59.5355Z"
        fill="#263238"
      />
      <path
        d="M79.1859 59.7872C79.1859 59.8136 79.2986 59.8576 79.3936 59.9632C79.4886 60.0688 79.5168 60.1973 79.5432 60.1955C79.5696 60.1938 79.5854 60.0389 79.4658 59.9016C79.3461 59.7643 79.1859 59.7608 79.1859 59.7872Z"
        fill="#263238"
      />
      <path
        d="M78.8691 60.4912C78.8938 60.4912 78.929 60.3592 78.8568 60.2131C78.7846 60.0671 78.6562 60.009 78.6456 60.0371C78.635 60.0653 78.7142 60.1375 78.7723 60.2589C78.8304 60.3803 78.848 60.4877 78.8691 60.4912Z"
        fill="#263238"
      />
      <path
        d="M79.8002 58.879C79.8125 58.9037 79.9357 58.865 80.0888 58.879C80.2419 58.8931 80.3634 58.9318 80.3757 58.909C80.388 58.8861 80.2736 58.791 80.0923 58.784C79.911 58.777 79.7861 58.8579 79.8002 58.879Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M79.728 57.2704L79.7544 57.7791L82.4824 57.6999L82.4771 57.2405L79.728 57.2704Z"
          fill="black"
        />
      </g>
      <path
        d="M79.7791 58.3035C79.8702 58.3275 79.966 58.3275 80.0571 58.3035C80.1566 58.2826 80.2534 58.2507 80.3458 58.2085C80.4002 58.1822 80.4531 58.1528 80.5042 58.1205C80.5344 58.1017 80.5622 58.0792 80.5869 58.0536C80.6036 58.0337 80.6135 58.0089 80.6151 57.9829C80.6167 57.9569 80.6099 57.9311 80.5957 57.9093C80.5819 57.8906 80.5638 57.8754 80.543 57.865C80.5222 57.8546 80.4993 57.8493 80.476 57.8494C80.4407 57.8487 80.4056 57.8541 80.3722 57.8653C80.3114 57.8801 80.2524 57.9013 80.1962 57.9286C80.1031 57.9738 80.0176 58.0332 79.9427 58.1046C79.8178 58.2261 79.7755 58.3282 79.7843 58.3334C79.7931 58.3387 79.8583 58.256 79.9867 58.1574C80.0623 58.0989 80.1451 58.0504 80.2331 58.0131C80.2844 57.9913 80.3374 57.9737 80.3915 57.9603C80.4496 57.9427 80.4989 57.9427 80.5147 57.9603C80.5306 57.9779 80.5147 57.9744 80.5147 57.992C80.4969 58.0109 80.4768 58.0274 80.4549 58.0413C80.4079 58.0729 80.3592 58.1017 80.3088 58.1275C80.2238 58.172 80.1348 58.2086 80.0431 58.2366C79.9534 58.2516 79.8651 58.274 79.7791 58.3035Z"
        fill="#263238"
      />
      <path
        d="M79.8142 58.3405C79.8248 58.3405 79.8759 58.2472 79.8829 58.0712C79.8849 57.968 79.87 57.8651 79.8389 57.7667C79.8204 57.7056 79.7957 57.6467 79.765 57.5907C79.7497 57.5549 79.7255 57.5237 79.6947 57.5C79.6639 57.4762 79.6275 57.4608 79.589 57.4552C79.5644 57.4562 79.5406 57.4642 79.5203 57.4782C79.5001 57.4923 79.4842 57.5117 79.4746 57.5344C79.4615 57.5672 79.4526 57.6015 79.4482 57.6365C79.4393 57.6984 79.4393 57.7612 79.4482 57.823C79.466 57.9268 79.5089 58.0247 79.5731 58.1082C79.684 58.2507 79.7984 58.2842 79.8002 58.2718C79.8019 58.2595 79.7139 58.2067 79.6242 58.0712C79.5745 57.9922 79.5427 57.9033 79.5309 57.8107C79.523 57.7576 79.523 57.7037 79.5309 57.6506C79.5309 57.5907 79.5591 57.5432 79.5819 57.5485C79.6048 57.5538 79.6576 57.5907 79.6805 57.6382C79.7098 57.6854 79.734 57.7355 79.7527 57.7878C79.7858 57.8766 79.806 57.9696 79.8125 58.0642C79.8266 58.2349 79.8002 58.337 79.8142 58.3405Z"
        fill="#263238"
      />
      <path
        d="M94.688 44.565C94.688 44.565 94.0808 47.6767 93.0741 47.7207C91.8421 47.7735 82.8925 47.8967 82.8925 47.8967L83.424 57.86L79.5203 57.7667L77.968 47.9477C77.7946 47.0668 77.9419 46.1529 78.3833 45.3711L78.415 45.3165C78.7486 44.7678 79.2176 44.3141 79.7769 43.9988C80.3363 43.6835 80.9673 43.5173 81.6094 43.516L89.0419 43.4034L94.688 44.565Z"
        fill="#263238"
      />
      <path
        d="M81.2134 56.7037L81.3718 59.5619C81.3718 59.5619 78.5206 60.6831 78.4995 61.2128L84.0506 61.1037L83.9837 56.6421L81.2134 56.7037Z"
        fill="#407BFF"
      />
      <path
        d="M83.0826 59.2874C83.1364 59.3023 83.1827 59.3366 83.2127 59.3838C83.2426 59.4309 83.2539 59.4875 83.2445 59.5426C83.2318 59.597 83.1985 59.6444 83.1515 59.6746C83.1045 59.7048 83.0476 59.7156 82.9928 59.7045C82.9347 59.6898 82.8843 59.6538 82.8516 59.6035C82.819 59.5532 82.8065 59.4925 82.8168 59.4335C82.8364 59.3785 82.8764 59.3333 82.9285 59.3071C82.9806 59.2808 83.0408 59.2757 83.0966 59.2927"
        fill="#E0E0E0"
      />
      <path
        d="M84.0506 61.1037L84.0259 60.6549L78.7125 60.9277C78.7125 60.9277 78.4679 61.0403 78.4995 61.2128L84.0506 61.1037Z"
        fill="#E0E0E0"
      />
      <path
        d="M81.2821 59.5355C81.2821 59.5619 81.4194 59.5707 81.5531 59.6552C81.6869 59.7397 81.7626 59.8523 81.7872 59.8418C81.8118 59.8312 81.7678 59.6746 81.6024 59.576C81.437 59.4774 81.275 59.5109 81.2821 59.5355Z"
        fill="#263238"
      />
      <path
        d="M80.7048 59.7872C80.7048 59.8136 80.8174 59.8576 80.9107 59.9632C81.004 60.0688 81.0339 60.1973 81.0603 60.1955C81.0867 60.1938 81.1025 60.0389 80.9829 59.9016C80.8632 59.7643 80.7048 59.7608 80.7048 59.7872Z"
        fill="#263238"
      />
      <path
        d="M80.3862 60.4912C80.4126 60.4912 80.4461 60.3592 80.3739 60.2131C80.3017 60.0671 80.1733 60.009 80.1627 60.0371C80.1521 60.0653 80.2313 60.1375 80.2894 60.2589C80.3475 60.3803 80.3598 60.4877 80.3862 60.4912Z"
        fill="#263238"
      />
      <path
        d="M81.312 58.879C81.3243 58.9037 81.4475 58.865 81.6007 58.879C81.7538 58.8931 81.8752 58.9318 81.8875 58.909C81.8998 58.8861 81.7855 58.791 81.6042 58.784C81.4229 58.777 81.312 58.8579 81.312 58.879Z"
        fill="#263238"
      />
      <g opacity="0.3">
        <path
          d="M81.2451 57.2704L81.2733 57.7791L83.9995 57.6999L83.9942 57.2405L81.2451 57.2704Z"
          fill="black"
        />
      </g>
      <path
        d="M81.2961 58.3035C81.3873 58.3275 81.4831 58.3275 81.5742 58.3035C81.6737 58.2826 81.7705 58.2507 81.8629 58.2085C81.9175 58.1825 81.9704 58.1531 82.0213 58.1205C82.0515 58.1017 82.0793 58.0792 82.104 58.0536C82.1207 58.0337 82.1306 58.0089 82.1322 57.9829C82.1338 57.9569 82.127 57.9311 82.1128 57.9093C82.0989 57.8906 82.0809 57.8754 82.0601 57.865C82.0393 57.8546 82.0163 57.8493 81.9931 57.8494C81.9578 57.8487 81.9227 57.8541 81.8893 57.8653C81.8285 57.8801 81.7695 57.9013 81.7133 57.9286C81.6204 57.9733 81.5354 58.0328 81.4616 58.1046C81.3349 58.2261 81.2856 58.3282 81.3014 58.3334C81.3173 58.3387 81.3753 58.256 81.5038 58.1574C81.5794 58.0989 81.6622 58.0504 81.7502 58.0131C81.8015 57.9913 81.8545 57.9737 81.9086 57.9603C81.9667 57.9427 82.016 57.9427 82.0318 57.9603C82.0477 57.9779 82.0318 57.9744 82.0318 57.992C82.014 58.0109 81.9939 58.0274 81.972 58.0413C81.925 58.0729 81.8763 58.1017 81.8259 58.1275C81.7409 58.172 81.6519 58.2086 81.5601 58.2366C81.4705 58.2516 81.3822 58.274 81.2961 58.3035V58.3035Z"
        fill="#263238"
      />
      <path
        d="M81.3313 58.3405C81.3419 58.3405 81.3929 58.2472 81.4 58.0712C81.4028 57.9679 81.388 57.8649 81.356 57.7667C81.3375 57.7056 81.3128 57.6467 81.2821 57.5907C81.2668 57.5549 81.2426 57.5237 81.2118 57.5C81.1809 57.4762 81.1445 57.4608 81.1061 57.4552C81.0815 57.4562 81.0576 57.4642 81.0374 57.4782C81.0172 57.4923 81.0013 57.5117 80.9917 57.5344C80.9786 57.5672 80.9697 57.6015 80.9653 57.6365C80.9564 57.6984 80.9564 57.7612 80.9653 57.823C80.983 57.9268 81.0259 58.0247 81.0902 58.1082C81.2011 58.2507 81.3155 58.2842 81.3173 58.2718C81.319 58.2595 81.231 58.2067 81.1413 58.0712C81.0916 57.9922 81.0598 57.9033 81.048 57.8107C81.0401 57.7576 81.0401 57.7037 81.048 57.6506C81.0568 57.5907 81.0779 57.5432 81.099 57.5485C81.1201 57.5538 81.1747 57.5907 81.1976 57.6382C81.2269 57.6854 81.2511 57.7355 81.2697 57.7878C81.3029 57.8766 81.3231 57.9696 81.3296 58.0642C81.3437 58.2349 81.312 58.337 81.3313 58.3405Z"
        fill="#263238"
      />
      <path
        d="M95.6665 44.565C95.6665 44.565 95.0611 47.6767 94.0526 47.7207C92.8206 47.7735 83.871 47.8967 83.871 47.8967L84.4148 57.86L80.5112 57.7667L78.95 47.9477C78.7767 47.0668 78.924 46.1529 79.3654 45.3711L79.3971 45.3165C79.7308 44.7676 80.2001 44.3137 80.7598 43.9984C81.3195 43.6831 81.9508 43.517 82.5932 43.516L90.024 43.4034L95.6665 44.565Z"
        fill="#263238"
      />
      <path
        d="M88.0527 32.0725L89.2319 39.901L88.4347 43.421L95.6753 45.5523C96.33 44.616 96.3547 41.6434 96.3318 39.5402C96.2913 35.8336 96.455 35.6541 96.2948 34.3535C96.1347 33.0528 95.8619 32.2186 95.1755 31.8085L93.2007 30.8C93.2007 30.8 92.0884 31.504 91.4108 31.4706C91.4108 31.4706 90.6065 31.504 90.1612 31.0763C90.1612 31.0711 88.5279 31.5639 88.0527 32.0725Z"
        fill="#407BFF"
      />
      <path
        d="M88.0529 32.0725L85.6399 35.3989L88.9258 37.3278L88.6741 36.1187L88.0529 32.0725Z"
        fill="#407BFF"
      />
      <path
        d="M82.3029 43.5635C82.289 43.5665 82.2746 43.5665 82.2607 43.5635L82.1392 43.5723C82.0725 43.5768 82.0061 43.585 81.9403 43.597C81.8508 43.6078 81.762 43.6242 81.6746 43.6463C81.114 43.7839 80.5964 44.0585 80.168 44.4453C79.8735 44.7107 79.6202 45.0186 79.4165 45.3587C79.1833 45.7485 79.0385 46.1847 78.9923 46.6365C78.9503 47.1385 78.9692 47.6438 79.0487 48.1413C79.1208 48.6693 79.2247 49.1973 79.2792 49.7587C79.4042 50.8675 79.5714 51.92 79.728 52.8757C79.8846 53.8314 80.036 54.6885 80.1574 55.4136C80.2789 56.1387 80.3739 56.7213 80.432 57.1279C80.4584 57.325 80.4795 57.4799 80.4954 57.596C80.4954 57.6453 80.5059 57.684 80.5094 57.7175C80.511 57.7315 80.511 57.7457 80.5094 57.7597C80.5039 57.7468 80.5003 57.7332 80.4989 57.7192C80.4989 57.6858 80.4848 57.6471 80.4742 57.5978L80.3951 57.1331C80.3211 56.7213 80.219 56.1405 80.0923 55.4242C79.9656 54.7079 79.8125 53.8402 79.6435 52.8898C79.4746 51.9394 79.3109 50.8816 79.1859 49.7675C79.1296 49.2131 79.0293 48.6781 78.9589 48.1536C78.8784 47.6491 78.8612 47.1365 78.9078 46.6277C78.9563 46.1644 79.1072 45.7178 79.3496 45.32C79.5591 44.9743 79.8202 44.6627 80.124 44.396C80.5629 44.0076 81.0935 43.7375 81.6658 43.6111C81.875 43.5664 82.0893 43.5504 82.3029 43.5635V43.5635Z"
        fill="#455A64"
      />
      <path
        d="M85.0766 41.1471C85.0766 41.1471 87.8697 42.0482 90.0416 42.8349C90.4103 42.9688 90.8064 43.0092 91.1946 42.9527C91.5828 42.8962 91.951 42.7445 92.2662 42.5111V42.5111C92.6698 42.2136 92.9686 41.7958 93.1198 41.3178L94.607 36.6327L92.1237 35.7632L90.464 39.9749L84.2864 39.1635"
        fill="#FFBE9D"
      />
      <path
        d="M81.3824 40.1808L81.3595 39.959C81.3747 39.7953 81.4427 39.6409 81.5531 39.519V39.519C81.6129 39.4525 81.6845 39.3976 81.7643 39.3571L82.4947 38.9752C82.6032 38.92 82.7264 38.9003 82.8467 38.9189L84.3445 39.1741L86.064 39.3994L85.712 41.36L84.9165 41.096C84.9165 41.096 84.3409 41.4146 83.9854 41.3389C83.9854 41.3389 83.6563 41.5589 83.5208 41.397C83.5208 41.397 83.2269 41.6504 83.116 41.6346C83.0051 41.6187 82.7869 41.4902 82.7957 41.4146L82.8045 41.3125C82.7481 41.3434 82.6843 41.3582 82.6201 41.3554C82.5559 41.3526 82.4936 41.3323 82.4401 41.2966C82.3641 41.2376 82.3061 41.1583 82.2729 41.0678C82.1357 41.0535 82.0062 40.9976 81.9016 40.9077C81.8324 40.8062 81.7861 40.6909 81.7661 40.5698C81.7661 40.5698 81.4369 40.4096 81.3824 40.1808Z"
        fill="#FFBE9D"
      />
      <path
        d="M82.4877 39.4715C82.4983 39.4715 82.5159 39.5525 82.4789 39.6757C82.4243 39.8317 82.3339 39.9728 82.2149 40.0875C82.0829 40.223 81.9597 40.3339 81.884 40.4254C81.8083 40.517 81.7679 40.5803 81.7555 40.575C81.7432 40.5698 81.7555 40.4923 81.833 40.3867C81.9262 40.2561 82.0317 40.1347 82.148 40.0242C82.258 39.916 82.3495 39.7905 82.4191 39.6528C82.4666 39.5472 82.4719 39.4715 82.4877 39.4715Z"
        fill="#EB996E"
      />
      <path
        d="M82.7728 40.2547C82.689 40.5695 82.5072 40.8493 82.2536 41.0538C82.3184 40.9079 82.4011 40.7707 82.5 40.6455C82.6426 40.4272 82.7482 40.2424 82.7728 40.2547Z"
        fill="#EB996E"
      />
      <path
        d="M83.1547 40.7722C83.1758 40.7862 83.1142 40.913 83.0157 41.0573C82.9171 41.2016 82.8221 41.3072 82.8009 41.2914C82.7798 41.2755 82.8432 41.1488 82.94 41.0062C83.0368 40.8637 83.1336 40.7581 83.1547 40.7722Z"
        fill="#EB996E"
      />
      <path
        d="M83.0843 40.5663C83.0843 40.5768 83.0456 40.5786 82.9998 40.5504C82.97 40.5305 82.9447 40.5046 82.9256 40.4742C82.9065 40.4439 82.894 40.4099 82.8889 40.3744C82.8828 40.3397 82.8843 40.3041 82.8935 40.27C82.9026 40.2359 82.9191 40.2043 82.9417 40.1773C82.9752 40.1368 83.0086 40.1263 83.0174 40.1351C83.0262 40.1439 82.9593 40.2424 82.9787 40.3603C82.9981 40.4783 83.0949 40.5434 83.0843 40.5663Z"
        fill="#EB996E"
      />
      <path
        d="M83.5014 41.0925C83.5014 41.1031 83.4697 41.1048 83.431 41.0925C83.4063 41.0778 83.3848 41.0581 83.3678 41.0348C83.3509 41.0115 83.3389 40.9851 83.3324 40.957C83.3215 40.9263 83.3202 40.893 83.3287 40.8616C83.3372 40.8301 83.3551 40.802 83.38 40.781C83.4257 40.7493 83.4609 40.7704 83.4574 40.781C83.438 40.7993 83.4243 40.8228 83.418 40.8488C83.4118 40.8747 83.4132 40.9019 83.4222 40.9271C83.4416 41.0168 83.512 41.0696 83.5014 41.0925Z"
        fill="#EB996E"
      />
      <path
        d="M82.8414 40.0066C82.8414 40.0171 82.8027 40.0259 82.7499 40.0066C82.7147 39.9895 82.6846 39.9636 82.6623 39.9314C82.6401 39.8992 82.6265 39.8618 82.623 39.8229C82.6195 39.7839 82.626 39.7447 82.6421 39.7091C82.6582 39.6734 82.6832 39.6425 82.7147 39.6194C82.7622 39.5859 82.8009 39.5894 82.8027 39.6C82.8045 39.6106 82.7041 39.6898 82.7147 39.8147C82.7253 39.9397 82.8485 39.9854 82.8414 40.0066Z"
        fill="#EB996E"
      />
      <path
        d="M94.6193 32.4439C94.0526 32.1975 93.3539 32.4439 92.9438 32.8733C92.5337 33.3027 92.3454 33.8642 92.1694 34.4098C91.9934 34.9554 91.5745 36.0061 91.4038 36.5323L94.6228 37.9104C94.8974 37.1677 95.1532 36.4185 95.3902 35.6629C95.5908 35.0293 95.7774 34.3464 95.5451 33.7163"
        fill="#407BFF"
      />
      <path
        d="M95.5451 33.7163C95.5595 33.7406 95.5708 33.7666 95.5785 33.7938C95.6065 33.8697 95.6271 33.948 95.6401 34.0279C95.6905 34.3269 95.6827 34.6329 95.6172 34.929C95.5188 35.3799 95.3888 35.8233 95.2283 36.256C95.0523 36.7594 94.8763 37.3208 94.6668 37.9245L94.651 37.9685L94.6052 37.9491L92.3383 36.9811L91.3844 36.5728L91.3457 36.5552L91.358 36.52C91.6185 35.7527 91.9089 35.0504 92.1271 34.408C92.2227 34.0967 92.3403 33.7927 92.4791 33.4981C92.6034 33.2352 92.7739 32.9967 92.9825 32.7941C93.3013 32.4935 93.7235 32.3271 94.1617 32.3295C94.2815 32.3332 94.4001 32.3545 94.5137 32.3928C94.552 32.4062 94.589 32.4227 94.6246 32.4421C94.4762 32.3929 94.3215 32.3656 94.1652 32.3611C93.7407 32.3704 93.3353 32.5395 93.03 32.8346C92.8312 33.0327 92.6692 33.2645 92.5513 33.5192C92.4178 33.8125 92.3049 34.1148 92.2134 34.4239C92.0004 35.0768 91.71 35.7826 91.4548 36.5447L91.4284 36.4919L92.3841 36.9002L94.6475 37.8717L94.5859 37.8963C94.7935 37.2927 94.9871 36.7347 95.1614 36.2331C95.3253 35.8042 95.4611 35.365 95.5679 34.9184C95.636 34.6303 95.6527 34.3324 95.6172 34.0384C95.5803 33.8255 95.538 33.7181 95.5451 33.7163Z"
        fill="#263238"
      />
      <path
        d="M91.4197 41.5818C91.4091 41.5818 91.3845 41.4058 91.2754 41.0837C91.2083 40.8938 91.1191 40.7124 91.0096 40.5434C90.87 40.3212 90.6778 40.1368 90.4499 40.0066H90.4587L86.9722 39.5525L84.8602 39.2691L83.9432 39.1424L83.1459 39.0139L82.7939 38.9594C82.6953 38.9418 82.5936 38.9599 82.507 39.0104L82.0547 39.2287L81.7678 39.3642C81.7352 39.3822 81.7003 39.3958 81.664 39.4047C81.6941 39.3828 81.7259 39.3634 81.759 39.3466L82.0389 39.197L82.4842 38.9647C82.5798 38.9073 82.6924 38.8849 82.8027 38.9013L83.1547 38.9506L83.952 39.0667L84.8707 39.1864L86.9827 39.4627L90.464 39.9397H90.4746C90.7129 40.0775 90.9122 40.2737 91.0536 40.5099C91.1618 40.6844 91.2476 40.8719 91.3088 41.0679C91.3476 41.1907 91.3781 41.3159 91.4003 41.4427C91.4119 41.4882 91.4183 41.5349 91.4197 41.5818V41.5818Z"
        fill="#EB996E"
      />
      <path
        d="M89.232 39.776C89.2078 39.6933 89.1918 39.6084 89.1845 39.5226C89.1546 39.3466 89.1176 39.1107 89.0736 38.8309C88.9733 38.2307 88.8413 37.4317 88.6952 36.5429C88.5491 35.6541 88.4154 34.8603 88.3168 34.2549C88.2728 33.9751 88.2376 33.741 88.2094 33.5615C88.1912 33.477 88.1817 33.3909 88.1813 33.3045C88.2102 33.3856 88.2314 33.4693 88.2446 33.5544L88.3802 34.2443C88.491 34.8269 88.6371 35.6312 88.7867 36.5323C88.9363 37.4335 89.0595 38.2343 89.1387 38.8203C89.1792 39.1143 89.2056 39.3483 89.2214 39.5243C89.2332 39.6077 89.2367 39.692 89.232 39.776V39.776Z"
        fill="#263238"
      />
      <path
        d="M122.584 78.3024C122.584 78.3288 97.1097 78.3482 65.6937 78.3482C34.2777 78.3482 8.79999 78.3288 8.79999 78.3024C8.79999 78.276 34.2689 78.2567 65.6973 78.2567C97.1256 78.2567 122.584 78.2778 122.584 78.3024Z"
        fill="#263238"
      />
      <path
        d="M12.2109 72.4346C11.9275 72.8165 11.4013 72.6722 11.1637 72.4346C10.9261 72.197 10.8293 71.8432 10.7431 71.5123C10.5477 70.7573 10.3523 69.9653 10.5336 69.2067C10.5776 68.9761 10.6848 68.7622 10.8434 68.589C10.9233 68.5037 11.0242 68.441 11.1361 68.407C11.2479 68.373 11.3667 68.369 11.4805 68.3954C11.7463 68.4728 11.9047 68.7386 12.0191 68.9903C12.3504 69.7327 12.5133 70.5393 12.496 71.3522C12.51 71.7335 12.4101 72.1102 12.2091 72.4346"
        fill="#407BFF"
      />
      <g opacity="0.2">
        <path
          d="M12.2109 72.4346C11.9275 72.8165 11.4013 72.6722 11.1637 72.4346C10.9261 72.197 10.8293 71.8432 10.7431 71.5123C10.5477 70.7573 10.3523 69.9653 10.5336 69.2067C10.5776 68.9761 10.6848 68.7622 10.8434 68.589C10.9233 68.5037 11.0242 68.441 11.1361 68.407C11.2479 68.373 11.3667 68.369 11.4805 68.3954C11.7463 68.4728 11.9047 68.7386 12.0191 68.9903C12.3504 69.7327 12.5133 70.5393 12.496 71.3522C12.51 71.7335 12.4101 72.1102 12.2091 72.4346"
          fill="black"
        />
      </g>
      <path
        d="M11.1373 74.3759C10.687 74.1502 10.1764 74.0743 9.68001 74.1594C9.40928 74.1769 9.15191 74.2833 8.94785 74.4621C8.76129 74.6575 8.71905 75.0077 8.92145 75.1855C9.05326 75.2782 9.21293 75.323 9.37377 75.3122C9.76097 75.3122 10.1922 75.2611 10.4949 75.5023C10.6709 75.6413 10.78 75.8648 10.9894 75.9352C11.0942 75.9628 11.205 75.9554 11.3051 75.914C11.4052 75.8727 11.489 75.7998 11.5438 75.7064C11.6498 75.5149 11.6782 75.29 11.623 75.0781C11.6014 74.9316 11.5463 74.7921 11.4621 74.6703C11.3779 74.5485 11.2667 74.4478 11.1373 74.3759V74.3759Z"
        fill="#407BFF"
      />
      <path
        d="M12.943 73.0119C12.8077 72.8256 12.72 72.609 12.6878 72.381C12.6556 72.1531 12.6798 71.9207 12.7582 71.7042C12.9168 71.2721 13.1915 70.8921 13.552 70.6059C13.9665 70.2579 14.4447 69.9937 14.96 69.828C15.1594 69.7537 15.3726 69.7237 15.5848 69.74C15.6906 69.7489 15.7925 69.7835 15.8818 69.8409C15.9711 69.8982 16.045 69.9766 16.097 70.0691C16.236 70.349 16.0864 70.6816 15.921 70.9491C15.6421 71.4256 15.3261 71.8793 14.9758 72.3061C14.6942 72.6493 14.3739 72.9872 13.955 73.1351C13.5362 73.2829 13.1067 73.2512 12.899 72.9591"
        fill="#407BFF"
      />
      <path
        d="M12.0718 78.3024C12.0587 78.2447 12.0522 78.1856 12.0525 78.1264C12.0419 77.9962 12.0314 77.8343 12.0173 77.6424C11.9998 77.4063 11.9699 77.1713 11.9275 76.9384C11.8722 76.6522 11.7939 76.3708 11.6934 76.0971C11.5955 75.8226 11.4585 75.5636 11.2869 75.328C11.1472 75.1371 10.9669 74.9796 10.7589 74.8669C10.6179 74.7916 10.4658 74.7393 10.3083 74.712C10.2487 74.7091 10.1896 74.699 10.1323 74.6821C10.1908 74.6761 10.2498 74.6761 10.3083 74.6821C10.4718 74.6986 10.631 74.7445 10.7782 74.8176C10.9976 74.929 11.1884 75.0891 11.3362 75.2858C11.5146 75.5244 11.6564 75.7884 11.7568 76.069C11.8606 76.3425 11.9401 76.6246 11.9944 76.912C12.0338 77.1497 12.0591 77.3895 12.0701 77.6301C12.0701 77.836 12.0806 78.0015 12.0806 78.1159C12.084 78.1782 12.081 78.2407 12.0718 78.3024V78.3024Z"
        fill="#263238"
      />
      <path
        d="M12.049 77.9293C12.0452 77.9019 12.0452 77.874 12.049 77.8466C12.049 77.785 12.049 77.7075 12.049 77.6125C12.049 77.4101 12.0631 77.1144 12.0613 76.7519C12.0613 76.0232 12.0279 75.0183 11.9363 73.9112C11.8448 72.8042 11.7093 71.7992 11.5843 71.0952C11.5544 70.9192 11.5245 70.7538 11.4946 70.613C11.4647 70.4722 11.4435 70.349 11.4207 70.2487C11.3978 70.1483 11.3837 70.0727 11.3714 70.0199C11.3645 69.9934 11.3604 69.9662 11.3591 69.9389C11.3591 69.9389 11.3714 69.9653 11.3855 70.0163C11.3996 70.0674 11.4224 70.1501 11.4471 70.2434C11.4717 70.3367 11.5034 70.4634 11.5351 70.6042C11.5667 70.745 11.6019 70.9069 11.6354 71.0864C11.8112 72.0177 11.9369 72.9577 12.012 73.9024C12.1035 75.0112 12.1317 76.0144 12.1176 76.7501C12.1176 77.1144 12.0965 77.4101 12.0807 77.6125C12.0807 77.7075 12.0666 77.7885 12.0613 77.8466C12.059 77.8744 12.0549 77.902 12.049 77.9293Z"
        fill="#263238"
      />
      <path
        d="M12.0965 75.988C12.0892 75.906 12.0892 75.8236 12.0965 75.7416C12.1106 75.585 12.137 75.3579 12.1898 75.0816C12.3294 74.3587 12.5564 73.6554 12.8656 72.9872C13.1634 72.3119 13.5808 71.696 14.0976 71.1691C14.188 71.0794 14.2838 70.9953 14.3845 70.9174C14.4285 70.8822 14.4672 70.847 14.506 70.8189L14.6168 70.7467C14.6834 70.6998 14.7534 70.658 14.8263 70.6218C14.5839 70.8021 14.3529 70.9972 14.1346 71.2061C13.6325 71.737 13.2242 72.3494 12.9272 73.0171C12.7595 73.3817 12.6155 73.7568 12.496 74.14C12.3946 74.4522 12.3094 74.7694 12.2408 75.0904C12.1828 75.365 12.1476 75.5902 12.1247 75.7451C12.1208 75.8266 12.1114 75.9078 12.0965 75.988Z"
        fill="#263238"
      />
    </svg>
  );
}

export default NavbarLogisticIcon;
