import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import "./styles/fonts/DmSans/dmsans.css";
import "./styles/fonts/WorkSans/worksans.css";

import { Provider } from "react-redux";
import { store } from "./store/store";

import AddVehicle from "./pages/AddVehicle";
import ConfirmUser from "./pages/ConfirmUser";
import ContactUs from "./pages/ContactUs";
import EditPaymentProfile from "./pages/EditPaymentProfile";
import EditProfile from "./pages/EditProfile";
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import MyAdvertisements from "./pages/MyAdvertisements";
import MyDocuments from "./pages/MyDocuments";
import MyTransports from "./pages/MyTransports";
import MyVehicles from "./pages/MyVehicles";
import OfferCargoDetail from "./pages/OfferCargoDetail";
import OfferSuccessMessage from "./pages/OfferSuccessMessage";
import OfferVehicleDetail from "./pages/OfferVehicleDetail";
import Offers from "./pages/Offers";
import OnboardingScreen from "./pages/OnboardingScreen";
import PaymentFail from "./pages/PaymentFail";
import PaymentSuccess from "./pages/PaymentSuccess";
import PostCargoListing from "./pages/PostCargoListing";
import PostVehicleListing from "./pages/PostVehicleListing";
import PrivateRoute from "./pages/PrivateRoute";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SplashScreen from "./pages/SplashScreen";
import SuccessMessage from "./pages/SuccessMessage";
import UploadFileMessage from "./pages/UploadFileMessage";
import VehicleDocuments from "./pages/VehicleDocuments";
import EditAddress from "./pages/EditAddress";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 425px;
  margin: 0px auto;
`;

const App = () => {
  return (
    <Provider store={store}>
      <StyledWrapper>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route path="/home" />
          </Route>
          <Route path="/loading" element={<SplashScreen />} />
          <Route path="/onboard" element={<OnboardingScreen />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<SignUp />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/post-load"
            element={
              <PrivateRoute>
                <PostCargoListing />
              </PrivateRoute>
            }
          />
          <Route
            path="/post-vehicle"
            element={
              <PrivateRoute>
                <PostVehicleListing />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-vehicle/:id"
            element={
              <PrivateRoute>
                <OfferVehicleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-vehicle/:id/:backUrl"
            element={
              <PrivateRoute>
                <OfferVehicleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-vehicle/:id/:confirm/:notes/:amount/:offerId"
            element={
              <PrivateRoute>
                <OfferVehicleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-load/:id"
            element={
              <PrivateRoute>
                <OfferCargoDetail />
              </PrivateRoute>
            }
          />{" "}
          <Route
            path="/offer-load/:id/:backUrl"
            element={
              <PrivateRoute>
                <OfferCargoDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-load/:id/:confirm/:notes/:amount/:offerId"
            element={
              <PrivateRoute>
                <OfferCargoDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-documents"
            element={
              <PrivateRoute>
                <MyDocuments />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-vehicles"
            element={
              <PrivateRoute>
                <MyVehicles />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-vehicles/:id"
            element={
              <PrivateRoute>
                <MyVehicles />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle-documents/:v"
            element={
              <PrivateRoute>
                <VehicleDocuments />
              </PrivateRoute>
            }
          />
          <Route path="/upload-file-message" element={<UploadFileMessage />} />
          <Route
            path="/contact-us"
            element={
              <PrivateRoute>
                <ContactUs />
              </PrivateRoute>
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/offer-success-message"
            element={
              <PrivateRoute>
                <OfferSuccessMessage />
              </PrivateRoute>
            }
          />
          <Route
            path="/offer-success-message/:id"
            element={
              <PrivateRoute>
                <OfferSuccessMessage />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-vehicle"
            element={
              <PrivateRoute>
                <AddVehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/offers"
            element={
              <PrivateRoute>
                <Offers />
              </PrivateRoute>
            }
          />
          <Route
            path="/my-advertisements"
            element={
              <PrivateRoute>
                <MyAdvertisements />
              </PrivateRoute>
            }
          />
          <Route
            path="/success-message/:id/:title/:message/:button"
            element={<SuccessMessage />}
          />
          <Route
            path="/success-message/:id/:title/:message/:button/:url"
            element={<SuccessMessage />}
          />
          <Route
            path="/success-message/:id/:title/:message/:button/:url/:urlParams"
            element={<SuccessMessage />}
          />
          <Route
            path="/my-transports"
            element={
              <PrivateRoute>
                <MyTransports />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-address"
            element={
              <PrivateRoute>
                <EditAddress />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-payment-profile"
            element={
              <PrivateRoute>
                <EditPaymentProfile />
              </PrivateRoute>
            }
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route
            path="/auth/confirm-user/:token"
            element={<ConfirmUser />}
          />{" "}
          <Route
            path="/auth/reset-password/:token"
            element={<ResetPassword />}
          />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/fail" element={<PaymentFail />} />
        </Routes>
        {/* <SplashScreen />
      <OnboardingScreen />
      <SignIn />
      <SignUp /> */}
      </StyledWrapper>
    </Provider>
  );
};

export default App;
