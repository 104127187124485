import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setUser } from "../features/user/userSlice";

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("_ygu");
  if (user?.token?.accessToken?.length === 0 && jwt) {
    dispatch(setUser(JSON.parse(jwt)));
  }
  return jwt ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
