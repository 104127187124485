import React from "react";

function VerticalRoadIcon() {
  return (
    <svg
      width="12"
      height="77"
      viewBox="0 0 12 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3V74"
        stroke="#3A3A3A"
        strokeOpacity="0.6"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <circle cx="6" cy="57.5" r="6" fill="#05C101" />
      <circle cx="6" cy="16.5" r="6" fill="#084BBB" />
      <circle cx="6" cy="16.5" r="6" stroke="white" />
    </svg>
  );
}

export default VerticalRoadIcon;
