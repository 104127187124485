import api from "../config/api";

const vehicleAdvertisementListRequest = async (pagination) => {
  const res = await api.get(
    `advertisement/list/vehicle-advertisements?page=${pagination.page}&limit=${pagination.limit}`
  );
  return res?.data;
};

export default vehicleAdvertisementListRequest;
