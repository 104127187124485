import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ArrowBackIos } from "@mui/icons-material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { Grid } from "@mui/material";
import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import UploadCard from "../components/UploadCard";

import createUserDocumentRequest from "../api/createUserDocumentRequest";
import getUserDocumentsRequest from "../api/getUserDocuments";
import getUserSignedDocument from "../api/getUserSignedDocument";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding-top: 80px;
  background-color: #d4d6dc7d;
  height: 100vh;
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
`;

const documentTypes = [
  {
    id: 5,
    value: "Vergi Levhası",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
  {
    id: 9,
    value: "Diğer",
    type: 3,
    icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
  },
];

const MyDocuments = () => {
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedtab] = useState(0);
  const navigate = useNavigate();
  const [document, setDocument] = useState();
  const hiddenFileInput = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [documents, setDocuments] = useState(documentTypes);

  useEffect(() => {
    loadMyDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMyDocuments = async () => {
    const result = await getUserDocumentsRequest();

    if (result?.data?.length > 0) {
      result?.data.forEach((document, index) => {
        let list = [...documents];
        let doc = list.find((q) => q.id === document.documentTypeId);
        if (doc) {
          doc.url = document?.documentPath;
          doc.statusText = document?.documentStatus;
          doc.documentId = document?.id;
          setDocuments(list);
        }
      });
    } else {
      // * This part cleans previous documents
      const converted = documentTypes.map((item, value) => ({
        id: item.id,
        value: item.value,
        type: item.type,
        icon: <CameraAltOutlinedIcon sx={{ color: "#084BBB" }} />,
      }));
      setDocuments(converted);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  const selectDocumentHandler = (document) => {
    setDocument(document);
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    const fileUploaded = event.target.files[0];

    if (!isFileValid(fileUploaded)) {
      return;
    }

    var formdata = new FormData();
    formdata.append("userId", user.id);
    formdata.append("documentTypeId", document?.id);
    formdata.append("file", event.target.files[0], event.target.files[0]?.name);

    const result = await createUserDocumentRequest(formdata);

    if (result?.code === 200) {
      setSuccess();
      loadMyDocuments();
      setTimeout(() => setShowAlert(false), 3000);
    } else {
      setError();
      setTimeout(() => setShowAlert(false), 3000);
    }
  };

  const isFileValid = (fileUploaded) => {
    if (fileUploaded && fileUploaded["type"].split("/")[0] !== "image") {
      alert("Dosya görsel yüklenmesi gerekmektedir!");
      return false;
    }

    if (fileUploaded?.size > 1024 * 1024 * 15) {
      alert("Dosya boyutu en fazla 15 MB olması gerekmektedir!");
      return false;
    }

    return true;
  };

  const handleViewDocument = async (id) => {
    const result = await getUserSignedDocument(id);

    if (result?.code === 200) {
      window.open(result?.data);
    } else {
      setError();
      setTimeout(() => setShowAlert(false), 3000);
    }
  };

  return (
    <StyledWrapper>
      <NavBar header={"Belgelerim"} showLogo={false} />
      <ContentWrapper>
        {/* <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} /> */}
        <StyledCardsWrapper>
          <>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </>
          {showAlert && (
            <AlertBox
              severity={showAlertDetail?.severity}
              title={showAlertDetail?.title}
              message={showAlertDetail?.message}
            />
          )}
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Profilim"}
              icon={<ArrowBackIos />}
              onClick={() => {
                navigate("/profile");
              }}
            />
            {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
          </Grid>
          {documents && documents?.length > 0
            ? documents.map((document, index) => (
                <UploadCard
                  key={index}
                  document={document}
                  selectDocumentHandler={selectDocumentHandler}
                  handleViewDocument={handleViewDocument}
                />
              ))
            : null}
        </StyledCardsWrapper>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default MyDocuments;
