import WestIcon from "@mui/icons-material/West";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { updateUser } from "../features/user/userSlice";

import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import DefaultPhoneField from "../components/UI/DefaultPhoneField";
import * as validators from "../utils/validators";

import updateUserRequest from "../api/updateUserRequest";
import * as utils from "../utils/utils";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: #d4d6dc7d; */
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 80px 0 80px 0;
  height: 100%;
  min-height: 100vh;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  color: #ffffff;
`;

const EditProfile = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([
    {
      key: "name",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "surname",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "phone",
      value: null,
      type: "phone",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    let val = formData.find((q) => q.key === "name");
    val.value = user?.user?.name;

    val = formData.find((q) => q.key === "surname");
    val.value = user?.user?.surname;

    val = formData.find((q) => q.key === "phone");
    val.value = user?.user?.phone;

    setFormData([...formData]);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  const handleUpdateUser = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      name: utils.convertFirstLetterToUpperCaseAndRestToLowerCase(
        formData.find((q) => q.key === "name").value
      ),
      surname: utils.convertFirstLetterToUpperCaseAndRestToLowerCase(
        formData.find((q) => q.key === "surname").value
      ),
      phone: formData.find((q) => q.key === "phone").value,
      userId: user.id,
    };

    const result = await updateUserRequest(request);

    if (result?.code === 200) {
      setSuccess("Güncelleme işleminiz gerçekleştirildi.");
      setTimeout(() => {
        let data = {
          name: request.name,
          phone: request.phone,
          surname: request.surname,
        };

        dispatch(updateUser(data));
        localStorage.setItem("_ygu", JSON.stringify(user));

        let val = formData.find((q) => q.key === "name");
        val.value = request.name;
        setFormData([...formData]);

        val = formData.find((q) => q.key === "surname");
        val.value = request.surname;
        setFormData([...formData]);

        setIsLoading(false);
        setShowAlert(false);
      }, 1000);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <StyledWrapper>
      <NavBar header={"Kişisel Bilgilerim"} showLogo={false} />
      <ContentWrapper>
        <StyledGridContainer
          container
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Profilim"}
              icon={<WestIcon />}
              onClick={() => {
                navigate("/profile");
              }}
            />
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="name"
                  type={"text"}
                  label="İsim"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={formData.find((q) => q.key === "name").value ?? ""}
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "name");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "name").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "name").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="İsim 2 karakterden fazla olmalıdır"
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="surname"
                  type={"text"}
                  label="Soyisim"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={formData.find((q) => q.key === "surname").value ?? ""}
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "surname");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "surname").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "surname").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Soyisim 2 karakterden fazla olmalıdır"
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultPhoneField
                  id="phone"
                  label=""
                  backgroundColor="#E5E5E587"
                  placeholderText="+90 (5XX) XXX XX XX"
                  value={formData.find((q) => q.key === "phone").value}
                  error={
                    isSubmit &&
                    !validators.checkIsValidPhone(
                      formData.find((q) => q.key === "phone").value
                    )
                  }
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "phone");
                    val.value = e;
                    val.hasError = !validators.checkIsValidPhone(e);
                    setFormData([...formData]);
                  }}
                />
                {isSubmit &&
                  !validators.checkIsValidPhone(
                    formData.find((q) => q.key === "phone").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Lütfen geçerli bir telefon numarası giriniz."
                    />
                  )}
              </Grid>
              <Grid item xs={12}>
                <DefaultInputFeild
                  id="email"
                  type={"text"}
                  label="E-posta"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={user?.user?.email}
                  disabled={true}
                />
              </Grid>
              <Grid>
                <DefaultButton
                  size={"small"}
                  variant={"contained"}
                  buttonColor={"#084BBB"}
                  buttonTextColor={"#FFFFFF"}
                  buttonTextTransform={"none"}
                  buttonMinHeight={48}
                  buttonRadius={"10px"}
                  fullWidth={true}
                  buttonText={<StyledButtontext>Kaydet</StyledButtontext>}
                  onClick={() => handleUpdateUser()}
                />
              </Grid>
            </>
          )}
          {showAlert ? (
            <Grid item xs={12}>
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
        </StyledGridContainer>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default EditProfile;
