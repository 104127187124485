import React from "react";

function OfferVehicleRoadIcon() {
  return (
    <svg
      width="336"
      height="32"
      viewBox="0 0 336 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 23H333"
        stroke="black"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <circle
        cx="198.5"
        cy="22.5"
        r="8.5"
        fill="#084BBB"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="326" cy="22" r="10" fill="#05C101" />
      <path
        d="M22 2.19212H9V0H7V19.7291H6V21.9212H10V19.7291H9V14.2488H22C22.2652 14.2488 22.5196 14.1333 22.7071 13.9277C22.8946 13.7222 23 13.4434 23 13.1527V3.28818C23 2.99748 22.8946 2.7187 22.7071 2.51315C22.5196 2.3076 22.2652 2.19212 22 2.19212Z"
        fill="black"
      />
    </svg>
  );
}

export default OfferVehicleRoadIcon;
