export const capitalizeText = (str) =>
  str
    .toLocaleLowerCase("tr")
    .split(" ")
    .map((word) => word.charAt(0).toLocaleUpperCase("tr") + word.slice(1))
    .join(" ");

export const convertSimpleDateTimeFormat = (date) => {
  if (date) {
    return new Date(date)
      .toISOString()
      .replace("T", " ")
      .replace("Z", "")
      .replace(".000", "");
  } else {
    return null;
  }
};

export const formatDateToLocaleString = (date) => {
  if (date) {
    var offset = new Date().getTimezoneOffset();
    let formattedDate = new Date(date);
    formattedDate = new Date(
      formattedDate.getFullYear(),
      formattedDate.getMonth(),
      formattedDate.getDate(),
      formattedDate.getHours(),
      formattedDate.getMinutes()
    );
    formattedDate.setTime(formattedDate.getTime() - offset * 60 * 1000);
    return formattedDate;
  } else {
    return null;
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertSeperator = (value) => {
  if (value) {
    return value?.replace(".", ",");
  }
};

export const convertFirstLetterToUpperCaseAndRestToLowerCase = (value) => {
  if (value) {
    return value
      .toLocaleLowerCase("tr")
      .split(" ")
      .map(
        (word) =>
          word.charAt(0).toLocaleUpperCase("tr") +
          word.slice(1).toLocaleLowerCase("tr")
      )
      .join(" ");
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  var result = phoneNumberString;
  if (phoneNumberString && phoneNumberString?.length > 0) {
    if (phoneNumberString.startsWith("90")) {
      result = `+90 (${phoneNumberString.substring(
        2,
        5
      )}) ${phoneNumberString.substring(5, 8)} ${phoneNumberString.substring(
        8,
        10
      )} ${phoneNumberString.substring(10, 12)}`;
    }
  }
  return result;
};

export const encodeJson = (data) => {
  return encodeURIComponent(JSON.stringify(data));
};

export const decodeJson = (data) => {
  return JSON.parse(decodeURIComponent(data));
};
