import { Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

const StyledBackButton = styled(Button)`
  &.MuiButton-text {
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 130% !important;
    text-transform: none !important;
    gap: 10px !important;
    color: #191d31 !important;
  }
`;

const DefaultBackButton = ({ icon, label, onClick }) => {
  return (
    <StyledBackButton onClick={onClick}>
      {icon} {label}
    </StyledBackButton>
  );
};

export default DefaultBackButton;
