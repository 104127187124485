import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CargoCard from "../components/CargoCard";
import CustomTabs from "../components/CustomTabs";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import VehicleCard from "../components/VehicleCard";

import getUserAdvertisements from "../api/getUserAdvertisements";
import EmptyState from "../components/EmptyState";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
`;

const TabOptions = [
  { label: "Mevcut İlanlarım", id: 1 },
  { label: "İptal Edilen İlanlarım", id: 2 },
];

const MyAdvertisements = () => {
  const [selectedTab, setSelectedtab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadLoadAdvertisements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const loadLoadAdvertisements = async () => {
    let filter = {
      advertisementStatus: selectedTab === 0 ? [1, 6, 7] : [2, 3],
    };

    const result = await getUserAdvertisements(filter);

    if (result?.code === 200) {
      setAdvertisements(result.data?.items ?? []);
    } else {
      console.log("error:", result);
    }

    setIsLoading(false);
  };

  const generateAdvertisement = (advertisement) => {
    if (advertisement?.advertisementTypeId === 1) {
      return (
        <CargoCard
          load={advertisement}
          hideFavorite={true}
          backUrl={"/my-advertisements"}
        />
      );
    } else {
      return (
        <VehicleCard
          vehicle={advertisement}
          hideFavorite={true}
          backUrl={"/my-advertisements"}
        />
      );
    }
  };

  return (
    <StyledWrapper>
      <NavBar />
      <StyledContainer>
        <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} />
        {isLoading ? <Loader /> : null}
        {selectedTab === 0 &&
          (advertisements && advertisements?.length > 0 ? (
            advertisements?.map((advertisement, index) => (
              <React.Fragment key={index}>
                {generateAdvertisement(advertisement)}
              </React.Fragment>
            ))
          ) : (
            <EmptyState />
          ))}
        {selectedTab === 1 &&
          (advertisements && advertisements?.length > 0 ? (
            advertisements?.map((advertisement, index) => (
              <React.Fragment key={index}>
                {generateAdvertisement(advertisement)}
              </React.Fragment>
            ))
          ) : (
            <EmptyState />
          ))}
      </StyledContainer>
      <BottomMenu selected={"Home"} />
    </StyledWrapper>
  );
};

export default MyAdvertisements;
