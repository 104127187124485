import WestIcon from "@mui/icons-material/West";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

import AlertBox from "../components/Alert";
import BottomMenu from "../components/Layout/BottomMenu";
import NavBar from "../components/Layout/NavBar";
import Loader from "../components/Loader";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import * as validators from "../utils/validators";

import { InputAdornment } from "@material-ui/core";
import DefaultSelect from "../components/UI/DefaultSelect";
import createUserDealer from "../api/createUserDealer";
import getUserDealer from "../api/getUserDealer";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: #d4d6dc7d; */
  background-color: #fff;
`;

const ContentWrapper = styled.div`
  padding: 80px 0 80px 0;
  height: 100%;
  min-height: 100vh;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  color: #ffffff;
`;

const EditPaymentProfile = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showInformation, setShowInformation] = useState(true);
  const [hasDealer, setHasDealer] = useState(false);
  const [dealerCode, setDealerCode] = useState("");
  const dealerTypes = [
    // ? not accepted dealer types
    // {
    //   id: 1,
    //   label: "Gerçek Kişi (Vergi Levhası olmayan)",
    // },
    {
      id: 2,
      label: "Tüzel Kişi (Ltd.A.Ş)",
    },
    {
      id: 3,
      label: "Tüzel Kişi (Şahıs Firması)",
    },
  ];
  const [formData, setFormData] = useState([
    {
      key: "address",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "iBan",
      value: null,
      type: "isValidIban",
      required: true,
      hasError: false,
    },
    {
      key: "iBanFullName",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "dealerType",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "auhthorityFullName",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "auhthorityTckn",
      value: null,
      type: "tckn",
      required: true,
      hasError: false,
    },
    {
      key: "companyName",
      value: null,
      type: "textArea",
      required: true,
      hasError: false,
    },
    {
      key: "companyVkn",
      value: null,
      type: "vkn",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    const result = await getUserDealer(user.id);

    if (result?.code === 200) {
      if (result?.data?.userDealer === null) {
        if (result?.data?.user !== null) {
          let val = formData.find((q) => q.key === "auhthorityFullName");
          val.value = `${result?.data?.user?.name} ${result?.data?.user?.surname}`;
          val = formData.find((q) => q.key === "auhthorityTckn");
          val.value = result?.data?.user?.tckn;
          val = formData.find((q) => q.key === "companyName");
          val.value = result?.data?.user?.companyName;
          val = formData.find((q) => q.key === "companyVkn");
          val.value = result?.data?.user?.companyVkn;

          setFormData([...formData]);
        }
        setHasDealer(false);
        setIsLoading(false);
        return;
      }

      setHasDealer(true);

      let val = formData.find((q) => q.key === "address");
      val.value = result?.data?.userDealer?.address;
      val = formData.find((q) => q.key === "iBan");
      val.value = result?.data?.userDealer?.iBan;
      val = formData.find((q) => q.key === "iBanFullName");
      val.value = result?.data?.userDealer?.iBanFullName;
      val = formData.find((q) => q.key === "dealerType");
      val.value = result?.data?.userDealer?.dealerType;
      val = formData.find((q) => q.key === "auhthorityFullName");
      val.value = result?.data?.userDealer?.auhthorityFullName;
      val = formData.find((q) => q.key === "auhthorityTckn");
      val.value = result?.data?.userDealer?.auhthorityTckn;
      val = formData.find((q) => q.key === "companyName");
      val.value = result?.data?.userDealer?.companyName;
      val = formData.find((q) => q.key === "companyVkn");
      val.value = result?.data?.userDealer?.companyVkn;

      setDealerCode(result?.data?.userDealer?.dealerCode?.toString() ?? "");

      setFormData([...formData]);
    }
    setIsLoading(false);
  };

  const handleUpdateUser = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    let request = {
      address: formData.find((q) => q.key === "address").value ?? "",
      iBan: formData.find((q) => q.key === "iBan").value ?? "",
      iBanFullName: formData.find((q) => q.key === "iBanFullName").value ?? "",
      userId: user.id,
      dealerType: formData.find((q) => q.key === "dealerType").value ?? "",
      auhthorityFullName:
        formData.find((q) => q.key === "auhthorityFullName").value ?? "",
      auhthorityTckn: formData.find((q) => q.key === "auhthorityTckn").value,
      companyName: formData.find((q) => q.key === "companyName").value ?? "",
      companyVkn: formData.find((q) => q.key === "companyVkn").value ?? "",
    };

    const result = await createUserDealer(request);

    if (result?.code === 200) {
      loadData();
      setSuccess("Alt bayi oluşturma işleminiz gerçekleştirildi.");
      setTimeout(() => {
        setIsLoading(false);
        setShowAlert(false);
      }, 3000);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  return (
    <StyledWrapper>
      <NavBar header={"Ödeme Bilgilerim"} showLogo={false} />
      <ContentWrapper>
        <StyledGridContainer
          container
          item
          xs={12}
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Profilim"}
              icon={<WestIcon />}
              onClick={() => {
                navigate("/profile");
              }}
            />
          </Grid>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {showInformation && !hasDealer ? (
                <AlertBox
                  severity={"info"}
                  title={"Önemli Hatırlatma"}
                  message={
                    "Bu bilgiler hizmetiniz başarıyla tamamlandıktan sonra hizmet bedelinin aktarılacağı hesap bilgileridir."
                  }
                />
              ) : null}

              <Grid
                item
                xs={12}
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
              >
                <DefaultSelect
                  id={"dealerType"}
                  disabled={hasDealer}
                  label={"Şirket Tipi"}
                  options={dealerTypes}
                  backgroundColor="#E5E5E587"
                  variant="outlined"
                  value={formData.find((q) => q.key === "dealerType").value}
                  onChange={(option) => {
                    let val = formData.find((q) => q.key === "dealerType");
                    val.value = option?.id;
                    val.hasError = !validators.checkIsValidSelect(option?.id);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidSelect(
                      formData.find((q) => q.key === "dealerType").value
                    )
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="auhthorityFullName"
                  disabled={hasDealer}
                  type={"text"}
                  label="Yetkili Kişi Tam Adı"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={
                    formData.find((q) => q.key === "auhthorityFullName")
                      .value ?? ""
                  }
                  onChange={(e) => {
                    let val = formData.find(
                      (q) => q.key === "auhthorityFullName"
                    );
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "auhthorityFullName").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "auhthorityFullName").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Yetkili Kişi adı 2 karakterden fazla olmalıdır"
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="auhthorityTckn"
                  disabled={hasDealer}
                  type={"text"}
                  label="Yetkili Kişi TCKN"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={
                    formData.find((q) => q.key === "auhthorityTckn").value ?? ""
                  }
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "auhthorityTckn");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidTurkishIdentity(
                      e.target.value
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidTurkishIdentity(
                      formData.find((q) => q.key === "auhthorityTckn").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidTurkishIdentity(
                    formData.find((q) => q.key === "auhthorityTckn").value
                  ) && <AlertBox severity="error" message="Geçersiz TCKN" />}
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="companyName"
                  disabled={hasDealer}
                  type={"text"}
                  label="Şirket Adı"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={
                    formData.find((q) => q.key === "companyName").value ?? ""
                  }
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "companyName");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "companyName").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "companyName").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Şirket adı 2 karakterden fazla olmalıdır"
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="companyVkn"
                  disabled={hasDealer}
                  type={"text"}
                  label="Şirket Vergi Numarası"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={
                    formData.find((q) => q.key === "companyVkn").value ?? ""
                  }
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "companyVkn");
                    val.value = e.target.value;
                    val.hasError =
                      !validators.checkIsValidTurkishCompanyIdentity(
                        e.target.value
                      );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidTurkishCompanyIdentity(
                      formData.find((q) => q.key === "companyVkn").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidTurkishCompanyIdentity(
                    formData.find((q) => q.key === "companyVkn").value
                  ) && <AlertBox severity="error" message="Geçersiz VKN" />}
              </Grid>

              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="address"
                  disabled={hasDealer}
                  type={"text"}
                  label="Adres"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={formData.find((q) => q.key === "address").value ?? ""}
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "address");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "address").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "address").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Adres 2 karakterden fazla olmalıdır"
                    />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="iBan"
                  disabled={hasDealer}
                  type={"number"}
                  label="IBAN"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={formData.find((q) => q.key === "iBan").value ?? ""}
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "iBan");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidIban(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidIban(
                      formData.find((q) => q.key === "iBan").value
                    )
                  }
                  inputProps={{ maxLength: 26 }}
                  startAdornment={
                    <InputAdornment position="start">TR</InputAdornment>
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidIban(
                    formData.find((q) => q.key === "iBan").value
                  ) && (
                    <AlertBox severity="error" message="IBAN bilgisi hatalı" />
                  )}
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="column"
                gap="20px"
              >
                <DefaultInputFeild
                  id="iBanFullName"
                  disabled={hasDealer}
                  type={"text"}
                  label="IBAN Ad Soyad"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  value={
                    formData.find((q) => q.key === "iBanFullName").value ?? ""
                  }
                  onChange={(e) => {
                    let val = formData.find((q) => q.key === "iBanFullName");
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidText(e.target.value);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidText(
                      formData.find((q) => q.key === "iBanFullName").value
                    )
                  }
                />
                {isSubmit &&
                  !validators.checkIsValidText(
                    formData.find((q) => q.key === "iBanFullName").value
                  ) && (
                    <AlertBox
                      severity="error"
                      message="Ad Soyad bilgisi hatalı"
                    />
                  )}
              </Grid>
              {hasDealer ? (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                >
                  <DefaultInputFeild
                    id="dealerCode"
                    disabled={hasDealer}
                    type={"text"}
                    label="Bayi Kodu"
                    variant="filled"
                    backgroundColor="#E5E5E587"
                    value={dealerCode ?? "-"}
                  />
                </Grid>
              ) : null}
              {hasDealer ? null : (
                <Grid>
                  <DefaultButton
                    size={"small"}
                    variant={"contained"}
                    buttonColor={"#084BBB"}
                    buttonTextColor={"#FFFFFF"}
                    buttonTextTransform={"none"}
                    buttonMinHeight={48}
                    buttonRadius={"10px"}
                    fullWidth={true}
                    buttonText={
                      <StyledButtontext>Alt Bayi Oluştur</StyledButtontext>
                    }
                    onClick={() => handleUpdateUser()}
                  />
                </Grid>
              )}
            </>
          )}
          {showAlert ? (
            <Grid item xs={12}>
              <AlertBox
                severity={showAlertDetail?.severity}
                title={showAlertDetail?.title}
                message={showAlertDetail?.message}
              />
            </Grid>
          ) : null}
        </StyledGridContainer>
      </ContentWrapper>
      <BottomMenu />
    </StyledWrapper>
  );
};

export default EditPaymentProfile;
