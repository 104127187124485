import WestIcon from "@mui/icons-material/West";
import { Grid, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NavBar from "../components/Layout/NavBar";
import DefaultBackButton from "../components/UI/DefaultBackButton";
import DefaultButton from "../components/UI/DefaultButton";
import DefaultCheckbox from "../components/UI/DefaultCheckbox";
import DefaultDatepicker from "../components/UI/DefaultDatepicker";
import DefaultInputFeild from "../components/UI/DefaultInputFeild";
import DefaultSelect from "../components/UI/DefaultSelect";
import * as validators from "../utils/validators";

import cityListRequest from "../api/cityListRequest";
import countyListRequest from "../api/countyListRequest";
import createAdvertisementRequest from "../api/createAdvertisementRequest";
import getUserVehicles from "../api/getUserVehicles";
import loadCapacityTypeListRequest from "../api/loadCapacityTypeListRequest";
import AlertBox from "../components/Alert";
import moment from "moment";

const ContentWrapper = styled.div`
  padding: 80px 0;
`;

const StyledGridContainer = styled(Grid)`
  gap: 20px 0px;
  padding: 20px;
`;

const StyledCheckboxWrapper = styled.div`
  align-self: left;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
`;

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const optionsParsiyel = [
  {
    id: 1,
    label: "Evet",
  },
  {
    id: 2,
    label: "Hayır",
  },
];

const PostVehicleListing = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDetail, setShowAlertDetail] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [materials, setMaterials] = useState([]);
  const [loads, setLoads] = useState([]);
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);
  const [destinationCounties, setDestinationCounties] = useState([]);
  // const [vehicles, setVehicles] = useState([]);
  const [userVehicles, setUserVehicles] = useState([]);
  const [formData, setFormData] = useState([
    {
      key: "header",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "description",
      value: null,
      type: "text",
      required: true,
      hasError: false,
    },
    {
      key: "loadAmount",
      value: 0,
      type: "number",
      required: true,
      hasError: false,
    },
    {
      key: "originCityId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "originCountyId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "destinationCityId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "destinationCountyId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "closeDate",
      value: null,
      type: "date",
      required: true,
      hasError: false,
    },
    {
      key: "isPartialLoad",
      value: 2,
      type: "select",
      required: false,
      hasError: false,
    },
    {
      key: "loadUnitId",
      value: 1,
      type: "select",
      required: true,
      hasError: false,
    },
    {
      key: "loadCapacityId",
      value: null,
      type: "select",
      required: false,
      hasError: false,
    },
    {
      key: "partialLoadNumber",
      value: null,
      type: "number",
      required: false,
      hasError: false,
    },
    {
      key: "partialLoadWidth",
      value: null,
      type: "number",
      required: false,
      hasError: false,
    },
    {
      key: "partialLoadHeight",
      value: null,
      type: "number",
      required: false,
      hasError: false,
    },
    {
      key: "partialLoadLength",
      value: null,
      type: "number",
      required: false,
      hasError: false,
    },
    {
      key: "condition1",
      value: null,
      type: "confirm",
      required: true,
      hasError: false,
    },
    {
      key: "note",
      value: null,
      type: "text",
      required: false,
      hasError: false,
    },
    {
      key: "vehicleId",
      value: null,
      type: "select",
      required: true,
      hasError: false,
    },
  ]);

  useEffect(() => {
    loadUserVehicles();
    loadLoadTypes();
    loadtCities();
    // loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCounties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.find((q) => q.key === "originCityId")?.value]);

  useEffect(() => {
    loadDestinationCounties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.find((q) => q.key === "destinationCityId")?.value]);

  const setError = (err, title) => {
    setShowAlertDetail({
      severity: "error",
      title: title ?? "Hata",
      message: err ?? "Bir hata oluştu, lütfen daha sonra tekrar deneyiniz.",
    });
    setShowAlert(true);
  };

  const setSuccess = (err, title) => {
    setShowAlertDetail({
      severity: "success",
      title: title ?? "İşlem Başarılı",
      message: err ?? "İşleminiz başarıyla gerçekleştirildi.",
    });
    setShowAlert(true);
  };

  const loadtCities = async () => {
    const result = await cityListRequest();

    if (result?.code === 200) {
      const converted = result.data.map((item, value) => ({
        id: item.id,
        label: item.name,
      }));
      setCities(converted ?? []);
    } else {
      console.log("error:", result);
    }
  };

  const loadCounties = async () => {
    const cityId = formData.find((q) => q.key === "originCityId")?.value;

    if (cityId && cityId > 0) {
      const result = await countyListRequest(cityId);

      if (result?.code === 200) {
        const converted = result.data.map((item, value) => ({
          id: item.id,
          label: item.name,
        }));

        setCounties(converted ?? []);
      } else {
        console.log("error:", result);
      }
    } else {
      setCounties([]);
    }
  };

  const loadDestinationCounties = async () => {
    const cityId = formData.find((q) => q.key === "destinationCityId")?.value;

    if (cityId && cityId > 0) {
      const result = await countyListRequest(cityId);

      if (result?.code === 200) {
        const converted = result.data.map((item, value) => ({
          id: item.id,
          label: item.name,
        }));

        setDestinationCounties(converted ?? []);
      } else {
        console.log("error:", result);
      }
    } else {
      setDestinationCounties([]);
    }
  };

  // const loadVehicles = async () => {
  //   const result = await vehicleListRequest();

  //   if (result?.code === 200) {
  //     const converted = result.data.map((item, value) => ({
  //       id: item.id,
  //       label: item.value,
  //     }));
  //     setVehicles(converted ?? []);
  //   } else {
  //     console.log("error:", result);
  //   }
  // };

  const loadLoadTypes = async () => {
    const result = await loadCapacityTypeListRequest();

    if (result?.code === 200) {
      const converted = result.data.map((item, value) => ({
        id: item.id,
        label: item.value,
      }));
      setLoads(converted ?? []);
    } else {
      console.log("error:", result);
    }
  };

  const loadUserVehicles = async () => {
    const result = await getUserVehicles();

    if (result?.code === 200) {
      const converted = result.data.map((item, value) => ({
        id: item.id,
        label: item?.plateNo,
        value: item?.loadCapacityAmount,
      }));
      setUserVehicles(converted ?? []);

      // * If user has no vehicle, redirect user to information page
      if (!(converted?.length > 0)) {
        setError("Tanımlı aracınız bulunmamaktadır!.");
        let params = {
          type: "vehicle-error",
          title: "Tanımlı Aracınız Bulunmamaktadır",
          message: "Araç Ekle'ye basarak araç ekleme sayfasına gidebilirsiniz.",
          button: "Araç Ekle",
          url: "add-vehicle",
        };

        setTimeout(() => {
          navigate(
            `/success-message/${params.type}/${params.title}/${params.message}/${params.button}/${params.url}`
          );
          setIsLoading(false);
        }, 1);
      }
    } else {
      console.log("error:", result);
    }

    setIsLoading(false);
  };

  const handleCreateAdvertisement = async () => {
    setIsSubmit(true);
    setIsLoading(true);

    if (!validators.isFormValid(formData)) {
      setIsLoading(false);
      return;
    }

    var isPartialLoading =
      formData.find((q) => q.key === "isPartialLoad")?.value === 1
        ? true
        : false;

    let closeDate = new Date(formData.find((q) => q.key === "closeDate").value);

    let request = {
      header: formData.find((q) => q.key === "header").value,
      description: formData.find((q) => q.key === "description").value,
      vehicleId: formData.find((q) => q.key === "vehicleId").value,
      loadAmount: formData.find((q) => q.key === "loadAmount").value,
      // * Load Unit Default value "ton"
      loadUnitId: 1,
      isPartialLoad: isPartialLoading,
      note: formData.find((q) => q.key === "note").value ?? "",
      ownerUserId: user.id,
      originCityId: formData.find((q) => q.key === "originCityId").value,
      originCountyId: formData.find((q) => q.key === "originCountyId").value,
      destinationCityId: formData.find((q) => q.key === "destinationCityId")
        .value,
      destinationCountyId: formData.find((q) => q.key === "destinationCountyId")
        .value,
      // ? status and type id may refactor with enum values
      advertisementStatusId: 1,
      // * Advertisement Type 2 (Vehicle)
      advertisementTypeId: 2,
      materialTypeId: null,
      loadTypeId: null,
      closeDate: moment(closeDate).format("YYYY-MM-DD"),
    };

    // * If Parsiyel state is true/exist append parsiyel paramaters to request.
    if (isPartialLoading) {
      request.partialLoadWidth = formData.find(
        (q) => q.key === "partialLoadWidth"
      ).value;
      request.partialLoadHeight = formData.find(
        (q) => q.key === "partialLoadHeight"
      ).value;
      request.partialLoadLength = formData.find(
        (q) => q.key === "partialLoadLength"
      ).value;
      request.partialLoadNumber = formData.find(
        (q) => q.key === "partialLoadNumber"
      ).value;
      request.loadCapacityId = formData.find(
        (q) => q.key === "loadCapacityId"
      ).value;
    }

    const result = await createAdvertisementRequest(request);

    if (result?.code === 200) {
      setSuccess("Kayıt işleminiz gerçekleştirildi, lütfen bekleyin.");

      let params = {
        type: "vehicle",
        title: "Araç İlanınız Onaya Gönderilmiştir.",
        message:
          "yükline ekibi tarafından en kısa sürede değerlendirilecek ve tarafınıza bilgi verilecektir.",
        button: "Tamam",
      };

      setTimeout(() => {
        navigate(
          `/success-message/${params.type}/${params.title}/${params.message}/${params.button}`
        );
        setIsLoading(false);
      }, 1000);
    } else {
      setError();
      setIsLoading(false);
    }
  };

  const setRequired = (obj) => {
    if (obj) {
      obj.value = null;
      obj.hasError = true;
      obj.required = true;
    }
  };

  const setNotRequired = (obj) => {
    if (obj) {
      obj.value = null;
      obj.hasError = false;
      obj.required = false;
    }
  };

  return (
    <>
      <NavBar />
      <ContentWrapper>
        <StyledGridContainer container>
          <Grid item xs={12}>
            <DefaultBackButton
              label={"Araç İlanı Ver"}
              icon={<WestIcon />}
              onClick={() => {
                navigate("/");
              }}
            />
            {/* <StyledDescription>Lorem ipsum dolor sit amet</StyledDescription> */}
          </Grid>

          <Grid item xs={12}>
            <DefaultInputFeild
              id="header"
              type={"text"}
              label="İlan Başlığı"
              variant="filled"
              backgroundColor="#E5E5E587"
              value={formData.find((q) => q.key === "header").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "header");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextField(
                  e.target.value
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextField(
                  formData.find((q) => q.key === "header").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="description"
              type={"text"}
              label="İlan Açıklaması"
              variant="filled"
              backgroundColor="#E5E5E587"
              value={formData.find((q) => q.key === "description").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "description");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidTextField(
                  e.target.value
                );
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidTextField(
                  formData.find((q) => q.key === "description").value
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultSelect
              id={"vehicleId"}
              label={"Araçlarım"}
              options={userVehicles || []}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "vehicleId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "vehicleId");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);

                let maks = formData.find((q) => q.key === "loadAmount");
                maks.value = userVehicles?.find(
                  (q) => q.id === option?.id
                )?.value;
                val.hasError = !validators.checkIsValidNumber(maks.value);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "vehicleId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingRight: "2px" }}>
            <DefaultInputFeild
              id="loadAmount"
              type={"number"}
              label="Maks Ağırlığı"
              variant="filled"
              backgroundColor="#E5E5E587"
              onChange={(e) => {
                let val = formData.find((q) => q.key === "loadAmount");
                val.value = e.target.value;
                val.hasError = !validators.checkIsValidNumber(e.target.value);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidNumber(
                  formData.find((q) => q.key === "loadAmount").value
                )
              }
              value={formData.find((q) => q.key === "loadAmount")?.value}
              endAdornment={<InputAdornment position="end">Ton</InputAdornment>}
            />
          </Grid>
          {/* <Grid item xs={6} style={{ paddingLeft: "2px" }}>
            <DefaultSelect
              id={"loadUnitId"}
              label={"Birimi"}
              options={optionsUnit}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "loadUnitId")?.value}
              onChange={(option) => {}}
              disabled={true}
            />
          </Grid> */}
          <Grid item xs={12}>
            <DefaultSelect
              id={"isPartialLoad"}
              label={"Parsiyel"}
              options={optionsParsiyel}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "isPartialLoad").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "isPartialLoad");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);

                if (option?.id === 1) {
                  setRequired(formData.find((q) => q.key === "loadCapacityId"));
                  setRequired(
                    formData.find((q) => q.key === "partialLoadNumber")
                  );
                  setRequired(
                    formData.find((q) => q.key === "partialLoadWidth")
                  );
                  setRequired(
                    formData.find((q) => q.key === "partialLoadHeight")
                  );
                  setRequired(
                    formData.find((q) => q.key === "partialLoadLength")
                  );
                } else {
                  setNotRequired(
                    formData.find((q) => q.key === "loadCapacityId")
                  );
                  setNotRequired(
                    formData.find((q) => q.key === "partialLoadNumber")
                  );
                  setNotRequired(
                    formData.find((q) => q.key === "partialLoadWidth")
                  );
                  setNotRequired(
                    formData.find((q) => q.key === "partialLoadHeight")
                  );
                  setNotRequired(
                    formData.find((q) => q.key === "partialLoadLength")
                  );
                }
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "isPartialLoad").value
                )
              }
            />
          </Grid>
          {formData.find((q) => q.key === "isPartialLoad").value === 1 ? (
            <>
              {/* <Grid item xs={6} style={{ paddingRight: "2px" }}>
              <DefaultInputFeild
                id="partialLoadNumber"
                type={"text"}
                label="Yük Miktarı"
                variant="filled"
                backgroundColor="#E5E5E587"
                onChange={(e) => {
                  let val = formData.find((q) => q.key === "partialLoadNumber");
                  val.value = e.target.value;
                  val.hasError = !validators.checkIsValidNumber(e.target.value);
                  setFormData([...formData]);
                }}
                error={
                  isSubmit &&
                  !validators.checkIsValidNumber(
                    formData.find((q) => q.key === "partialLoadNumber").value
                  )
                }
              />
            </Grid> */}
              <Grid item xs={4} style={{ paddingRight: "2px" }}>
                <DefaultInputFeild
                  id="partialLoadWidth"
                  type={"number"}
                  label="Eni (cm)"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  onChange={(e) => {
                    let val = formData.find(
                      (q) => q.key === "partialLoadWidth"
                    );
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidNumber(
                      e.target.value
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidNumber(
                      formData.find((q) => q.key === "partialLoadWidth").value
                    )
                  }
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ paddingLeft: "2px", paddingRight: "2px" }}
              >
                <DefaultInputFeild
                  id="partialLoadHeight"
                  type={"number"}
                  label="Yüksekliği (cm)"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  onChange={(e) => {
                    let val = formData.find(
                      (q) => q.key === "partialLoadHeight"
                    );
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidNumber(
                      e.target.value
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidNumber(
                      formData.find((q) => q.key === "partialLoadHeight").value
                    )
                  }
                />
              </Grid>
              <Grid item xs={4} style={{ paddingLeft: "2px" }}>
                <DefaultInputFeild
                  id="partialLoadLength"
                  type={"number"}
                  label="Boyu (cm)"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  onChange={(e) => {
                    let val = formData.find(
                      (q) => q.key === "partialLoadLength"
                    );
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidNumber(
                      e.target.value
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidNumber(
                      formData.find((q) => q.key === "partialLoadLength").value
                    )
                  }
                />
              </Grid>

              <Grid item xs={6} style={{ paddingRight: "2px" }}>
                <DefaultInputFeild
                  id="partialLoadNumber"
                  type={"number"}
                  label="Adet"
                  variant="filled"
                  backgroundColor="#E5E5E587"
                  onChange={(e) => {
                    let val = formData.find(
                      (q) => q.key === "partialLoadNumber"
                    );
                    val.value = e.target.value;
                    val.hasError = !validators.checkIsValidNumber(
                      e.target.value
                    );
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidNumber(
                      formData.find((q) => q.key === "partialLoadNumber").value
                    )
                  }
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "2px" }}>
                <DefaultSelect
                  id={"loadCapacityId"}
                  label={"Yük Tipi"}
                  options={loads}
                  backgroundColor="#E5E5E587"
                  variant="outlined"
                  value={
                    formData.find((q) => q.key === "loadCapacityId")?.value
                  }
                  onChange={(option) => {
                    let val = formData.find((q) => q.key === "loadCapacityId");
                    val.value = option?.id;
                    val.hasError = !validators.checkIsValidSelect(option?.id);
                    setFormData([...formData]);
                  }}
                  error={
                    isSubmit &&
                    !validators.checkIsValidSelect(
                      formData.find((q) => q.key === "loadCapacityId").value
                    )
                  }
                />
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <DefaultSelect
              id={"originCityId"}
              label={"Nereden (İl)"}
              options={cities}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "originCityId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "originCityId");
                let county = formData.find((q) => q.key === "originCountyId");
                county.value = null;
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "originCityId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingRight: "2px" }}>
            <DefaultSelect
              id={"originCountyId"}
              label={"Nereden (İlçe)"}
              options={counties}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "originCountyId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "originCountyId");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "originCountyId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultSelect
              id={"destinationCityId"}
              label={"Nereye (İl)"}
              options={cities}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={formData.find((q) => q.key === "destinationCityId").value}
              onChange={(option) => {
                let val = formData.find((q) => q.key === "destinationCityId");
                let county = formData.find(
                  (q) => q.key === "destinationCountyId"
                );
                county.value = null;
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "destinationCityId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12} style={{ paddingRight: "2px" }}>
            <DefaultSelect
              id={"destinationCountyId"}
              label={"Nereye (İlçe)"}
              options={destinationCounties}
              backgroundColor="#E5E5E587"
              variant="outlined"
              value={
                formData.find((q) => q.key === "destinationCountyId").value
              }
              onChange={(option) => {
                let val = formData.find((q) => q.key === "destinationCountyId");
                val.value = option?.id;
                val.hasError = !validators.checkIsValidSelect(option?.id);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidSelect(
                  formData.find((q) => q.key === "destinationCountyId").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultDatepicker
              disablePast={true}
              id={"closeDate"}
              label={"Hangi Tarihte"}
              onChange={(date) => {
                let val = formData.find((q) => q.key === "closeDate");
                val.value = date;
                val.hasError = !validators.checkIsValidDateTimePicker(date);
                setFormData([...formData]);
              }}
              error={
                isSubmit &&
                !validators.checkIsValidDateTimePicker(
                  formData.find((q) => q.key === "closeDate").value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInputFeild
              id="note"
              type={"text"}
              label="Özellikli Not"
              variant="filled"
              backgroundColor="#E5E5E587"
              value={formData.find((q) => q.key === "note").value ?? ""}
              onChange={(e) => {
                let val = formData.find((q) => q.key === "note");
                val.value = e.target.value;
                setFormData([...formData]);
              }}
            />
          </Grid>
        </StyledGridContainer>
        <StyledCheckboxWrapper>
          <DefaultCheckbox
            value={Boolean(formData.find((q) => q.key === "condition1")?.value)}
            label={"Taşıma şartlarını kabul ediyorum."}
            onChange={(e) => {
              let val = formData.find((q) => q.key === "condition1");
              val.value = e.target.checked;
              val.hasError = !e.target.checked;
              setFormData([...formData]);
            }}
            error={
              isSubmit && !formData.find((q) => q.key === "condition1").value
            }
          />
        </StyledCheckboxWrapper>
        {showAlert ? (
          <Grid item xs={12}>
            <AlertBox
              severity={showAlertDetail?.severity}
              title={showAlertDetail?.title}
              message={showAlertDetail?.message}
            />
          </Grid>
        ) : null}
        <div style={{ padding: "0 18px" }}>
          <DefaultButton
            size={"small"}
            variant={"contained"}
            buttonColor={"#084BBB"}
            buttonTextColor={"#FFFFFF"}
            buttonTextTransform={"none"}
            buttonMinHeight={48}
            buttonRadius={"10px"}
            fullWidth={true}
            buttonText={<StyledButtontext>Araç İlanı Ver</StyledButtontext>}
            isProgress={isLoading}
            onClick={() => handleCreateAdvertisement()}
          />
        </div>
      </ContentWrapper>
    </>
  );
};

export default PostVehicleListing;
