import React from "react";

function BottomMenuCargoIcon({ htmlColor = "#898989" }) {
  return (
    <svg
      width="40"
      height="20"
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_1133)">
        <path
          d="M9.0332 0V2.61761H10.2656V1.25478H27.1663V14.2119H10.2656V12.7095H9.0332V15.4662H28.3987V0H9.0332Z"
          fill={htmlColor}
        />
        <path
          d="M12.7621 5.15951H3.04522C2.70476 5.15951 2.42902 4.87876 2.42902 4.53212C2.42902 4.18547 2.70476 3.90472 3.04522 3.90472H12.7621C13.1026 3.90472 13.3783 4.18547 13.3783 4.53212C13.3783 4.87876 13.1026 5.15951 12.7621 5.15951Z"
          fill={htmlColor}
        />
        <path
          d="M15.969 8.28133H0.616201C0.275743 8.28133 0 8.00058 0 7.65394C0 7.3073 0.275743 7.02655 0.616201 7.02655H15.969C16.3094 7.02655 16.5852 7.3073 16.5852 7.65394C16.5852 8.00058 16.3094 8.28133 15.969 8.28133Z"
          fill={htmlColor}
        />
        <path
          d="M9.92915 11.4032H3.82342C3.48296 11.4032 3.20721 11.1224 3.20721 10.7758C3.20721 10.4291 3.48296 10.1484 3.82342 10.1484H9.92915C10.2696 10.1484 10.5454 10.4291 10.5454 10.7758C10.5454 11.1224 10.2696 11.4032 9.92915 11.4032Z"
          fill={htmlColor}
        />
        <path
          d="M38.7628 12.0625L37.3523 11.0733L35.3923 7.24347C35.275 6.96763 34.6041 5.60767 32.7776 5.60767H27.1667V17.7667H32.858C32.8552 17.7409 32.8524 17.7147 32.8496 17.6881C32.8106 17.2789 32.9062 16.8733 33.0944 16.5119H28.3987V6.86204H32.7776C33.8601 6.86204 34.213 7.62367 34.2584 7.73335L34.2669 7.75872L36.4009 11.9291L38.075 13.1032L38.0995 13.1196C38.1064 13.1237 38.768 13.562 38.768 14.0801V16.3454C38.768 16.3846 38.768 16.4047 38.6683 16.4468C38.5489 16.4972 38.4022 16.5107 38.3536 16.5119H36.9925C36.9958 16.525 36.9998 16.5381 37.0026 16.5512C37.097 16.9699 37.0761 17.1806 37.0026 17.601C36.9929 17.6566 36.9773 17.7123 36.958 17.7667H38.3572C39.1736 17.7667 40.0004 17.2785 40.0004 16.3458V14.0805C40.0004 12.9105 38.9485 12.182 38.7632 12.0625H38.7628Z"
          fill={htmlColor}
        />
        <path
          d="M36.8764 12.1283H31.2715C30.931 12.1283 30.6553 11.8476 30.6553 11.5009C30.6553 11.1543 30.931 10.8735 31.2715 10.8735H36.8764C37.2168 10.8735 37.4926 11.1543 37.4926 11.5009C37.4926 11.8476 37.2168 12.1283 36.8764 12.1283Z"
          fill={htmlColor}
        />
        <path
          d="M35.0045 20C33.4554 20 32.1948 18.7166 32.1948 17.1393C32.1948 15.562 33.4554 14.2786 35.0045 14.2786C36.5537 14.2786 37.8138 15.5616 37.8138 17.1393C37.8138 18.717 36.5537 20 35.0045 20ZM35.0045 15.5338C34.1347 15.5338 33.4272 16.254 33.4272 17.1397C33.4272 18.0253 34.1347 18.7456 35.0045 18.7456C35.8743 18.7456 36.5818 18.0253 36.5818 17.1397C36.5818 16.254 35.8743 15.5338 35.0045 15.5338Z"
          fill={htmlColor}
        />
        <path
          d="M39.3154 13.5923H37.6722V14.8471H39.3154V13.5923Z"
          fill={htmlColor}
        />
        <path
          d="M11.5603 17.4757C11.5772 17.1839 11.5732 16.8859 11.6729 16.6097C11.6849 16.5765 11.6982 16.5442 11.7115 16.5115H9.64941V17.7663H11.5334C11.5358 17.6705 11.545 17.5735 11.5603 17.4757Z"
          fill={htmlColor}
        />
        <path
          d="M15.6104 16.5119C15.6623 16.685 15.6852 16.852 15.7109 17.116C15.7226 17.2367 15.7254 17.3582 15.7342 17.479C15.7415 17.5768 15.737 17.673 15.7234 17.7667H28.7954V16.5119H15.6104Z"
          fill={htmlColor}
        />
        <path
          d="M13.5991 20C12.0499 20 10.7894 18.7166 10.7894 17.1393C10.7894 15.562 12.0499 14.2786 13.5991 14.2786C15.1482 14.2786 16.4083 15.5616 16.4083 17.1393C16.4083 18.717 15.1482 20 13.5991 20ZM13.5991 15.5338C12.7292 15.5338 12.0218 16.254 12.0218 17.1397C12.0218 18.0253 12.7292 18.7456 13.5991 18.7456C14.4689 18.7456 15.1763 18.0253 15.1763 17.1397C15.1763 16.254 14.4689 15.5338 13.5991 15.5338Z"
          fill={htmlColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_8_1133">
          <rect width="40" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BottomMenuCargoIcon;
