import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CargoCard from "../components/CargoCard";
import CustomTabs from "../components/CustomTabs";
import BottomMenu from "../components/Layout/BottomMenu";
import HomeNavBar from "../components/Layout/HomeNavBar";
import Loader from "../components/Loader";
import DefaultButton from "../components/UI/DefaultButton";
import VehicleCard from "../components/VehicleCard";

import loadAdvertisementListRequest from "../api/loadAdvertisementListRequest";
import vehicleAdvertisementListRequest from "../api/vehicleAdvertisementListRequest";
import EmptyState from "../components/EmptyState";

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  padding-top: 174px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  overflow-y: auto;
`;

const TabOptions = [
  { label: "Yük İlanları", id: 1 },
  { label: "Araç İlanları", id: 2 },
  // {
  //   label: "Favoriler",
  //   id: 3,
  //   icon: <FavoriteIcon />,
  //   selectedIcon: <FavoriteIcon fillColor="#FFFFFF" />,
  //   disabled: true,
  // },
];

const StyledButtontext = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`;

const LoadMoreWrapper = styled.div`
  margin-inline: 14px;
`;

const Home = () => {
  const [selectedTab, setSelectedtab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loads, setLoads] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [pageLoad, setPageLoad] = useState(1);
  const [pageVehicle, setPageVehicle] = useState(1);
  const [showLoadMoreLoad, setShowLoadMoreLoad] = useState(true);
  const [showLoadMoreVehicle, setShowLoadMoreVehicle] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    loadLoadAdvertisements();
    loadVehicleAdvertisements();
    loadFavorites();
    resetLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const loadLoadAdvertisements = async (more) => {
    if (more) setPageLoad(pageLoad + 1);
    if (selectedTab === 0) {
      let pagination = {
        page: more ? pageLoad + 1 : pageLoad,
        limit: process.env.REACT_APP_PAGINATION_LIMIT || 500,
      };

      const result = await loadAdvertisementListRequest(pagination);

      if (result?.code === 200) {
        if (loads?.length >= result.data?.meta?.totalItems) {
          setShowLoadMoreLoad(false);
        } else {
          setShowLoadMoreLoad(true);
        }

        setLoads([...loads, ...result.data?.items]);
      } else {
        console.log("error:", result);
      }
      setIsLoading(false);
    }
  };

  const loadVehicleAdvertisements = async (more) => {
    if (more) setPageVehicle(pageVehicle + 1);
    if (selectedTab === 1) {
      let pagination = {
        page: more ? pageVehicle + 1 : pageVehicle,
        limit: process.env.REACT_APP_PAGINATION_LIMIT || 500,
      };
      const result = await vehicleAdvertisementListRequest(pagination);

      if (result?.code === 200) {
        if (vehicles?.length >= result.data?.meta?.totalItems) {
          setShowLoadMoreVehicle(false);
        } else {
          setShowLoadMoreVehicle(true);
        }

        setVehicles([...vehicles, ...result.data?.items]);
      } else {
        console.log("error:", result);
      }
      setIsLoading(false);
    }
  };

  const loadFavorites = async () => {
    if (selectedTab === 2) {
      setIsLoading(false);
    }
  };

  const resetLists = () => {
    setPageLoad(1);
    setPageVehicle(1);
    setLoads([]);
    setVehicles([]);
  };

  return (
    <StyledWrapper>
      <HomeNavBar />
      <StyledContainer>
        <CustomTabs options={TabOptions} setSelectedtab={setSelectedtab} />
        {isLoading ? <Loader /> : null}
        {selectedTab === 0 && (
          <>
            {loads?.length > 0 ? (
              loads.map((load, index) => (
                <CargoCard
                  key={index}
                  load={load}
                  hideFavorite={true}
                  showStatus={false}
                />
              ))
            ) : (
              <EmptyState />
            )}
            {showLoadMoreLoad ? (
              <LoadMoreWrapper>
                <DefaultButton
                  size={"small"}
                  variant={"contained"}
                  buttonColor={"#084BBB"}
                  buttonTextColor={"#FFFFFF"}
                  buttonTextTransform={"none"}
                  buttonMinHeight={48}
                  buttonRadius={"10px"}
                  fullWidth={true}
                  buttonText={
                    <StyledButtontext>Devamını yükle</StyledButtontext>
                  }
                  // isProgress={isLoading}
                  onClick={() => {
                    loadLoadAdvertisements(true);
                  }}
                />
              </LoadMoreWrapper>
            ) : null}
          </>
        )}
        {selectedTab === 1 && (
          <>
            {vehicles?.length > 0 ? (
              vehicles.map((vehicle, index) => (
                <VehicleCard
                  key={index}
                  vehicle={vehicle}
                  hideFavorite={true}
                  showStatus={false}
                />
              ))
            ) : (
              <EmptyState />
            )}
            {showLoadMoreVehicle ? (
              <LoadMoreWrapper>
                <DefaultButton
                  size={"small"}
                  variant={"contained"}
                  buttonColor={"#084BBB"}
                  buttonTextColor={"#FFFFFF"}
                  buttonTextTransform={"none"}
                  buttonMinHeight={48}
                  buttonRadius={"10px"}
                  fullWidth={true}
                  buttonText={
                    <StyledButtontext>Devamını yükle</StyledButtontext>
                  }
                  // isProgress={isLoading}
                  onClick={() => {
                    loadVehicleAdvertisements(true);
                  }}
                />
              </LoadMoreWrapper>
            ) : null}
          </>
        )}
        {selectedTab === 2 && <EmptyState />}
      </StyledContainer>
      <BottomMenu selected={"Home"} />
    </StyledWrapper>
  );
};

export default Home;
